import styled from 'styled-components'
import { fontSizes } from 'syf-component-library/defaultTheme'
import IconButton from 'syf-component-library/ui/atoms/IconButton'

export const ActivateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ActivateIconButton = styled(IconButton)`
  text-transform: uppercase;
  font-size: ${fontSizes.small}px;
`
