import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { fontSizes } from 'syf-component-library/defaultTheme'
import { H5 } from 'syf-component-library/ui/typography'

export const DetailValueWrapper = styled(H5)`
  white-space: nowrap;
  @media ${query.lessThanMedium} {
    font-size: ${fontSizes.medium}px;
  }
`

export const DetailWrapper = styled.div`
  width: 100%;
`
