import { ReactElement, Suspense } from 'react'

import { mfeConfig } from 'const/mfeConfig'

import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import Loader from 'authorization-module/ui/atoms/Loader'

import { getLanguageFromStorage } from 'syf-js-utilities'
import { RewardsPage } from 'rewards-mfe'

const Rewards = (): ReactElement => {
  useNavigateOnRedirect()

  const currentLanguage = getLanguageFromStorage()

  return (
    <Suspense fallback={<Loader />}>
      <RewardsPage config={{ ...mfeConfig, lang: currentLanguage }} />
    </Suspense>
  )
}

export default Rewards
