import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import { Button } from 'syf-component-library/ui/atoms'
import CardRow from 'syf-component-library/ui/atoms/Card/CardRow'
import Grid from 'syf-component-library/ui/patterns/Grid'
import Inline from 'syf-component-library/ui/spacing/Inline'

export const IconContainer = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.cyanBlue};
  font-size: 20px;
`

export const AccountImageGrid = styled(Grid)`
  max-width: 100%;
  height: 44px;

  @media ${query.lessThanSmall} {
    max-width: 100px;
  }
`

export const VerticalIconWrapper = styled(Grid)`
  display: block;
  text-align: right;

  @media ${query.greaterThanSmall} {
    display: none;
  }
`
export const CardHeaderRow = styled(CardRow)`
  display: block;
  border-radius: 10px 10px 0 0;
  background-color: ${({ theme }) => theme.white};

  @media ${query.lessThanSmall} {
    cursor: pointer;
    padding: 0 ${spacing.xsmall}px;
  }
`

export const HeaderWrapper = styled.div<{
  isLinkDisabled?: boolean
  isFullPage?: boolean
}>`
  cursor: ${({ isLinkDisabled }) => (isLinkDisabled ? 'cursor' : 'pointer')};
  display: flex;
  max-width: 1200px;
  margin: ${({ isFullPage }) => (isFullPage ? 'auto' : '')};
`

export const CardTitleBorder = styled(Inline)`
  border-left: 1px solid ${({ theme }) => theme.lightGrey};
  height: auto;
`

export const AccountHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 56px auto minmax(150px, 1fr) auto;
  width: 100%;

  @media ${query.greaterThanSmall} {
    grid-template-columns: 56px auto 1fr;
    width: initial;
  }
`

export const AccountHeaderSeparator = styled.div`
  display: flex;
`

export const CollapseOpenCloseButton = styled(Button)`
  margin: 0;
  height: auto;
  padding: 0 ${spacing.medium}px;
`
