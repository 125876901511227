import styled from 'styled-components'
import { Body } from 'syf-component-library/ui/typography'

export const TextWrapper = styled.div`
  text-align: center;
`

export const Description = styled(Body)`
  text-align: center;
`
