import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAL_CREDIT_CARD, FAS_CREDIT_CARD } from 'const/icons'
import { AccountButtonsWrapper } from 'pages/AccountsDashboard/Card/actions/subcomponents'
import i18n from 'strings/i18n'

import { ActivateButtonContainer, ActivateIconButton } from './subcomponents'

interface ActivateButtonProps {
  handleOnClick?: () => void
}

const ActivateButton = ({
  handleOnClick
}: ActivateButtonProps): ReactElement => {
  return (
    <AccountButtonsWrapper>
      <ActivateButtonContainer>
        <ActivateIconButton
          id="activate-card"
          type="button"
          data-type="New Credit"
          data-reason="Activate Card"
          data-object="button"
          data-analytics="clicktrackingevent"
          restIcon={<FontAwesomeIcon icon={FAL_CREDIT_CARD} size="lg" />}
          activeIcon={<FontAwesomeIcon icon={FAS_CREDIT_CARD} size="lg" />}
          buttonText={i18n({ button: 'activateCard' }) as string}
          ariaLabel={i18n({ button: 'activateCard' }) as string}
          onClick={handleOnClick}
        />
      </ActivateButtonContainer>
    </AccountButtonsWrapper>
  )
}

export default ActivateButton
