import { ReactElement } from 'react'

import { FAL_ANGLE_RIGHT } from 'const/icons'
import { Stack } from 'syf-component-library/ui/spacing'

import {
  ArrowIcon,
  CampaignLink,
  CampaignTitle,
  Image,
  LowerRowText,
  Subtitle,
  UpperRowText,
  YieldCard
} from '../subcomponents'

export interface DynamicYieldCardProps {
  campaignData: {
    image: string
    title: string
    subtitle: string
    link: string
    cta: string
  }
}

const DynamicYieldCard = ({
  campaignData
}: DynamicYieldCardProps): ReactElement => {
  const { image, title, subtitle, link, cta } = campaignData

  const displayCampaignLink = Boolean(cta && link)
  const displayImage = Boolean(image)

  return (
    <YieldCard key={campaignData.title}>
      <UpperRowText>
        {displayImage && <Image src={image} alt="" />}
        <div>
          <CampaignTitle as="h3">{title}</CampaignTitle>
          <Stack size="xsmall" />
          <Subtitle>{subtitle}</Subtitle>
        </div>
      </UpperRowText>
      <Stack />
      <LowerRowText>
        {displayCampaignLink && (
          <CampaignLink href={link} target="_blank">
            {cta}
            <ArrowIcon icon={FAL_ANGLE_RIGHT} />
          </CampaignLink>
        )}
      </LowerRowText>
    </YieldCard>
  )
}

export default DynamicYieldCard
