import { ReactElement } from 'react'

import { Image } from 'pages/AccountsDashboard/Card/layouts/subcomponents'
import i18n from 'strings/i18n'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { overlayMessages } from 'ui/atoms/AccountOverlayMessage/subcomponents'
import ActivateButton from 'ui/atoms/ActivateButton'

import { DisplayName, ImageContainer, MessageContainer } from './subcomponents'

interface ActivateCardOverlayMessageProps {
  handleOnClick?: () => void
  overlayDynamicText?: string
  overlayIconUrl?: string
}

const ActivateCardOverlayMessage = ({
  handleOnClick,
  overlayDynamicText,
  overlayIconUrl
}: ActivateCardOverlayMessageProps): ReactElement => {
  const { overlayMessageTitle, overlayMessageDescription } =
    overlayMessages.activateCard

  return (
    <>
      <Stack size="xxlarge" />
      <ImageContainer>
        <Image src={overlayIconUrl} alt="activate card" />
      </ImageContainer>
      <Stack size="xlarge" />
      <MessageContainer weight="bold" data-test="overlay-title">
        {i18n(
          { overlayMessages: overlayMessageTitle },
          {
            displayName: (
              <DisplayName>{overlayDynamicText.toLowerCase()}</DisplayName>
            )
          }
        )}
      </MessageContainer>
      <Inset top="xlarge" bottom="xxlarge">
        <MessageContainer data-test="overlay-description">
          {i18n({ overlayMessages: overlayMessageDescription })}
        </MessageContainer>
      </Inset>
      <Stack size="small" />
      <ActivateButton handleOnClick={handleOnClick} />
    </>
  )
}

export default ActivateCardOverlayMessage
