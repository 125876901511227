import { ReactElement, useState } from 'react'

import { CollapseHeaderWrapper } from 'pages/AccountsDashboard/Card/layouts/CardLayout/subcomponents'
import { VerticalCardCollapsedLoader } from 'syf-component-library/ui/atoms'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Account, AccountSummaryGetResponse } from 'syf-typings'

import PaymentDetailHeader from './PaymentDetailHeader'
import {
  CollapseContainer,
  LoaderContainer,
  LoaderHeaderWrapper
} from './subcomponents'
import PageHeader from '../PageHeader'

const collapseContainerId = 'collapse-container'

interface PaymentSectionHeaderProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
  isLoading: boolean
}

const PaymentSectionHeader = ({
  account,
  accountSummary,
  isLoading
}: PaymentSectionHeaderProps): ReactElement => {
  const windowWidth = useWindowSize()
  const [isExpanded, setExpanded] = useState(false)

  const handleHeaderOnClick = () => {
    setExpanded(!isExpanded)
  }
  if (isLoading || !accountSummary) {
    return (
      <LoaderHeaderWrapper>
        <LoaderContainer>
          <VerticalCardCollapsedLoader />
        </LoaderContainer>
      </LoaderHeaderWrapper>
    )
  }

  return windowWidth.isLessThanMobile ? (
    <>
      <CollapseHeaderWrapper
        data-test="collapse-header-wrapper"
        isExpanded={isExpanded}
        onClick={handleHeaderOnClick}
      >
        <PageHeader
          accountSummary={accountSummary}
          account={account}
          isExpanded={isExpanded}
          showBars
          role="button"
          aria-expanded={isExpanded}
          aria-controls={collapseContainerId}
          handleIconClick={handleHeaderOnClick}
        />
      </CollapseHeaderWrapper>
      <CollapseContainer
        expand={isExpanded}
        data-test={collapseContainerId}
        id={collapseContainerId}
      >
        <PaymentDetailHeader
          account={account}
          accountSummary={accountSummary}
        />
      </CollapseContainer>
    </>
  ) : (
    <PageHeader
      isDesktop
      accountSummary={accountSummary}
      account={account}
      isExpanded
      isSidebarNavPage
    />
  )
}

export default PaymentSectionHeader
