import {
  colors,
  fontWeights,
  spacing,
  statusColors
} from 'syf-component-library/defaultTheme'
import styled from 'styled-components'
import { colors as vistaColors, vistaFontSizes } from 'theme'

export const ErrorCard = styled.div`
  width: 100%;
  padding: ${spacing.base}px;
  border: 2px solid ${colors.error};
  border-radius: 10px;
  background: ${statusColors.error.background};
  display: flex;
`

export const IconContainer = styled.div`
  margin-right: ${spacing.base}px;
  color: ${colors.error};
`

export const ErrorDescription = styled.p`
  color: ${vistaColors.darkestGrey};
  font-size: ${vistaFontSizes.mediumSmall}px;
  font-weight: ${fontWeights.regular};
  line-height: 20px;
`
