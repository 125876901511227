import { AnyObject } from 'typings/custom'
import ServerError from 'typings/ServerError'

const parseError = (err: AnyObject): ServerError => {
  let error: ServerError
  const errorResponse: AnyObject = err?.response

  if (errorResponse) {
    const { status, data } = errorResponse
    const errorCode = data?.businessErrors?.[0].code || ''

    error = {
      statusCode: status,
      description: data?.message,
      businessErrorCode: errorCode
    }
  }
  return error
}

export default parseError
