import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { Body } from 'syf-component-library/ui/typography'

export const NavigationContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
`

export const MenuContainer = styled.nav`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 32px 0 32px 32px;
  @media (min-width: 480px) and (max-width: 1023px) {
    padding: 32px 0 0;
  }
`

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 40px;
  width: 250px;
  @media ${query.betweenSmallMedium} {
    width: 170px;
  }
`

export const PageWrapper = styled.div`
  flex: 2;
  @media ${query.greaterThanSmall} {
    padding: 32px 16px 32px 0;
  }
  @media ${query.betweenSmallMedium} {
    padding-right: 16px;
  }
`

export const MenuItem = styled.li<{ $isActive?: boolean }>`
  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
    background-color: ${({ $isActive, theme }) =>
      $isActive ? theme.cyanBlue : 'inherit'};
    color: ${({ theme }) => theme.white};
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 32px 0 16px;
    cursor: pointer;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  a:active {
    background-color: ${({ theme }) => theme.cyanBlue};
  }
`

export const IconContainer = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.white};
`

export const MenuText = styled(Body)`
  padding-left: 8px;
`
