import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAL_ELLIPSIS_H, FAS_ELLIPSIS_H } from 'const/icons'
import { accountDetailsModalContainerId } from 'const/modalContainerId'
import i18n from 'strings/i18n'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { DropdownTriggerProps } from 'syf-component-library/typings/navigation'
import CLButton from 'syf-component-library/ui/atoms/Button/Button'
import CLIconButton from 'syf-component-library/ui/atoms/IconButton'
import DropdownMenu from 'syf-component-library/ui/patterns/DropdownMenu'
import { Inset } from 'syf-component-library/ui/spacing'
import Small from 'syf-component-library/ui/typography/Small'

const buttonDefaultStyles = `
  margin: 0;
  padding: 0;
  width: 100%;
`

export const Button = styled(CLButton)`
  ${buttonDefaultStyles}
`

export const IconButton = styled(CLIconButton)`
  width: 100%;
`

export const RegisterButton = styled(CLButton)`
  margin: 0;
  width: 100%;
`

export const AutopayStatus = styled(Small)<{ $enrolled: boolean }>`
  color: ${({ theme, $enrolled }) => $enrolled && theme.green};
`

export const AutopayButtonWrapper = styled(Button)`
  width: unset;
`

export const AccountButtonsWrapper = styled(Inset)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 0 0 10px 10px;
  background: ${({ theme }) => theme.mediumGrey};
  padding: 16px 0;

  @media ${query.betweenSmallMedium} {
    width: 100%;
  }
`

export const CreditBalanceRefundButtonStyle = styled(Button)`
  @media ${query.greaterThanSmall} {
    padding: 0 6px;
  }
`

export const MoreDropdownMenu = styled(DropdownMenu)`
  position: relative;

  ul[role='menubar'] {
    left: auto;
    right: 0;
    bottom: 60px;
  }

  li[role='menuitem'] {
    text-wrap: nowrap;
  }

  #account-details-modal {
    ul {
      border-radius: 0;
      background-color: inherit;
      padding-left: 0;
      box-shadow: inherit;
      position: inherit;
    }
  }
`

export const MoreDropdownMenuOption = styled(IconButton)`
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
`

interface MoreDropdownTriggerProps extends DropdownTriggerProps {
  accountId?: string
}

export const Trigger = ({
  toggleMenu,
  accountId
}: MoreDropdownTriggerProps) => {
  return (
    <div id={accountDetailsModalContainerId(accountId)}>
      <IconButton
        onClick={toggleMenu}
        id="moreMenuButtonId"
        data-test="more-button"
        isStacked
        type="button"
        restIcon={<FontAwesomeIcon icon={FAL_ELLIPSIS_H} size="lg" />}
        activeIcon={<FontAwesomeIcon icon={FAS_ELLIPSIS_H} size="lg" />}
        buttonText={`${i18n({ button: 'more' })}`}
        ariaLabel={`${i18n({ button: 'more' })}`}
      />
    </div>
  )
}
