import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAL_CLOCK, FAL_CLOUD_RAIN, FAL_MEDAL, FAL_PHONE } from 'const/icons'
import i18n from 'strings/i18n'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { Body, H4 } from 'syf-component-library/ui/typography'

interface OverlayMessageDetailsProps {
  overlayMessageIcon?: ReactElement
  overlayMessageTitle:
    | 'detailsUnavailable'
    | 'attentionRequired'
    | 'checkBackLater'
    | 'accountUpgrading'
    | 'activationReady'
  overlayMessageDescription:
    | 'noInformation'
    | 'issueWithAccount'
    | 'setpayLoanProcessing'
    | 'checkBackForDetails'
    | 'clickActivateButton'
  overlayMessageHelp?: 'tryLater' | 'contactInfo'
}

export const OverlayMessageWrapper = styled(Inset)`
  text-align: center;
  max-width: 360px;

  @media ${query.greaterThanSmall} {
    height: 422px;
  }
`

export const OverlayMessageTitle = styled(H4)`
  color: ${({ theme }) => theme.body.text};
`

export const TitleWrapper = styled(H4)`
  color: ${({ theme }) => theme.body.text};
  margin: 0 auto;
  max-width: 50%;
`

export const SetpayLoanErrorWrapper = styled.div`
  text-align: center;
  height: 422px;
`

export const OverlayMessageDescriptionWrapper = styled(Inset)`
  max-width: 70%;
  margin: 0 auto;
`

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.cyanBlue};
`

export type OverlayMessageType =
  | 'accountUnavailableError'
  | 'authProhibitedUnregisteredStatus'
  | 'setpayLoanProcessingError'
  | 'accountUpgrading'
  | 'activateCard'

export const overlayMessages: {
  [key in OverlayMessageType]: OverlayMessageDetailsProps
} = {
  accountUnavailableError: {
    overlayMessageIcon: <Icon icon={FAL_CLOUD_RAIN} size="4x" />,
    overlayMessageTitle: 'detailsUnavailable',
    overlayMessageDescription: 'noInformation',
    overlayMessageHelp: 'tryLater'
  },
  authProhibitedUnregisteredStatus: {
    overlayMessageIcon: <Icon icon={FAL_PHONE} size="4x" />,
    overlayMessageTitle: 'attentionRequired',
    overlayMessageDescription: 'issueWithAccount',
    overlayMessageHelp: 'contactInfo'
  },
  setpayLoanProcessingError: {
    overlayMessageIcon: <Icon icon={FAL_CLOCK} size="4x" />,
    overlayMessageTitle: 'checkBackLater',
    overlayMessageDescription: 'setpayLoanProcessing'
  },
  accountUpgrading: {
    overlayMessageIcon: <Icon icon={FAL_MEDAL} size="4x" />,
    overlayMessageTitle: 'accountUpgrading',
    overlayMessageDescription: 'checkBackForDetails'
  },
  activateCard: {
    overlayMessageTitle: 'activationReady',
    overlayMessageDescription: 'clickActivateButton'
  }
}

export const SetpayLoanProcessingError = (): ReactElement => {
  const { overlayMessageIcon, overlayMessageTitle, overlayMessageDescription } =
    overlayMessages.setpayLoanProcessingError
  return (
    <SetpayLoanErrorWrapper>
      <Stack />
      {overlayMessageIcon}
      <Stack size="xlarge" />
      <TitleWrapper data-test="overlay-title">
        {i18n({ overlayMessages: overlayMessageTitle })}
      </TitleWrapper>
      <OverlayMessageDescriptionWrapper vertical="xxlarge" horizontal="large">
        <Body data-test="overlay-description">
          {i18n({ overlayMessages: overlayMessageDescription })}
        </Body>
      </OverlayMessageDescriptionWrapper>
      <Stack size="xlarge" />
    </SetpayLoanErrorWrapper>
  )
}
