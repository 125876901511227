import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from 'const/config'
import routes from 'const/routes'
import Container from 'pages/subcomponents'
import i18n from 'strings/i18n'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import Button from 'syf-component-library/ui/atoms/Button'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { H4, H5 } from 'syf-component-library/ui/typography'

export const RainCloudsIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.cyanBlue};
  height: 61px;
`

export const TextWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 492px;

  @media ${query.lessThanSmall} {
    max-width: 267px;
  }
`

export const ErrorCard = styled(Card)`
  width: 702px;

  @media ${query.betweenSmallMedium} {
    width: 430px;
  }

  @media ${query.lessThanSmall} {
    width: 315px;
  }
`
export const ErrorWrapper = styled(Inset)`
  margin: auto;
`

export const DisabledCookiesError = (): ReactElement => {
  return (
    <Inset top="large" bottom="xxlarge">
      <H5 as="h3">
        Please change your browser settings to allow cookies or choose a
        different browser to access the account manager.
      </H5>
    </Inset>
  )
}

export const NoAccountError = (): ReactElement => {
  return (
    <>
      <H5 as="h3">{i18n({ errors: 'unableToFindAccount' })}</H5>
      <Stack size="small" hideOnBreakpointDown="large" />
      <Inset top="large" bottom="xxlarge">
        <H5 as="h4">
          {i18n({ errors: 'accountClosedOrBlocked' })} &nbsp;
          {i18n({ errors: 'contactCustomerService' })}
        </H5>
      </Inset>
    </>
  )
}

export const TechnicalDifficultiesError = (): ReactElement => {
  return (
    <>
      <H5 as="h3">{i18n({ errors: 'technicalDifficulties' })}</H5>
      <Stack size="small" hideOnBreakpointDown="large" />
      <Inset top="large" bottom="xxlarge">
        <H5 as="h4">{i18n({ errors: 'tryAgainLater' })}</H5>
      </Inset>
    </>
  )
}

const TryAgainButton = styled(Button)`
  margin: auto;
  padding: 0 ${spacing.xxlarge}px;
`

export const FullPageError = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <>
      <H5 as="h3">{i18n({ errors: 'technicalDifficulties' })}</H5>
      <Stack size="small" hideOnBreakpointDown="large" />
      <Inset top="large" bottom="xxlarge">
        <TryAgainButton
          buttonType="primary"
          data-type="error"
          data-reason="try again"
          data-object="button"
          data-analytics="clicktrackingevent"
          onClick={() => navigate(routes.HOME)}
        >
          {i18n({ errors: 'tryAgain' })}
        </TryAgainButton>
      </Inset>
    </>
  )
}

export const InnerContainer = styled.div`
  text-align: center;
  width: 100%;
  position: relative;

  @media ${query.greaterThanMedium} {
    width: 768px;
  }
`

const ErrorHeader = styled.div`
  background-color: ${({ theme }) => theme.yellow};
  height: 256px;
  width: 100%;

  @media ${query.lessThanMedium} {
    height: 166px;
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    max-width: 100%;

    @media ${query.lessThanMedium} {
      height: 178px;
    }

    @media ${query.greaterThanLarge} {
      height: 256px;
    }
  }
`

export const FullPageRewardsError = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <Container>
      <InnerContainer>
        <Stack size="medium" hideOnBreakpointUp="medium" />
        <Stack size="large" hideOnBreakpointDown="medium" />
        <Card>
          <ErrorHeader>
            <img
              src={`${config.ASSETS_HOST}/assets/vista/error_cat.svg`}
              alt={`${i18n({ logout: 'pageTitle' })}`}
            />
          </ErrorHeader>
          <Inset horizontal="xxlarge" vertical="base">
            <H4 as="h3" weight="bold">
              {i18n({ overlayMessages: 'careCreditUnavailable' })}
            </H4>
            <Stack size="xsmall" />
            <H5 as="h4">{i18n({ overlayMessages: 'tryLater' })}</H5>
            <Stack size="large" />
            <TryAgainButton
              buttonType="primary"
              data-type="error"
              data-reason="return to account manager"
              data-object="button"
              data-analytics="clicktrackingevent"
              onClick={() => navigate(routes.HOME)}
            >
              {i18n({ button: 'accountManagerReturn' })}
            </TryAgainButton>
          </Inset>
        </Card>
      </InnerContainer>
    </Container>
  )
}
