import { ChangeEvent, ReactElement, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from 'const/config'
import { FAR_INFO_CIRCLE } from 'const/icons'
import { formatDate } from 'syf-component-library/helpers/dateValidation'
import i18n from 'strings/i18n'
import { Stack } from 'syf-component-library/ui/spacing'
import { InputField } from './subcomponents'
import IncorrectInfoError from '../IncorrectInfoError'
import { FormState, ManageFormHelpers } from '../typings'
import { BodyContent, ContentWrapper, ImageWrapper } from '../subcomponents'

interface AddAccountScreenProps {
  formData: FormState
  formError: FormState
  setFormError?: ManageFormHelpers['setFormError']
  handleInputChange: ManageFormHelpers['handleInputChange']
  validateInput: ManageFormHelpers['validateInput']
  shouldShowIncorrectInfoError?: boolean
}

const AddAccountScreen = ({
  formData,
  formError,
  setFormError,
  handleInputChange,
  validateInput,
  shouldShowIncorrectInfoError
}: AddAccountScreenProps): ReactElement => {
  useEffect(() => {
    if (shouldShowIncorrectInfoError && setFormError) {
      setFormError({
        accountNumber: i18n({ errors: 'emptyAccountNumber' }) as string,
        ssn: i18n({ errors: 'emptySSN' }) as string,
        dob: i18n({ errors: 'emptyDOB' }) as string
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowIncorrectInfoError])

  const handleBlur = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    validateInput(name, value)
  }

  const handleChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'dob' && formData.dob.length <= value.length) {
      handleInputChange(name, formatDate(value))
    } else handleInputChange(name, value)
  }

  return (
    <ContentWrapper data-test="add-account-screen">
      <ImageWrapper>
        <img
          src={`${config.ASSETS_HOST}/assets/vista/add_account_modal.svg`}
          alt={i18n({ labels: 'addAccountContent' }) as string}
        />
      </ImageWrapper>
      <Stack size="large" />
      <BodyContent>{i18n({ labels: 'addAccountContent' })}</BodyContent>
      <Stack size="large" />
      {shouldShowIncorrectInfoError && <IncorrectInfoError />}
      <div>
        <InputField
          data-private
          id="add-account-number"
          name="accountNumber"
          data-test="add-account-number"
          type="text"
          placeholder={i18n({ labels: 'accountNumberPlaceholder' }) as string}
          helpText={i18n({ labels: 'addAccountHelpText' }) as string}
          matchPattern={/^\d{0,16}$/}
          error={formError.accountNumber}
          errorIcon={<FontAwesomeIcon icon={FAR_INFO_CIRCLE} size="sm" />}
          value={formData.accountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Stack size="base" />
        <InputField
          data-private
          id="add-ssn"
          name="ssn"
          data-test="add-ssn"
          type="text"
          placeholder={i18n({ labels: 'ssnPlaceholder' }) as string}
          helpText={i18n({ labels: 'ssnHelpText' }) as string}
          matchPattern={/^\d{0,4}$/}
          error={formError.ssn}
          errorIcon={<FontAwesomeIcon icon={FAR_INFO_CIRCLE} size="sm" />}
          value={formData.ssn}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Stack size="base" />
        <InputField
          data-private
          id="add-dob"
          name="dob"
          data-test="add-dob"
          type="text"
          placeholder={i18n({ labels: 'dobPlaceholder' }) as string}
          helpText={i18n({ labels: 'dobHelpText' }) as string}
          maxLength={10}
          error={formError.dob}
          errorIcon={<FontAwesomeIcon icon={FAR_INFO_CIRCLE} size="sm" />}
          value={formData.dob}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <Stack size="large" />
    </ContentWrapper>
  )
}

export default AddAccountScreen
