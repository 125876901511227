import { AriaRole, ReactElement, RefObject, forwardRef, useState } from 'react'

import { ACCOUNT_UPGRADING } from 'const/account/conditions'
import { PSCC, SETPAY } from 'const/account/products'
import { defaultCardArt, synchronyLogo } from 'const/assets'
import featureFlags from 'const/featureFlags'
import { FAL_ANGLE_DOWN, FAL_ANGLE_UP } from 'const/icons'
import getSetpayLoanProcessingStatus from 'helpers/getSetpayLoanProcessingStatus'
import handleAccountLink from 'helpers/handleAccountLink'
import isLoanProductCard from 'helpers/isLoanProductCard'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Inline, Stack } from 'syf-component-library/ui/spacing'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'
import CardBankDetails from 'ui/atoms/CardBankDetails'
import CardLogo from 'ui/atoms/CardLogo'

import isAccountLinkDisabledForAClient from 'helpers/isAccountLinkDisabledForAClient'
import {
  AccountHeaderGrid,
  AccountHeaderSeparator,
  AccountImageGrid,
  CardHeaderRow,
  CardTitleBorder,
  CollapseOpenCloseButton,
  HeaderWrapper,
  IconContainer,
  VerticalIconWrapper
} from './subcomponents'

interface AccountHeaderProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
  isFullPage?: boolean
  isExpanded?: boolean
  role?: AriaRole
  showChevron?: boolean
  showBorder?: boolean
  handleIconClick?: () => void
}

const AccountHeader = (
  {
    accountSummary,
    account,
    isExpanded,
    showChevron,
    showBorder,
    isFullPage,
    handleIconClick,
    ...props
  }: AccountHeaderProps,
  logoFocusRef: RefObject<HTMLButtonElement>
): ReactElement => {
  const windowWidth = useWindowSize()
  const { isLessThanMobile } = windowWidth
  const [isViewAccountDisabled, setIsViewAccountDisabled] = useState(false)

  const isRegistered = account?.registration?.registered
  const productType = account?.product?.type
  const isSetpayAccount = productType === SETPAY
  const isPN4orMonthlySetpayAccount = isLoanProductCard(productType)
  const clientId = accountSummary?.product?.clientId
  const portfolioLogo = accountSummary?.product?.portfolioLogo || synchronyLogo
  const cardArt = accountSummary?.card?.cardArtURL || defaultCardArt
  const { conditions, installment } = accountSummary || {}
  const cardLogo = isFullPage ? cardArt : portfolioLogo
  const isPSCCRevolvingAccount = account?.product?.lineOfBusiness === PSCC
  const isAccountLinkDisabledForAClientId =
    isAccountLinkDisabledForAClient(clientId)

  const isAccountUpgrading =
    conditions?.includes(ACCOUNT_UPGRADING) &&
    featureFlags.isAccountUpgradingMessageEnabled

  const isSetpayLoanProcessing = getSetpayLoanProcessingStatus(
    installment,
    productType,
    conditions
  )

  const isLinkEnabled =
    isRegistered &&
    !isViewAccountDisabled &&
    !isSetpayLoanProcessing &&
    !isAccountUpgrading &&
    !isPN4orMonthlySetpayAccount &&
    clientId &&
    !isSetpayAccount &&
    !isPSCCRevolvingAccount &&
    !isAccountLinkDisabledForAClientId

  const isLinkEnabledOnDesktop = isLinkEnabled && !isLessThanMobile

  const isLinkEnabledOnMobile =
    (isExpanded || !isLessThanMobile) && isLinkEnabled

  const handleDesktopAccountClick = () => {
    if (isLinkEnabledOnDesktop) {
      setIsViewAccountDisabled(true)
      handleAccountLink(account, clientId, windowWidth)
    }
  }

  const handleMobileAccountClick = () => {
    if (isLessThanMobile) {
      setIsViewAccountDisabled(true)
      handleAccountLink(account, clientId, windowWidth)
    }
  }

  return (
    <CardHeaderRow
      hasBorderBottom={showBorder}
      data-test="account-header"
      {...props}
    >
      <Stack />
      <HeaderWrapper
        data-test="header-wrapper"
        isLinkDisabled={!isLinkEnabledOnDesktop}
        onClick={handleDesktopAccountClick}
        isFullPage={isFullPage}
      >
        <Inline
          size={isFullPage ? 'xxlarge' : 'large'}
          respondToBreakpoints={['small']}
        />
        <AccountHeaderGrid>
          <AccountImageGrid item xs="auto" alignItems="center">
            <CardLogo
              handleOnClick={handleMobileAccountClick}
              isLinkDisabled={!isLinkEnabledOnMobile}
              logo={cardLogo}
              ref={logoFocusRef}
            />
          </AccountImageGrid>
          <AccountHeaderSeparator>
            <Inline size="medium" />
            {!isFullPage && <CardTitleBorder />}
          </AccountHeaderSeparator>
          <div>
            <CardBankDetails
              cardName={
                accountSummary?.product?.displayName || 'Your Synchrony Account'
              }
              isFullPage={isFullPage}
              accountLast4={account?.info.accountLast4}
              isSetpayAccount={isSetpayAccount}
              handleOnClick={handleMobileAccountClick}
              isLinkDisabled={!isLinkEnabledOnMobile}
            />
          </div>
          {showChevron && (
            <VerticalIconWrapper item xs="auto">
              <CollapseOpenCloseButton
                buttonType="text"
                onClick={handleIconClick}
              >
                <IconContainer
                  data-test="header-chevron"
                  icon={isExpanded ? FAL_ANGLE_UP : FAL_ANGLE_DOWN}
                />
              </CollapseOpenCloseButton>
              <Inline size="medium" />
            </VerticalIconWrapper>
          )}
        </AccountHeaderGrid>
      </HeaderWrapper>
      <Stack />
    </CardHeaderRow>
  )
}

export default forwardRef(AccountHeader)
