import {
  ACCOUNT_CLOSED_WITH_BALANCE,
  ACCOUNT_OPEN
} from 'const/account/conditions'
import isAccountTypeSetPay from 'helpers/isAccountTypeSetPay'
import { AccountSummaryGetResponse } from 'syf-typings/accountSummary/accountSummary'

const shouldShowAccountServiceButton = (
  accountSummary: AccountSummaryGetResponse
): boolean => {
  const { billing, conditions, credit, product } = accountSummary || {}
  const { customerAssistancePlan, settlementEnrolled } = billing || {}
  const currentBalance = credit?.currentBalance
  const isStatusOpen = conditions?.includes(ACCOUNT_OPEN)
  const isStatusClosedWithBalance = conditions?.includes(
    ACCOUNT_CLOSED_WITH_BALANCE
  )
  const isEnrolled = customerAssistancePlan || settlementEnrolled

  const currentBalanceValue = currentBalance && Number(currentBalance)
  const isNegativeCurrentBalance = currentBalanceValue < 0
  const isLoanPaidOff = isNegativeCurrentBalance || currentBalanceValue === 0
  const isClosedAndHasNegativeBalance =
    isStatusClosedWithBalance && isLoanPaidOff

  const isSetPay = isAccountTypeSetPay(product?.type)

  return !(
    isSetPay ||
    isClosedAndHasNegativeBalance ||
    ((isStatusOpen || isStatusClosedWithBalance) && isEnrolled)
  )
}

export default shouldShowAccountServiceButton
