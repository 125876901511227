import { ReactElement } from 'react'

import { useQuery } from '@tanstack/react-query'
import { getLanguageFromStorage } from 'helpers'

import DynamicYieldCard from './DynamicYieldCard'
import getDynamicYieldResponse from './getDynamicYieldResponse'
import { DynamicYieldWrapper } from './subcomponents'
import { DynamicYieldData } from './typings'

const DynamicYieldGrid = (): ReactElement | null => {
  const lang = getLanguageFromStorage()

  const { data, isFetching, isError } = useQuery<DynamicYieldData>(
    ['dynamicYieldData', lang],
    async () => getDynamicYieldResponse()
  )

  const filteredCampaignsData = data?.campaigns?.filter(
    (campaign) => campaign && campaign?.title
  )

  const shouldRenderDY =
    !isFetching && !isError && Boolean(filteredCampaignsData?.length)

  if (shouldRenderDY) {
    return (
      <DynamicYieldWrapper data-test="dynamic-yield-card">
        {filteredCampaignsData.map((campaignData) => (
          <DynamicYieldCard
            key={campaignData.title}
            campaignData={campaignData}
          />
        ))}
      </DynamicYieldWrapper>
    )
  }

  return null
}

export default DynamicYieldGrid
