import { CARECREDIT, PAYSOL, UNIVERSAL } from 'const/account/clients'
import config from 'const/config'

const getApigeeClientId = (client: string): string => {
  if (client === PAYSOL || client === CARECREDIT) {
    return `${config.PSCC_APIGEE_CLIENT_ID}`
  }
  if (client === UNIVERSAL) {
    return `${config.UNIVERSAL_APIGEE_CLIENT_ID}`
  }

  return `${config.RC_APIGEE_CLIENT_ID}`
}

export default getApigeeClientId
