import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { updateModalStatus } from 'redux/modals/actions'

import featureFlags from 'const/featureFlags'
import routes from 'const/routes'
import {
  AllAccountSummaryGetResponse,
  useAllAccountSummary,
  useCreditAccounts
} from 'const/syfApiQueries'
import findAccount from 'helpers/findAccount'
import handleAccountLink from 'helpers/handleAccountLink'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'

/**
 *
 *This function will allow users to be redirected to the Profile screen when they click Edit Profile
 */

const useProfileNavigate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const windowWidth = useWindowSize()

  const { data: { accounts = [] } = {} } = useCreditAccounts()

  const allAccountSummaryQuery = useAllAccountSummary(accounts)

  const handleProfileClick = (guid: string) => {
    if (featureFlags.isProfileManagementEnabled) {
      dispatch(updateModalStatus(''))
      setTimeout(() => {
        navigate(routes.PROFILE)
      }, 400)
    } else {
      const allAccountSummaryData: AllAccountSummaryGetResponse[] =
        allAccountSummaryQuery.map((result) => result.data)
      const account = findAccount(accounts, guid)
      const accountSummary: AllAccountSummaryGetResponse =
        allAccountSummaryData.find(
          (summary: AllAccountSummaryGetResponse) => summary?.accountId === guid
        )
      const { clientId } = accountSummary?.product || {}
      handleAccountLink(account, clientId, windowWidth)
    }
  }

  return {
    handleProfileClick
  }
}

export default useProfileNavigate
