import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import { H2 } from 'syf-component-library/ui/typography'

/*
 * TODO: mobile styles for margin bottom
 */
export const PersonalGreetingHeading = styled(H2)`
  margin-bottom: ${spacing.base}px;
`

export const PersonalGreetingContainer = styled.div`
  margin-top: ${spacing.large}px;
  padding-left: ${spacing.xsmall}px;
  color: ${({ theme }) => theme.white};

  @media ${query.greaterThanSmall} {
    padding-left: 0;
  }
  /* Slightly nuanced, but this keeps the greeting aligned with the SYF logo */
  @media ${query.greaterThanLarge} {
    position: relative;
    left: -${spacing.small}px;
  }
`
