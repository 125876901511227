const isAllowedHost = (url: string): boolean => {
  if (!url) return false
  const whitelistCheck =
    /(\.|^)(syf|synchrony|mysynchrony|synchronycredit|carecredit|synchronymastercard|dickssportinggoods).com$/
  try {
    const urlObject = new URL(url)
    return whitelistCheck.test(urlObject.host)
  } catch (error) {
    return false
  }
}

export default isAllowedHost
