import styled from 'styled-components'
import Body from 'syf-component-library/ui/typography/Body'

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 126px;
  width: 201px;
  margin: auto;
  border-radius: 3px;
  background: ${({ theme }) => theme.mediumGrey};
`

export const MessageContainer = styled(Body)`
  color: ${({ theme }) => theme.body.text};
  max-width: 85%;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
`

export const DisplayName = styled.span`
  text-transform: capitalize;
`
