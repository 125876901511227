import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'
import { Button } from 'syf-component-library/ui/atoms'
import Collapse from 'syf-component-library/ui/atoms/Collapse'
import { H4, Small } from 'syf-component-library/ui/typography'

export const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${query.lessThanSmall} {
    flex-wrap: wrap;
    padding: ${spacing.base}px;
    background-color: ${({ theme }) => theme.athensGray};
  }
  @media ${query.betweenSmallMedium} {
    padding-top: 20px;
  }
  @media ${query.betweenMediumLarge} {
    margin-left: 20px;
  }
`

export const PaymentDetailSection = styled.div`
  @media ${query.lessThanSmall} {
    width: 50%;
    padding: 0 8px 16px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.lightGreyBorder};
    &:nth-child(odd) {
      border-right: 1px solid ${({ theme }) => theme.lightGreyBorder};
    }
  }
  @media ${query.betweenMediumLarge} {
    padding-right: 12px;
    flex: 1;
  }
`

export const ValueWrapper = styled(H4)`
  color: ${({ theme }) => theme.eerieBlack};
  @media (min-width: 480px) and (max-width: 1023px) {
    font-size: ${fontSizes.medium}px;
  }
`

export const LabelWrapper = styled(Small)`
  color: ${({ theme }) => theme.brightGray};
  @media (min-width: 480px) and (max-width: 1023px) {
    font-size: 10px;
  }
`

export const ButtonContainer = styled.div`
  .make-payment-btn {
    padding: 0 8px;
  }
  @media ${query.lessThanSmall} {
    width: 100%;
    margin: 16px 0;
  }
  @media ${query.betweenSmallMedium} {
    position: absolute;
    top: 0;
    right: 20px;
  }
`

export const MenuButton = styled(Button)`
  font-size: 16px;
  margin-bottom: 16px;
`

export const IconContainer = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-right: 8px;
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const LoaderHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.athensGray};
`

export const LoaderContainer = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 82px;
  display: flex;
  flex-direction: column;
`

export const CollapseContainer = styled(Collapse)`
  position: relative;
`
