import PRODUCT_TYPE from 'const/productTypes'
import { ProductType } from 'syf-typings'

const isAccountTypeSetPay = (type: ProductType) => {
  return [
    PRODUCT_TYPE.INSTALLMENT_LOAN_MONTHLY,
    PRODUCT_TYPE.INSTALLMENT_LOAN_PAY_IN_FOUR,
    PRODUCT_TYPE.INSTALLMENT_LOAN
  ].includes(type)
}

export default isAccountTypeSetPay
