import triggerAnalyticsEvent, {
  analyticsConfig
} from 'syf-js-utilities/helpers/triggerAnalyticsEvent'

import getLanguageFromStorage from './getLanguageFromStorage'

if (navigator.cookieEnabled) {
  analyticsConfig.defaults.Country_Language = `us|${getLanguageFromStorage()}`
  analyticsConfig.defaults.ClientName = localStorage.getItem('client')
  analyticsConfig.defaults.Token = sessionStorage.getItem('correlationId')
}

analyticsConfig.defaults.CustomerType = 'consumer'
analyticsConfig.defaults.Product = 'generic'
analyticsConfig.defaults.PageKind = 'multiaccount'
analyticsConfig.defaults.pageName = 'view'
analyticsConfig.defaults.PageFunction = 'dashboard'
analyticsConfig.defaults.SiteIdentifier = 'vista'
analyticsConfig.defaults.PNL = 'vista'

export default triggerAnalyticsEvent
