import { AnyObject } from 'typings/custom'

const setAttributes = (
  element: HTMLInputElement | HTMLFormElement,
  attributes: AnyObject
): void =>
  Object.keys(attributes).forEach((key) =>
    element.setAttribute(key, attributes[key])
  )

const formPost = (path: string, params: AnyObject, target = '_self'): void => {
  const form = document.createElement('form')
  setAttributes(form, { method: 'post', action: path, target })

  Object.keys(params).forEach((key) => {
    const hiddenField = document.createElement('input')
    setAttributes(hiddenField, {
      type: 'hidden',
      name: key,
      value: params[key]
    })
    form.appendChild(hiddenField)
  })
  if (document.body) {
    document.body.appendChild(form)
  }
  form.submit()
}

export default formPost
