import { nonSupportingPAGClients } from 'const/account/clients'
import {
  ACCOUNT_CLOSED_WITH_BALANCE,
  ACCOUNT_DELINQUENT
} from 'const/account/conditions'
import { RC, SETPAY } from 'const/account/products'
import featureFlags from 'const/featureFlags'
import { PaymentButtonTypeRecord } from 'pages/AccountsDashboard/Card/actions/typing'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

export interface GetPaymentButtonProps {
  account: Account
  accountSummary: AccountSummaryGetResponse
  accessToCreditBalanceRefund: boolean
}

const getMakeAPaymentEligibility = (
  account: Account,
  accountSummary: AccountSummaryGetResponse,
  shouldShowCreditBalanceRefund: boolean
) => {
  const { registration: { registered } = {}, product } = account
  const {
    billing: { customerAssistancePlan, settlementEnrolled } = {},
    product: { clientId } = {},
    credit: { currentBalance } = {},
    conditions
  } = accountSummary
  const isUnregisteredRetailCard = product.lineOfBusiness === RC && !registered
  const isSetpay = product.type === SETPAY
  const currentBalanceValue = currentBalance && Number(currentBalance)
  const isNegativeCurrentBalance = currentBalanceValue < 0
  const isLoanPaidOff = isNegativeCurrentBalance || currentBalanceValue === 0
  const isClosedWithBalance = conditions?.includes(ACCOUNT_CLOSED_WITH_BALANCE)

  const isPAGDisabled =
    nonSupportingPAGClients.includes(clientId) && isUnregisteredRetailCard
  const isSetpayLoanPaidOff = isSetpay && isLoanPaidOff
  const isClosedAndHasNegativeBalance = isClosedWithBalance && isLoanPaidOff
  const isDelinquent = conditions?.includes(ACCOUNT_DELINQUENT)

  const isInCollections =
    customerAssistancePlan || settlementEnrolled || isDelinquent
  return (
    (isSetpay || !isInCollections || !registered) &&
    !isPAGDisabled &&
    !isSetpayLoanPaidOff &&
    !isClosedAndHasNegativeBalance &&
    !shouldShowCreditBalanceRefund
  )
}

const getPaymentButtonType = ({
  account,
  accountSummary,
  accessToCreditBalanceRefund
}: GetPaymentButtonProps): PaymentButtonTypeRecord => {
  const {
    billing: { customerAssistancePlan, settlementEnrolled } = {},
    credit: { currentBalance } = {},
    conditions
  } = accountSummary
  const { registration: { registered } = {} } = account

  const isDelinquent = conditions?.includes(ACCOUNT_DELINQUENT)
  const currentBalanceValue = currentBalance && Number(currentBalance)
  const isNegativeCurrentBalance = currentBalanceValue < 0
  const isSettlementEnrolledOrCAP = customerAssistancePlan || settlementEnrolled
  const shouldShowCreditBalanceRefund =
    featureFlags.isCBREnabled &&
    Boolean(accessToCreditBalanceRefund) &&
    isNegativeCurrentBalance &&
    registered

  const shouldShowMakeAPayment = getMakeAPaymentEligibility(
    account,
    accountSummary,
    shouldShowCreditBalanceRefund
  )

  const shouldShowViewPaymentPlan = isSettlementEnrolledOrCAP
  const shouldShowViewPaymentOptions =
    !isSettlementEnrolledOrCAP && isDelinquent
  const shouldShowCollectionPayment =
    shouldShowViewPaymentPlan || shouldShowViewPaymentOptions

  return {
    shouldShowCreditBalanceRefund,
    shouldShowMakeAPayment,
    shouldShowCollectionPayment,
    shouldShowViewPaymentOptions
  }
}

export default getPaymentButtonType
