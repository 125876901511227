import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { Button } from 'syf-component-library/ui/atoms'
import { H3 } from 'syf-component-library/ui/typography'

export const AccountServiceWrapper = styled.div`
  max-width: 1200px;
  background: ${({ theme }) => theme.white};
  min-height: 232px;
  @media ${query.greaterThanSmall} {
    border-radius: 10px;
  }
`

export const AccountServiceContainer = styled.div`
  padding: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.lightGreyBorder};
`

export const LinkContainer = styled.div`
  display: flex;
  padding: 8px 0;
  @media ${query.lessThanSmall} {
    flex-direction: column;
  }
  @media ${query.lessThanLarge} {
    flex-wrap: wrap;
  }
`

export const LinkItem = styled.div`
  flex: 1 50%;
  padding-bottom: 8px;
`

export const LinkHeader = styled(H3)`
  font-size: 16px;
  color: ${({ theme }) => theme.ebonyClay};
  border-bottom: 2px solid ${({ theme }) => theme.lightGreyBorder};
  padding: 8px 16px;
  @media ${query.greaterThanMedium} {
    margin: 0 16px;
    padding: 8px 0;
  }
`

export const LinkList = styled.div``

export const LinkButton = styled(Button)`
  padding: 4px 8px;
  height: auto;
  text-align: left;
`

export const Header = styled(H3)`
  font-size: 16px;
  color: ${({ theme }) => theme.ebonyClay};
`

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const LoaderContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  height: 230px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
