import { clientUrls } from './account/clients'

type ENV = 'development' | 'production' | 'local'

const { NODE_ENV } = process.env
const APP_ENV = 'CF_APP_ENV' as ENV
const BASENAME = '/accounts'

export interface ConfigType {
  API_HOST: string
  APP_ENV: ENV
  ASSETS_HOST: string
  AUTH_DOMAIN: string
  BASENAME: string
  COBROWSE: string
  DCH_HOST_NAME: string
  DY_CAMPAIGNS_URL: string
  DY_SECTION_ID: string
  IVA_HOST: string
  PSCC_APIGEE_CLIENT_ID: string
  PSCC_PAY_AS_GUEST_URL: string
  PSCC_URL: string
  RC_APIGEE_CLIENT_ID: string
  SERVICE_FILES: string
  SURFLY_URL: string
  SURFLY_WIDGET_KEY: string
  TARGET_ORIGIN: (client?: string) => string
  UNIVERSAL_APIGEE_CLIENT_ID: string
  UTAG_URL: string
}

export const setTargetOrigin = (
  environment: string,
  client?: string
): string => {
  const currentClient = client || localStorage.getItem('client')
  return (
    (environment === 'production' && clientUrls[currentClient]) ||
    `https://${window.location.host}`
  )
}

const prodConfig: ConfigType = {
  API_HOST: 'CF_API_HOST',
  APP_ENV,
  ASSETS_HOST: 'CF_ASSETS_HOST',
  AUTH_DOMAIN: 'CF_AUTH_DOMAIN',
  BASENAME,
  COBROWSE: 'CF_COBROWSE',
  DCH_HOST_NAME: 'CF_DCH_HOST_NAME',
  DY_CAMPAIGNS_URL: 'CF_DY_CAMPAIGNS_URL',
  DY_SECTION_ID: 'CF_DY_SECTION_ID',
  IVA_HOST: 'CF_IVA_HOST',
  PSCC_APIGEE_CLIENT_ID: 'CF_PSCC_APIGEE_CLIENT_ID',
  PSCC_PAY_AS_GUEST_URL: 'CF_PSCC_PAY_AS_GUEST_URL',
  PSCC_URL: 'CF_PSCC_URL',
  RC_APIGEE_CLIENT_ID: 'CF_RC_APIGEE_CLIENT_ID',
  SERVICE_FILES: 'CF_SERVICE_FILES',
  SURFLY_URL: 'CF_SURFLY_URL',
  SURFLY_WIDGET_KEY: 'CF_SURFLY_WIDGET_KEY',
  TARGET_ORIGIN: (client?: string) => setTargetOrigin(APP_ENV, client),
  UNIVERSAL_APIGEE_CLIENT_ID: 'CF_UNIVERSAL_APIGEE_CLIENT_ID',
  UTAG_URL: 'CF_UTAG_URL'
}

const localConfig: ConfigType = {
  API_HOST: 'https://api-qa.syf.com',
  APP_ENV: 'local',
  ASSETS_HOST: 'https://qa.synchronycredit.com',
  AUTH_DOMAIN: 'https://auth-qa.syf.com',
  BASENAME,
  COBROWSE: 'staging',
  DCH_HOST_NAME: 'https://qa.synchronycredit.com/paymenthelp',
  DY_CAMPAIGNS_URL: 'https://qa.synchronycredit.com/dy-api/api/v1/dy/campaigns',
  DY_SECTION_ID: '8776194',
  IVA_HOST: 'ivaqa.synchronycredit.com',
  PSCC_APIGEE_CLIENT_ID: 'WeyPqxf4m17Lc309BUbgCG9YmxmmwUIZ',
  PSCC_PAY_AS_GUEST_URL: 'https://q3consumercenter.mysynchrony.com',
  PSCC_URL: 'https://q3consumercenter.mysynchrony.com/consumercenter',
  RC_APIGEE_CLIENT_ID: 'SjkM9OgCRWBV5AJ9lEPf8icRdUtPizBY',
  SERVICE_FILES: 'https://qa.synchronycredit.com/serviceFiles/json/qa',
  SURFLY_URL: 'https://dev-connecttoassist.syf.com/surfly.js',
  SURFLY_WIDGET_KEY: 'aa1e83f44f5d4bf8b01952883699bbc7',
  TARGET_ORIGIN: () => 'https://qa.synchronycredit.com',
  UNIVERSAL_APIGEE_CLIENT_ID: 'G0aFAZvPHNurZSUSJ1RGMcb5FebcSWjr',
  UTAG_URL: 'https://tags.syf.com/synchrony-spa/dev/utag.js'
}

export default NODE_ENV === 'production' ? prodConfig : localConfig
