import styled from 'styled-components'
import CLButton from 'syf-component-library/ui/atoms/Button/Button'
import theme from 'theme'

const AddAccountButtonStyle = styled(CLButton)`
  margin: 0;
  gap: 4px;
  height: 28px;
  background-color: transparent;
  color: ${theme.white};
  border-color: ${theme.white};
  &:hover {
    color: ${theme.cyanBlue};
  }
`
export default AddAccountButtonStyle
