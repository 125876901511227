import getQueryParam from 'syf-js-utilities/formats/getQueryParam'

const setLanguageFromQueryParams = (): void => {
  const language = getQueryParam('lang')
  if (language) {
    localStorage.setItem('language', language)
    document.documentElement.setAttribute('lang', language)
  }
}

export default setLanguageFromQueryParams
