import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { mmaConfig } from 'const/mfeConfig'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import Promotions from 'promotions-mfe'
import { getLanguageFromStorage } from 'helpers'

const PromotionsPage = () => {
  const promotionsMfeConfig = {
    ...mmaConfig,
    lang: getLanguageFromStorage()
  }

  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )
  useNavigateOnRedirect()
  return (
    <Promotions
      config={promotionsMfeConfig}
      accountId={selectedAccount}
      accountIdType="guid"
    />
  )
}
export default PromotionsPage
