import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateModalStatus, updateSelectedAccount } from 'redux/modals/actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import CrossBorderModal from 'App/CrossBorderModal'
import featureFlags from 'const/featureFlags'
import {
  FAL_FILE_INVOICE,
  FAL_GIFT,
  FAL_LIST_ALT,
  FAL_PIE_CHART,
  FAL_USER_COG,
  FAS_FILE_INVOICE,
  FAS_GIFT,
  FAS_LIST_ALT,
  FAS_PIE_CHART_ALT,
  FAS_USER_COG
} from 'const/icons'
import {
  accountDetailsModalContainerId,
  activityModalContainerId,
  rewardsModalContainerId,
  statementsModalContainerId
} from 'const/modalContainerId'
import routes from 'const/routes'
import { useAccountFeatures } from 'const/syfApiQueries'
import getRewardsResponse from 'helpers/getRewardsResponse'
import handleAccountLink from 'helpers/handleAccountLink'
import handleRewardsPlatform from 'helpers/handleRewardsPlatform'
import shouldShowAccountServiceButton from 'pages/AccountServices/shouldShowAccountServiceButton'
import i18n from 'strings/i18n'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import debounce from 'syf-js-utilities/helpers/debounce'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import queryClient from 'const/queryClient'
import {
  AccountButtonsWrapper,
  IconButton,
  MoreDropdownMenu,
  MoreDropdownMenuOption,
  Trigger
} from './subcomponents'

interface RewardsResponse {
  samlToken: string
}

export interface AccountDetailButtonsProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
}

const AccountDetailButtons = ({
  accountSummary,
  account
}: AccountDetailButtonsProps): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const windowWidth = useWindowSize()

  const [isCrossBorderVisible, setIsCrossBorderVisible] = useState(false)

  const { product: { type: productType, clientId } = {} } = accountSummary
  const accountId = account?.info?.['cipher.accountId']

  const { data: accountFeatures, error: accountFeaturesError } =
    useAccountFeatures(accountId)

  const {
    ssoEnabled,
    rewardsPlatformDestination,
    accessToRewards,
    accessToRewardsMfe
  } = accountFeatures?.rewardDetails || {}

  const activityButtonId = `activity-${accountId}`
  const detailsButtonId = `details-${accountId}`
  const statementsButtonId = `statements-${accountId}`
  const rewardsButtonId = `rewards-${accountId}`
  const accountServicesButtonId = `account-services-${accountId}`

  const samlTokenCacheKey = ['saml-token', accountId]
  const { data: samlRewards, isError: isRewardsError } =
    useQuery<RewardsResponse>(
      samlTokenCacheKey,
      () => getRewardsResponse(accountId),
      {
        enabled:
          Boolean(ssoEnabled) &&
          Boolean(accountId) &&
          Boolean(rewardsPlatformDestination)
      }
    )

  const shouldDisplayAccountServices =
    shouldShowAccountServiceButton(accountSummary)

  const handleActivityButtonClick = () => {
    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    dispatch(updateSelectedAccount(accountId))
    dispatch(updateModalStatus('Activity', activityButtonId))
  }

  const handleDetailsButtonClick = () => {
    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    dispatch(updateSelectedAccount(accountId))
    dispatch(updateModalStatus('Details', detailsButtonId))
  }

  const handleServicesClick = () => {
    dispatch(updateSelectedAccount(accountId))
    navigate(routes.ACCOUNT_SERVICES)
  }
  const debouncedStatementsButtonClick = debounce(() => {
    handleAccountLink(account, clientId, windowWidth, 'statements')
  }, 250)

  const handleStatementDetailsButtonClick = () => {
    if (featureFlags.isStatementsMfeEnabled) {
      analyticsConfig.defaults.Product = productType
      analyticsConfig.defaults.ClientName = clientId
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('Statement', statementsButtonId))
    } else {
      debouncedStatementsButtonClick()
    }
  }
  const debouncedDetailsButtonClick = debounce(() => {
    handleDetailsButtonClick()
  }, 250)

  const debouncedRewardsButtonClick = debounce(() => {
    const samlToken = samlRewards?.samlToken

    if (accessToRewardsMfe) {
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('Rewards', rewardsButtonId))
      return
    }

    if (isRewardsError) {
      navigate(routes.REWARDS_ERROR)
      return
    }

    if (rewardsPlatformDestination && ssoEnabled) {
      handleRewardsPlatform(samlToken, rewardsPlatformDestination)
      queryClient.invalidateQueries(samlTokenCacheKey)
    } else if (rewardsPlatformDestination && !ssoEnabled) {
      setIsCrossBorderVisible(true)
    } else {
      handleAccountLink(account, clientId, windowWidth, 'rewards')
    }
  }, 250)

  return (
    <AccountButtonsWrapper top="medium" bottom="large">
      <div id={activityModalContainerId(accountId)}>
        <IconButton
          onClick={() => handleActivityButtonClick()}
          id={activityButtonId}
          isStacked
          type="button"
          data-type="activity"
          data-reason="activity"
          data-object="button"
          data-analytics="clicktrackingevent"
          restIcon={<FontAwesomeIcon icon={FAL_LIST_ALT} size="lg" />}
          activeIcon={<FontAwesomeIcon icon={FAS_LIST_ALT} size="lg" />}
          buttonText={`${i18n({ button: 'activity' })}`}
          ariaLabel={`${i18n({ button: 'activity' })}`}
        />
      </div>
      {(accountFeatures?.accessToStatements || accountFeaturesError) && (
        <div id={statementsModalContainerId(accountId)}>
          <IconButton
            onClick={handleStatementDetailsButtonClick}
            id={statementsButtonId}
            isStacked
            type="button"
            data-type="statements"
            data-reason="statements"
            data-object="button"
            data-analytics="clicktrackingevent"
            restIcon={<FontAwesomeIcon icon={FAL_FILE_INVOICE} size="lg" />}
            activeIcon={<FontAwesomeIcon icon={FAS_FILE_INVOICE} size="lg" />}
            buttonText={`${i18n({ button: 'statements' })}`}
            ariaLabel={`${i18n({ button: 'statements' })}`}
          />
        </div>
      )}
      {featureFlags.isRewardsDeeplinkEnabled && accessToRewards && (
        <div id={rewardsModalContainerId(accountId)}>
          <IconButton
            onClick={debouncedRewardsButtonClick}
            id={rewardsButtonId}
            isStacked
            type="button"
            data-type="rewards"
            data-reason="rewards"
            data-object="button"
            data-analytics="clicktrackingevent"
            restIcon={<FontAwesomeIcon icon={FAL_GIFT} size="lg" />}
            activeIcon={<FontAwesomeIcon icon={FAS_GIFT} size="lg" />}
            buttonText={`${i18n({ button: 'rewards' })}`}
            ariaLabel={`${i18n({ button: 'rewards' })}`}
          />
        </div>
      )}
      <MoreDropdownMenu
        trigger={<Trigger showActiveStyles={false} accountId={accountId} />}
      >
        {featureFlags.isAccountDetailsEnabled &&
          account?.registration?.registered && (
            <div id={accountDetailsModalContainerId(accountId)}>
              <MoreDropdownMenuOption
                onClick={debouncedDetailsButtonClick}
                id={detailsButtonId}
                type="button"
                data-type="accountdetails"
                data-reason="accountdetails"
                data-object="button"
                data-analytics="clicktrackingevent"
                data-test="account-details-button"
                restIcon={<FontAwesomeIcon icon={FAL_PIE_CHART} size="lg" />}
                activeIcon={
                  <FontAwesomeIcon icon={FAS_PIE_CHART_ALT} size="lg" />
                }
                buttonText={`${i18n({ button: 'accountDetails' })}`}
                ariaLabel={`${i18n({ button: 'accountDetails' })}`}
              />
            </div>
          )}
        {shouldDisplayAccountServices && (
          <MoreDropdownMenuOption
            onClick={handleServicesClick}
            id={accountServicesButtonId}
            type="button"
            data-type="account services"
            data-reason="account services"
            data-object="button"
            data-analytics="clicktrackingevent"
            data-test="account-service-button"
            restIcon={<FontAwesomeIcon icon={FAL_USER_COG} size="lg" />}
            activeIcon={<FontAwesomeIcon icon={FAS_USER_COG} size="lg" />}
            buttonText={`${i18n({ labels: 'accountsServices' })}`}
            ariaLabel={`${i18n({ labels: 'accountsServices' })}`}
          />
        )}
      </MoreDropdownMenu>
      <CrossBorderModal
        isOpen={isCrossBorderVisible}
        setIsOpen={setIsCrossBorderVisible}
        url={rewardsPlatformDestination}
      />
    </AccountButtonsWrapper>
  )
}

export default AccountDetailButtons
