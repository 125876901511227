import { SyntheticEvent } from 'react'

const handleImageError = (e: SyntheticEvent, fallbackImage: string): void => {
  const event = e.target as HTMLImageElement
  if (event.src !== fallbackImage) {
    event.onerror = null
    event.src = fallbackImage
  }
}

export default handleImageError
