import { ReactElement, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import config from 'const/config'
import queryClient from 'const/queryClient'
import routes from 'const/routes'
import { useAutoRegisterUpdate, useCreditAccounts } from 'const/syfApiQueries'
import getAutoRegisterPayload from 'helpers/getAutoRegisterPayload'
import getLanguageFromStorage from 'helpers/getLanguageFromStorage'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import postElectronicConsent from 'services/postElectronicConsent'
import i18n from 'strings/i18n'
import { creditAccountsCacheKey } from 'syf-api/api/cacheKeys'
import Button from 'syf-component-library/ui/atoms/Button'
import Link from 'syf-component-library/ui/atoms/Link/Link'
import { Inline, Inset, Stack } from 'syf-component-library/ui/spacing'
import { Body, H5 } from 'syf-component-library/ui/typography'
import AccountManagerTreeGraphic from 'ui/atoms/AccountManagerTreeGraphic'

import { ButtonWrapper, SimpleModal } from './subcomponents'

const WelcomeInterstitial = (): ReactElement => {
  const headerRef = useRef(null)
  const navigate = useNavigate()
  const lang = getLanguageFromStorage()
  const [isOpen, setIsOpen] = useState(true)

  const { data: { accounts = [], electronicConsent } = {} } =
    useCreditAccounts()
  const { mutate: mutateAutoRegister } = useAutoRegisterUpdate()

  useEffect(() => {
    triggerAnalyticsEvent({
      PageSubFunction: 'econsent'
    })
  }, [])

  const onClickHandler = () => {
    localStorage.setItem('previouslyVisited', 'true')
    setIsOpen(false)
  }

  const handleElectronicConsent = async () => {
    onClickHandler()
    try {
      const econsentStatus = await postElectronicConsent()

      if (!econsentStatus) {
        navigate(routes.ERROR)
        return
      }

      const accountsToAutoRegister = getAutoRegisterPayload(accounts)

      // Updating credit accounts data with new econsent status.
      queryClient.invalidateQueries(creditAccountsCacheKey())

      if (accountsToAutoRegister.length) {
        mutateAutoRegister(accountsToAutoRegister, {
          onSuccess: () =>
            queryClient.invalidateQueries(creditAccountsCacheKey())
        })
      }
    } catch (error) {
      navigate(routes.ERROR)
    }
  }

  return (
    <SimpleModal
      id="simple-modal"
      isOpen={isOpen}
      headerRef={headerRef}
      aria-label={`${i18n({ welcomeInterstitial: 'welcomeTitle' })}`}
      aria-describedby={`${i18n({
        welcomeInterstitial: 'manageAccountSubTitle'
      })}`}
      data-test="welcome-modal"
    >
      <Inset all="xlarge" respondToBreakpoints={['small']}>
        <H5 weight="bold">
          {`${i18n({ welcomeInterstitial: 'welcomeTitle' })}`}
        </H5>
        <Body>{`${i18n({
          welcomeInterstitial: 'manageAccountSubTitle'
        })}`}</Body>
        <AccountManagerTreeGraphic />
        {!electronicConsent && (
          <Inset vertical="large" hideOnBreakpointDown="medium">
            <div>
              {i18n(
                { welcomeInterstitial: 'eConsentStatement' },
                {
                  legalStatementLink: (
                    <Link
                      isInline
                      href={`${config.ASSETS_HOST}/assets/generic/${lang}/eConsent_document.pdf`}
                      target="_blank"
                      data-type="welcome view"
                      data-reason="eConsent file"
                      data-object="link"
                      data-analytics="clicktrackingevent"
                    >
                      {i18n({
                        welcomeInterstitial: 'legalStatement'
                      })}
                    </Link>
                  )
                }
              )}
            </div>
            <Stack size="large" />
            <div>
              <Body isInline weight="bold">
                {`${i18n({
                  welcomeInterstitial: 'note'
                })}`}
              </Body>
              <Inline size="small" />
              {i18n({ welcomeInterstitial: 'welcomeNote' })}
            </div>
          </Inset>
        )}

        <Stack size="large" />
        <ButtonWrapper key="modal-button">
          {electronicConsent ? (
            <Button
              onClick={onClickHandler}
              buttonType="secondary"
              data-type="welcome view"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="welcome-button"
            >
              {i18n({
                button: 'welcomeConfirmation'
              })}
            </Button>
          ) : (
            <Button
              onClick={handleElectronicConsent}
              buttonType="text"
              data-type="welcome view"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-reason="agree & continue"
              data-test="agree-button"
            >
              {i18n({
                button: 'agreeAndContinue'
              })}
            </Button>
          )}
        </ButtonWrapper>
      </Inset>
    </SimpleModal>
  )
}

export default WelcomeInterstitial
