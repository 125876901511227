import { ReactElement } from 'react'

import { AppWrapper, Footer } from 'App/subcomponents'
import config from 'const/config'
import { GlobalBaseStyles } from 'globalStyles'
import TechnicalError from 'pages/AccountsDashboard/TechnicalError'
import styled, { ThemeProvider } from 'styled-components'
import Navigation from 'syf-component-library/ui/patterns/SystemNavigation'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import appTheme from 'theme'

const ErrorWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

const DisabledCookies = (): ReactElement => {
  return (
    <ThemeProvider theme={appTheme}>
      <GlobalBaseStyles />
      <AppWrapper data-test="disabled-cookies">
        <Navigation logo={`${config.ASSETS_HOST}/assets/synchrony/logo.svg`} />
        <ErrorWrapper>
          <Inset all="xxlarge" hideOnBreakpointDown="medium">
            <Stack size="xlarge" respondToBreakpoints={['medium', 'small']} />
            <TechnicalError errorType="disabledCookies" />
          </Inset>
        </ErrorWrapper>
        <Footer />
      </AppWrapper>
    </ThemeProvider>
  )
}

export default DisabledCookies
