import { ReactElement, RefObject, forwardRef } from 'react'

import { synchronyLogo } from 'const/assets'
import handleImageError from 'helpers/handleImageError'

import { AccountLinkButton, CardImage } from './subcomponents'

interface CardLogoProps {
  logo: string
  handleOnClick: () => void
  isLinkDisabled: boolean
}

const CardLogo = forwardRef(
  (
    { logo, handleOnClick, isLinkDisabled }: CardLogoProps,
    ref: RefObject<HTMLButtonElement>
  ): ReactElement => (
    <AccountLinkButton
      data-type="legacy account link"
      data-reason="legacy account link"
      data-object="link"
      data-analytics="clicktrackingevent"
      buttonType="text"
      onClick={handleOnClick}
      disabled={isLinkDisabled}
      ref={ref}
    >
      <CardImage
        src={logo}
        alt=""
        onError={(e) => handleImageError(e, synchronyLogo)}
      />
    </AccountLinkButton>
  )
)

export default CardLogo
