const triggerQualtricsOnLoad = (
  isScriptsLoaded: boolean,
  setIsScriptsLoaded: (isScriptsLoaded: boolean) => void
): void => {
  const observer = new MutationObserver(() => {
    if (window.QSI?.API && !isScriptsLoaded) {
      setIsScriptsLoaded(true)
      observer.disconnect()
    }
  })

  if (!isScriptsLoaded) {
    const targetNode = document.body
    observer.observe(targetNode, { childList: true })
  } else {
    window.QSI?.API?.load()
    window.QSI?.API?.run()
  }
}

export default triggerQualtricsOnLoad
