import AccountDetailButtons from 'pages/AccountsDashboard/Card/actions/AccountDetailButtons'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import Grid from 'syf-component-library/ui/patterns/Grid'

export const FlexWrapper = styled.div`
  display: flex;
`

export const CardBorderColumn = styled(Grid)<{ $borderSize: number }>`
  align-self: flex-start;
  border-right: 1px solid ${({ theme }) => theme.lightGrey};
  border-image: radial-gradient(
      ${({ theme }) => theme.lighterGrey},
      ${({ theme }) => theme.white}
    )
    1;
  height: ${({ $borderSize }) => $borderSize}px;

  @media ${query.lessThanSmall} {
    &::after {
      right: 16px;
    }
  }
`

export const CardFooterGrid = styled(AccountDetailButtons)`
  background-color: ${({ theme }) => theme.mediumGrey};
`

export const CardDetailsGrid = styled(Grid)`
  height: 245px;
`

export const AutopayWrapper = styled(Grid)`
  height: 24px;
`

export const CommonStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageWrapper = styled.div`
  ${CommonStyles}
  width: 120px;
  height: 120px;
`

export const Image = styled.img`
  height: auto;
  width: auto;
  max-width: 120px;
  max-height: 120px;
`
