import reducer from 'redux/rootReducer'

import { $CombinedState, configureStore } from '@reduxjs/toolkit'

const store = configureStore({ reducer })

export type RootState = ReturnType<typeof reducer> & {
  readonly [$CombinedState]?: never
}
export type AppDispatch = typeof store.dispatch

export default store
