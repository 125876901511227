import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import Button from 'syf-component-library/ui/atoms/Button'
import { Body } from 'syf-component-library/ui/typography'

export const IconWrapper = styled.span`
  margin-right: 5px;
`

export const DropdownRow = styled(Button)`
  width: 100%;
  padding: 4px 20px;
  margin: 8px 0;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    text-decoration: none;
  }

  @media ${query.greaterThanMedium} {
    width: 275px;
  }
`

export const MenuItemText = styled(Body)`
  text-align: start;
  @media ${query.greaterThanMedium} {
    margin-left: 6px;
  }
`

export const NavigationContainer = styled.header`
  width: 100%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.04), 0 1px 18px 0 rgba(0, 0, 0, 0.08),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
`
