import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import {
  FAR_GEAR,
  FAS_BADGE_PERCENT,
  FAS_GIFT,
  FAS_LIST_ALT
} from 'const/icons'
import routes from 'const/routes'
import i18n from 'strings/i18n'

import { useAccountFeatures } from 'const/syfApiQueries'
import featureFlags from 'const/featureFlags'
import {
  IconContainer,
  MenuContainer,
  MenuItem,
  MenuList,
  MenuText
} from './subcomponents'

interface MenuOptionsProps {
  activeLink: string
  showAccountServiceMenu: boolean
  accountId: string
}

const MenuOptions = ({
  activeLink,
  showAccountServiceMenu,
  accountId
}: MenuOptionsProps): ReactElement => {
  const { data: accountFeatures } = useAccountFeatures(accountId)

  const { accessToRewardsMfe, accessToRewards } =
    accountFeatures?.rewardDetails || {}

  const isRewardsButtonEnabled =
    featureFlags.isRewardsDeeplinkEnabled &&
    accessToRewardsMfe &&
    accessToRewards

  const isPromotionsButtonEnabled =
    featureFlags.isPromotionsMfeEnabled && accountFeatures?.accessToPromotions

  return (
    <MenuContainer>
      <MenuList>
        <MenuItem
          $isActive={
            activeLink === routes.ACTIVITY || activeLink === routes.DISPUTES
          }
        >
          <NavLink
            data-type="activity"
            data-reason="activity"
            data-object="button"
            data-analytics="clicktrackingevent"
            data-test="activity-nav-link"
            to={routes.ACTIVITY}
          >
            <IconContainer icon={FAS_LIST_ALT} />
            <MenuText weight="bold" isInline>
              {i18n({
                labels: 'activityMenu'
              })}
            </MenuText>
          </NavLink>
        </MenuItem>
        {isPromotionsButtonEnabled && (
          <MenuItem $isActive={activeLink === routes.PROMOTIONS}>
            <NavLink
              data-type="promotions"
              data-reason="left nav"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="promotions-nav-link"
              to={routes.PROMOTIONS}
            >
              <IconContainer icon={FAS_BADGE_PERCENT} />
              <MenuText weight="bold" isInline>
                {i18n({
                  labels: 'promotionsMenu'
                })}
              </MenuText>
            </NavLink>
          </MenuItem>
        )}
        {showAccountServiceMenu && (
          <MenuItem $isActive={activeLink === routes.ACCOUNT_SERVICES}>
            <NavLink
              data-type="account services"
              data-reason="account services"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="account-services-nav-link"
              to={routes.ACCOUNT_SERVICES}
            >
              <IconContainer icon={FAR_GEAR} />
              <MenuText weight="bold" isInline>
                {i18n({
                  labels: 'accountsServices'
                })}
              </MenuText>
            </NavLink>
          </MenuItem>
        )}
        {isRewardsButtonEnabled && (
          <MenuItem $isActive={activeLink === routes.REWARDS}>
            <NavLink
              data-type="rewards"
              data-reason="rewards"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="rewards-nav-link"
              to={routes.REWARDS}
            >
              <IconContainer icon={FAS_GIFT} />
              <MenuText weight="bold" isInline>
                {i18n({ button: 'rewards' })}
              </MenuText>
            </NavLink>
          </MenuItem>
        )}
      </MenuList>
    </MenuContainer>
  )
}

export default MenuOptions
