import getLanguageFromStorage from 'helpers/getLanguageFromStorage'
import translations from 'strings/lang'
import type { AnyObject } from 'typings/custom'

import {
  LanguageObject,
  StringObject,
  StringsDictionary,
  Translation
} from './lang/typings'

/**
 * This finds a string from <language>.json, then replaces its slots with values, returning a fully formatted string for the UI.
 * @param {Object} lang Object that has key of the string from <language>.json
 * @param {Object} data Values of slots to replace.  Key is slot, value is replacement.
 */
const i18n = (lang: StringObject, data?: AnyObject): Translation => {
  const language = getLanguageFromStorage()
  const strings = translations[language] as StringsDictionary
  const messageKey = Object.keys(lang)[0] as keyof LanguageObject
  const message = strings[messageKey][lang[messageKey]]

  if (typeof message === 'string' && data) {
    let result = message
    const args = Object.keys(data)
    args.forEach((arg: string) => {
      result = result.replace(`%${arg}%`, data[arg])
    })
    return result
  }
  if (typeof message !== 'string') {
    return message(data)
  }

  return message
}

export default i18n
