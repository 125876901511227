import { ReactElement } from 'react'

import TechnicalError from 'pages/AccountsDashboard/TechnicalError'
import styled from 'styled-components'
import Inset from 'syf-component-library/ui/spacing/Inset'

const ErrorWrapper = styled(Inset)`
  display: flex;
  justify-content: center;
`

const ErrorPage = (): ReactElement => {
  return (
    <ErrorWrapper top="xlarge">
      <TechnicalError errorType="fullPageError" />
    </ErrorWrapper>
  )
}

export default ErrorPage
