import { GivenNameAction } from './typings'

const updateGivenName = (payload: string): GivenNameAction => {
  return {
    type: 'UPDATE_GIVEN_NAME',
    payload
  }
}

export default updateGivenName
