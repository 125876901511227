import { ReactElement } from 'react'

import config from 'const/config'
import clearSessionStorage from 'helpers/clearSessionStorage'
import handleRevokeAccessToken from 'helpers/handleRevokeAccessToken'
import redirectToUrl from 'helpers/redirectToUrl'
import i18n from 'strings/i18n'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import { Button } from './subcomponents'

interface CollectionsPaymentButtonProps {
  account: Account
  className?: string
  buttonText: 'paymentPlan' | 'paymentOptions'
  dataType?: string
}

const CollectionsPaymentButton = ({
  account,
  className,
  buttonText,
  dataType
}: CollectionsPaymentButtonProps): ReactElement => {
  const handleOnClick = async () => {
    const guid = account.info['cipher.accountId']
    const languageId = localStorage.getItem('language')
    clearSessionStorage()
    await handleRevokeAccessToken()
    redirectToUrl(`${config.DCH_HOST_NAME}/?account=${guid}&lang=${languageId}`)
  }

  return (
    <Button
      buttonType="secondary"
      className={className}
      onClick={handleOnClick}
      data-test="collection-payment-button"
      data-type={dataType || 'manage collections hub'}
      data-reason="manage collections hub"
      data-object="button"
      data-analytics="clicktrackingevent"
    >
      {i18n({ button: buttonText })}
    </Button>
  )
}

export default CollectionsPaymentButton
