import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FAL_CREDIT_CARD_BLANK,
  FAL_FILE_INVOICE_DOLLAR,
  FAL_MONEY_CHECK,
  FAL_PHONE_LAPTOP,
  FAL_RECEIPT,
  FAL_STAR_CHRISTMAS
} from 'const/icons'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Stack } from 'syf-component-library/ui/spacing'
import { colors } from 'theme'

import {
  AccountManagerGraphic,
  CreditCardIconContainer,
  CreditCardIconWrapper,
  FileInvoiceIconContainer,
  FileInvoiceIconLineWrapper,
  FileInvoiceIconWrapper,
  FlexWrapper,
  IconWrapper,
  LineWrapper,
  MoneyCheckIconContainer,
  MoneyCheckIconLineWrapper,
  MoneyCheckIconWrapper,
  PhoneLaptopIconContainer,
  PhoneLaptopIconLineWrapper,
  PhoneLaptopIconWrapper,
  ReceiptIconContainer,
  ReceiptIconLineWrapper,
  ReceiptIconWrapper,
  StarIconWrapper,
  StrokeAnimater,
  dashedLine
} from './subcomponents'

const AccountManagerTreeGraphic = (): ReactElement => {
  const windowWidth = useWindowSize()

  return (
    <AccountManagerGraphic>
      <Stack size="xlarge" respondToBreakpoints={['small']} />
      <FlexWrapper>
        <FileInvoiceIconWrapper>
          <FileInvoiceIconContainer>
            <FontAwesomeIcon
              icon={FAL_FILE_INVOICE_DOLLAR}
              size={windowWidth.isLessThanMobile ? 'sm' : 'lg'}
            />
          </FileInvoiceIconContainer>
        </FileInvoiceIconWrapper>
        <FileInvoiceIconLineWrapper>
          <LineWrapper>
            {dashedLine(
              colors.cyanBlue,
              '25',
              '58',
              windowWidth.isLessThanMobile
            )}
            <StrokeAnimater />
          </LineWrapper>
        </FileInvoiceIconLineWrapper>
        <PhoneLaptopIconWrapper>
          <PhoneLaptopIconContainer>
            <FontAwesomeIcon
              icon={FAL_PHONE_LAPTOP}
              size={windowWidth.isLessThanMobile ? '1x' : '2x'}
            />
          </PhoneLaptopIconContainer>
        </PhoneLaptopIconWrapper>
        <PhoneLaptopIconLineWrapper>
          <LineWrapper>
            {dashedLine(colors.grey, '70', '48', windowWidth.isLessThanMobile)}
            <StrokeAnimater />
          </LineWrapper>
        </PhoneLaptopIconLineWrapper>
        <CreditCardIconWrapper>
          <CreditCardIconContainer>
            <FontAwesomeIcon
              icon={FAL_CREDIT_CARD_BLANK}
              size={windowWidth.isLessThanMobile ? 'sm' : '1x'}
            />
          </CreditCardIconContainer>
          <LineWrapper>
            {dashedLine(colors.yellow, '70', '2', windowWidth.isLessThanMobile)}
            <StrokeAnimater />
          </LineWrapper>
        </CreditCardIconWrapper>
        <MoneyCheckIconWrapper>
          <MoneyCheckIconContainer>
            <FontAwesomeIcon
              icon={FAL_MONEY_CHECK}
              size={windowWidth.isLessThanMobile ? 'lg' : '2x'}
            />
          </MoneyCheckIconContainer>
        </MoneyCheckIconWrapper>
        <MoneyCheckIconLineWrapper>
          <LineWrapper>
            {dashedLine(colors.blue, '70', '48', windowWidth.isLessThanMobile)}
            <StrokeAnimater />
          </LineWrapper>
        </MoneyCheckIconLineWrapper>
        <ReceiptIconWrapper>
          <ReceiptIconContainer>
            <FontAwesomeIcon
              icon={FAL_RECEIPT}
              size={windowWidth.isLessThanMobile ? 'xs' : 'sm'}
            />
          </ReceiptIconContainer>
        </ReceiptIconWrapper>
        <ReceiptIconLineWrapper>
          <LineWrapper>
            {dashedLine(colors.green, '35', '78', windowWidth.isLessThanMobile)}
            <StrokeAnimater />
          </LineWrapper>
        </ReceiptIconLineWrapper>
      </FlexWrapper>
      <IconWrapper>
        <StarIconWrapper>
          <FontAwesomeIcon
            icon={FAL_STAR_CHRISTMAS}
            size={windowWidth.isLessThanMobile ? '2x' : '3x'}
          />
        </StarIconWrapper>
      </IconWrapper>
    </AccountManagerGraphic>
  )
}

export default AccountManagerTreeGraphic
