import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAR_PLUS_CIRCLE } from 'const/icons'
import i18n from 'strings/i18n'

import { useDispatch } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'
import AddAccountButtonStyle from './subcomponents'

const AddAccountButton = (): ReactElement => {
  const dispatch = useDispatch()
  const addAccountHandler = () => {
    dispatch(updateModalStatus('AddAccount', 'add-account-container-id'))
  }
  return (
    <AddAccountButtonStyle
      buttonType="secondary"
      data-test="add-account-button"
      onClick={addAccountHandler}
      data-type="dashboard"
      data-reason="add account"
      data-object="button"
      data-analytics="clicktrackingevent"
    >
      <FontAwesomeIcon icon={FAR_PLUS_CIRCLE} />
      {i18n({ button: 'addAccount' })}
    </AddAccountButtonStyle>
  )
}

export default AddAccountButton
