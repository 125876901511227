import getQueryParam from 'syf-js-utilities/formats/getQueryParam'

export type SupportedLanguage = 'en' | 'es'

const getLanguageFromStorage = (): SupportedLanguage => {
  const availableLanguages: SupportedLanguage[] = ['en', 'es']
  const languageFromParam = getQueryParam('lang') as SupportedLanguage
  const languageFromStorage = navigator.cookieEnabled
    ? (localStorage.getItem('language') as SupportedLanguage)
    : 'en'

  if (availableLanguages.includes(languageFromParam)) {
    return languageFromParam
  }
  if (availableLanguages.includes(languageFromStorage)) {
    return languageFromStorage
  }
  if (navigator.cookieEnabled) localStorage.setItem('language', 'en')
  return 'en'
}

export default getLanguageFromStorage
