import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { Card } from 'syf-component-library/ui/atoms'

export const DisputesTableWrapper = styled(Card)`
  max-width: 1200px;
  @media ${query.lessThanMedium} {
    border-radius: 0;
  }
`
