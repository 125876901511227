import { ReactElement } from 'react'

import featureFlags from 'const/featureFlags'
import isIE from 'const/isIE'
import i18n from 'strings/i18n'
import styled from 'styled-components'
import CLFooter from 'syf-component-library/ui/patterns/Footer'

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const RouteWrapper = styled((props) =>
  // eslint-disable-next-line
  isIE ? <div {...props} role="main" /> : <main {...props} />
)`
  flex-grow: 1;
`

export const Footer = (): ReactElement => {
  const { isCobrowseEnabled, isShowCookieSetting } = featureFlags
  const footerLinks = [
    {
      id: '1',
      text: `\u00A9 ${new Date().getFullYear()} Synchrony `,
      url: 'https://www.synchrony.com/',
      dataType: 'footer',
      dataReason: 'Synchrony'
    },
    {
      id: '2',
      text: i18n({ footer: 'privacyPolicy' }),
      url: `https://www.synchrony.com/${i18n({ footer: 'privacyPolicyLink' })}`,
      dataType: 'footer',
      dataReason: 'Online privacy policy'
    },
    {
      id: '3',
      text: i18n({ footer: 'usageAgreement' }),
      url: `https://www.mysynchrony.com/${i18n({
        footer: 'usageAgreementLink'
      })}`,
      dataType: 'footer',
      dataReason: 'Online usage'
    },
    {
      id: '4',
      text: i18n({ footer: 'accessibility' }),
      url: `https://www.synchrony.com/${i18n({
        footer: 'accessibilityLink'
      })}`,
      dataType: 'footer',
      dataReason: 'Accessibility'
    },
    {
      id: '5',
      text: i18n({ footer: 'fraudProtection' }),
      url: `https://www.synchronyfinancial.com/${i18n({
        footer: 'fraudProtectionlink'
      })}`,
      dataType: 'footer',
      dataReason: 'Fraud protection'
    }
  ]

  return (
    <CLFooter
      body={i18n({ footer: 'body' })}
      linkList={footerLinks}
      showCobrowse={isCobrowseEnabled}
      showCookieSetting={isShowCookieSetting}
      cookieSettingButtonLabel={i18n({ footer: 'cookieSettings' }) as string}
      data-test="vista-footer"
    />
  )
}
