import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'
import Button from 'syf-component-library/ui/atoms/Button'
import theme from 'theme'

export const MarketPlaceContainer = styled.div`
  background-color: ${theme.paleCyan};
  margin: 10px auto;
  width: 100%;
  position: relative;
  border-radius: 6px;
  @media ${query.greaterThanMedium} {
    width: 768px;
  }
`

export const MarketPlaceGrid = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
  align-items: center;
  gap: 12px;
  padding: ${spacing.xsmall}px 0;

  @media ${query.lessThanMedium} {
    padding: ${spacing.small}px ${spacing.medium}px ${spacing.small}px 0;
    gap: 16px;
  }
`

export const MarketPlaceContent = styled.div`
  margin-top: 8px;
`

export const MarketPlaceButton = styled(Button)`
  border: none;
  height: 24px;
  margin-left: 0px;
  font-size: 12px;
  min-width: 100px;
  font-weight: 600;
  padding: ${spacing.xsmall}px ${spacing.small}px;
  text-transform: none;
`

export const MarketPlaceTextContainer = styled.div`
  color: ${theme.darkCyanBlue};
`

export const ImageWrapper = styled.div`
  width: 86px;
`

export const MarketPlaceDescription = styled.p`
  font-size: ${fontSizes.root}px;
  @media ${query.lessThanMedium} {
    font-size: ${fontSizes.small}px;
  }
`

export const Image = styled.img`
  height: 100%;
`
