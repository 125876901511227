import { ReactElement } from 'react'

import handoverToRegisterAccount from 'helpers/handoverToRegisterAccount'
import i18n from 'strings/i18n'
import { AccountSummaryGetResponse } from 'syf-typings'

import { RegisterButton } from './subcomponents'

interface RegisterAccountProps {
  accountSummary: AccountSummaryGetResponse
}

const RegisterAccount = ({
  accountSummary
}: RegisterAccountProps): ReactElement => {
  const { lineOfBusiness, clientId } = accountSummary?.product || {}
  return (
    <RegisterButton
      onClick={() => handoverToRegisterAccount(lineOfBusiness, clientId)}
      buttonType="primary"
      data-test="register-button"
      data-type="register"
      data-reason="register"
      data-object="button"
      data-analytics="clicktrackingevent"
    >
      {i18n({ button: 'registerAccount' })}
    </RegisterButton>
  )
}

export default RegisterAccount
