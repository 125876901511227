import { ReactElement, RefObject, forwardRef, useState } from 'react'

import { useCreditAccounts } from 'const/syfApiQueries'
import { PaymentStatus } from 'notifications/typings/custom'
import { CollapsedCardNotification } from 'notifications/ui'
import CardFlip from 'notifications/ui/CardFlip'
import AccountHeader from 'pages/AccountsDashboard/Card/layouts/AccountHeader'
import CardBack from 'pages/AccountsDashboard/Card/layouts/CardBack'
import Collapse from 'syf-component-library/ui/atoms/Collapse'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import CardBody from './CardBody'
import {
  AccountCard,
  AccountHeaderWrapper,
  CardCollapsedLoader,
  CardColumn,
  CardLoader,
  CollapseHeaderWrapper
} from './subcomponents'

export interface CardLayoutProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
  isAccountSummaryLoading: boolean
  isError: boolean
  cardIndex: number
  notificationList: PaymentStatus[]
}

const collapseContainerId = 'collapse-container'

const CardLayout = (
  {
    accountSummary,
    account,
    cardIndex,
    isAccountSummaryLoading,
    isError,
    notificationList
  }: CardLayoutProps,
  focusRef: RefObject<HTMLDivElement>
): ReactElement => {
  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const windowWidth = useWindowSize()
  const isSingleAccount = accounts.length === 1
  const [isFlipped, setIsFlipped] = useState(false)
  const [isExpanded, setExpanded] = useState(isSingleAccount)
  const shouldCollapse =
    (isSingleAccount && isAccountSummaryLoading) || !isExpanded
  const accountId = account?.info?.['cipher.accountId']

  const handleHeaderOnClick = () => {
    if (!isSingleAccount) setExpanded(!isExpanded)
  }

  return (
    <CardColumn $cardIndex={cardIndex} $shouldCollapse={shouldCollapse}>
      <CardFlip isFlipped={isFlipped}>
        <>
          <AccountCard data-test="account-card-layout">
            {isAccountSummaryLoading ? (
              <Inset vertical="base">
                <CardCollapsedLoader
                  id={`collapsed-card-loader-${accountId}`}
                  data-test="loading-indicator"
                />
                <CardLoader
                  id={`card-loader${accountId}`}
                  data-test="loading-indicator"
                />
                <Stack hideOnBreakpointDown="medium" />
              </Inset>
            ) : (
              <div data-test="account-loaded">
                {windowWidth.isLessThanMobile ? (
                  <>
                    {!isExpanded ? (
                      <CollapseHeaderWrapper
                        data-test="collapse-header-wrapper"
                        isExpanded={isExpanded}
                        onClick={handleHeaderOnClick}
                        aria-expanded={isExpanded}
                        aria-controls={collapseContainerId}
                      >
                        <AccountHeaderWrapper
                          accountSummary={accountSummary}
                          account={account}
                          isExpanded={isExpanded}
                          showChevron={!isSingleAccount}
                          showBorder
                        />
                      </CollapseHeaderWrapper>
                    ) : (
                      <AccountHeaderWrapper
                        accountSummary={accountSummary}
                        account={account}
                        isExpanded={isExpanded}
                        showChevron={!isSingleAccount}
                        showBorder
                        handleIconClick={handleHeaderOnClick}
                      />
                    )}
                    <Collapse
                      expand={isExpanded}
                      data-test={collapseContainerId}
                      id={collapseContainerId}
                    >
                      <CardBody
                        isError={isError}
                        notificationList={notificationList}
                        handleOnClick={() => setIsFlipped(!isFlipped)}
                        account={account}
                        accountSummary={accountSummary}
                        ref={focusRef}
                      />
                    </Collapse>
                  </>
                ) : (
                  <>
                    <AccountHeader
                      accountSummary={accountSummary}
                      account={account}
                      showBorder
                    />
                    <CardBody
                      isError={isError}
                      notificationList={notificationList}
                      handleOnClick={() => setIsFlipped(!isFlipped)}
                      account={account}
                      accountSummary={accountSummary}
                      ref={focusRef}
                    />
                  </>
                )}
              </div>
            )}
          </AccountCard>
          {windowWidth.isLessThanMobile && !isExpanded ? (
            <CollapsedCardNotification notificationList={notificationList} />
          ) : null}
        </>
        <AccountCard data-test="account-card-back">
          <CardBack
            data-test="card-back"
            accountSummary={accountSummary}
            account={account}
            setIsFlipped={setIsFlipped}
            notificationList={notificationList}
            ref={focusRef}
          />
        </AccountCard>
      </CardFlip>
    </CardColumn>
  )
}

export default forwardRef(CardLayout)
