import { formatNumToCurrency } from 'helpers'
import { AccountSummaryGetResponse } from 'syf-typings'
import { DetailType, DetailTypeOptions } from 'ui/atoms/AccountDetail/typings'

import { formatDateString } from './dateFormatters'
import isAccountTypeSetPay from './isAccountTypeSetPay'

const getAccountDetail = (
  label: DetailTypeOptions,
  value: string
): DetailType => {
  let accountValue: string

  if (value) {
    accountValue =
      label === 'dueDate' ? formatDateString(value) : formatNumToCurrency(value)
  } else {
    accountValue = 'unavailable'
  }

  return {
    type: label,
    value: accountValue
  }
}

const getAccountCardDetails = (
  accountSummary: AccountSummaryGetResponse
): DetailType[] => {
  const {
    billing,
    installment,
    credit,
    product: { type: productType } = {}
  } = accountSummary
  const { nextPaymentDate, totalMinimumPaymentDue } = billing || {}
  const { availableCredit, currentBalance } = credit || {}
  const { principalAmount } = installment || {}
  const isSetpayAccount = isAccountTypeSetPay(productType)

  const currentBalanceAmount = getAccountDetail(
    'currentBalance',
    currentBalance
  )
  const totalMinimumPayment = getAccountDetail(
    'totalMinimumPayment',
    totalMinimumPaymentDue
  )
  const availableToSpend = getAccountDetail('availableToSpend', availableCredit)
  const loanAmount = getAccountDetail('loanAmount', principalAmount)
  const totalLoanBalance = getAccountDetail('totalLoanBalance', currentBalance)
  const nextAmountDue = getAccountDetail(
    'nextAmountDue',
    totalMinimumPaymentDue
  )
  const dueDate = getAccountDetail('dueDate', nextPaymentDate)

  const setpayAccountDetails = [
    loanAmount,
    totalLoanBalance,
    nextAmountDue,
    dueDate
  ]
  const revolvingAccountDetails = [
    currentBalanceAmount,
    availableToSpend,
    totalMinimumPayment,
    dueDate
  ]

  return isSetpayAccount ? setpayAccountDetails : revolvingAccountDetails
}

export default getAccountCardDetails
