import { PSCC, RC, SETPAY } from 'const/account/products'
import featureFlags from 'const/featureFlags'
import clearSessionStorage from 'helpers/clearSessionStorage'
import formPost from 'helpers/formPost'
import getViewAccountUrl from 'helpers/getViewAccountUrl'
import { WindowSize } from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import handleRevokeAccessToken from './handleRevokeAccessToken'
import redirectToUrl from './redirectToUrl'

type LinkTo = 'statements' | 'rewards'

const handleAccountLink = async (
  account: Account,
  clientId: string,
  windowWidth: WindowSize,
  linkTo?: LinkTo
): Promise<void> => {
  const { product } = account
  const viewAccountUrl = getViewAccountUrl(account, clientId, windowWidth)
  const { type: productType, lineOfBusiness } = product
  const enableDeeplink = featureFlags.isStatementsDeeplinkEnabled && linkTo

  if (productType === SETPAY) {
    redirectToUrl(viewAccountUrl)
  } else if (product.lineOfBusiness === RC) {
    const formData = {
      langId: localStorage.getItem('language'),
      sessionId: sessionStorage.getItem('correlationId'),
      guid: account.info['cipher.accountId'],
      clientId,
      ...(enableDeeplink && { linkTo })
    }
    formPost(viewAccountUrl, formData)
  } else if (lineOfBusiness === PSCC) {
    const formData = {
      idToken: sessionStorage.getItem('idToken'),
      sessionId: sessionStorage.getItem('correlationId'),
      'cipher.accountId': account.info['cipher.accountId'],
      accountIdType: 'guid'
    }
    formPost(viewAccountUrl, formData)
  }
  await handleRevokeAccessToken()
  clearSessionStorage()
}

export default handleAccountLink
