import { defaultCardArt } from 'const/assets'
import { AllAccountSummaryGetResponse } from 'const/syfApiQueries'
import { PlatformInformation } from 'iva-react-web-ui/types/UserMetadata'

const getIvaUserMetadata = (
  allAccountDetails: AllAccountSummaryGetResponse[]
): PlatformInformation[] => {
  return allAccountDetails
    .filter((account) => account?.product?.displayName)
    .map((account) => ({
      cardName: account.product?.displayName,
      accountNumber: account.accountInfo?.accountLast4,
      imageURL: account.card?.cardArtURL || defaultCardArt,
      enterpriseId: account.accountId,
      productType: account?.product?.type,
      clientId: account.product?.clientId
    }))
}
export default getIvaUserMetadata
