import { ReactElement } from 'react'
import { Provider } from 'react-redux'
import store from 'redux/store'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryClient from 'const/queryClient'
import CookieEnabled from 'guards/CookieEnabled'
import { IVAChatStatusProvider } from 'iva-react-web-ui'

import Dashboard from './Dashboard'

const App = (): ReactElement => {
  return (
    <CookieEnabled>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <IVAChatStatusProvider>
            <Dashboard />
          </IVAChatStatusProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </CookieEnabled>
  )
}

export default App
