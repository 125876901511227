import { clientUrls } from 'const/account/clients'
import { PSCC_REGISTER_ACCOUNT_URL_PATH, RC } from 'const/account/products'
import config from 'const/config'

import clearSessionStorage from './clearSessionStorage'
import handleRevokeAccessToken from './handleRevokeAccessToken'
import isAllowedHost from './isAllowedHost'
import redirectToUrl from './redirectToUrl'

const handoverToRegisterAccount = async (
  lineOfBusiness: string,
  clientId: string
): Promise<void> => {
  const isProductionEnvironment = config.APP_ENV === 'production'
  const PSCC_URL = isAllowedHost(sessionStorage.getItem('psccRedirectUrl'))
    ? sessionStorage.getItem('psccRedirectUrl')
    : config.PSCC_URL
  await handleRevokeAccessToken()
  clearSessionStorage()
  if (lineOfBusiness === RC) {
    const productionUrl = `${clientUrls[clientId]}/login/register`
    const uatRegistrationUrl = `${config.TARGET_ORIGIN()}/login/${clientId}/register`
    const redirectRCUrl = isProductionEnvironment
      ? productionUrl
      : uatRegistrationUrl
    redirectToUrl(redirectRCUrl)
  } else {
    redirectToUrl(`${PSCC_URL}/${PSCC_REGISTER_ACCOUNT_URL_PATH}`)
  }
}

export default handoverToRegisterAccount
