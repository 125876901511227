import { ReactElement } from 'react'

import i18n from 'strings/i18n'
import { DetailType } from 'ui/atoms/AccountDetail/typings'

import {
  LabelWrapper,
  PaymentDetailSection,
  ValueWrapper
} from './subcomponents'

export interface PaymentFieldsDetailProps {
  detail: DetailType
}

const PaymentFieldsDetail = ({
  detail
}: PaymentFieldsDetailProps): ReactElement => {
  const { value, type } = detail || {}

  if (!type) {
    return null
  }
  const detailValue =
    value === 'unavailable' ? i18n({ labels: 'unavailable' }) : value

  return (
    <PaymentDetailSection>
      <ValueWrapper>{detailValue}</ValueWrapper>
      <LabelWrapper>{i18n({ labels: type })}</LabelWrapper>
    </PaymentDetailSection>
  )
}
export default PaymentFieldsDetail
