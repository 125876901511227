import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAR_LINK } from 'const/icons'
import i18n from 'strings/i18n'
import { Small } from 'syf-component-library/ui/typography'

import {
  AccountEndingInWrapper,
  AccountLinkButton,
  AccountLinkText,
  CardName
} from './subcomponents'

interface CardBankDetailsProps {
  accountLast4: string
  cardName: string
  className?: string
  isFullPage?: boolean
  handleOnClick: () => void
  isLinkDisabled: boolean
  isSetpayAccount: boolean
}

const CardBankDetails = ({
  accountLast4,
  cardName,
  className,
  handleOnClick,
  isFullPage,
  isLinkDisabled,
  isSetpayAccount
}: CardBankDetailsProps): ReactElement => {
  const label = i18n({
    labels: isSetpayAccount ? 'loanEndingIn' : 'accountEndingIn'
  })

  return (
    <div className={className} data-test="card-bank-details">
      <CardName weight="bold" $isFullPage={isFullPage}>
        {cardName}
      </CardName>
      {isLinkDisabled ? (
        <Small>{`${label} ${accountLast4}`}</Small>
      ) : (
        <AccountLinkButton
          data-type="legacy account link"
          data-reason="legacy account link"
          data-object="link"
          data-analytics="clicktrackingevent"
          data-test="account-link-button"
          buttonType="text"
          onClick={handleOnClick}
        >
          <AccountLinkText weight="bold" isInline>
            <FontAwesomeIcon icon={FAR_LINK} />
            &nbsp;
            <AccountEndingInWrapper>{label}</AccountEndingInWrapper>
            {` ${accountLast4}`}
          </AccountLinkText>
        </AccountLinkButton>
      )}
    </div>
  )
}

export default CardBankDetails
