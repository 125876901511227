import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { Button } from 'syf-component-library/ui/atoms'
import { Body, Small } from 'syf-component-library/ui/typography'

export const CardName = styled(Body)<{
  $isFullPage?: boolean
}>`
  overflow: hidden;
  text-overflow: ${({ $isFullPage }) => !$isFullPage && 'ellipsis'};
  white-space: ${({ $isFullPage }) => !$isFullPage && 'nowrap'};
  /* gives breathing room between AccountHeader and VerticalIconWrapper */
  width: calc(100% - 8px);

  @media ${query.greaterThanSmall} {
    width: ${({ $isFullPage }) => ($isFullPage ? '100%' : '186px')};
  }
`

export const AccountLinkButton = styled(Button)`
  color: ${({ theme }) => theme.cyanBlue};
  margin: 0;
  padding: 0;
  height: 100%;
`

export const AccountLinkText = styled(Small)`
  text-decoration: inherit;
`

export const AccountEndingInWrapper = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  text-decoration: inherit;

  @media ${query.greaterThanMedium} {
    max-width: 140px;
  }

  @media ${query.greaterThanHuge} {
    max-width: fit-content;
  }
`
