import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import routes from 'const/routes'
import i18n from 'strings/i18n'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import Button from 'syf-component-library/ui/atoms/Button'
import { Inline, Inset, Stack } from 'syf-component-library/ui/spacing'
import { Body } from 'syf-component-library/ui/typography'
import { useDispatch } from 'react-redux'
import { updateModalStatus } from 'redux/modals/actions'

interface ModalFooterProps {
  closeModal: () => void
}

export const TabsWrapper = styled.div`
  display: flex;

  @media ${query.lessThanSmall} {
    margin: 0 ${spacing.small}px;
  }
`

export const TabsContainer = styled.div`
  width: 100%;
`

export const DrawerHeaderWrapper = styled(Inset)`
  @media ${query.greaterThanSmall} {
    padding: ${spacing.large}px 0 0 ${spacing.xlarge}px;
  }
`
export const HorizontalBorder = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`

export const ViewAllActivityMessage = styled(Body)`
  text-align: center;
  max-width: 464px;
  margin: 0 auto;
  color: ${({ theme }) => theme.silver};

  @media ${query.lessThanSmall} {
    max-width: 260px;
  }
`

const FooterWrapper = styled(Inset)`
  display: flex;
  background: ${({ theme }) => theme.lightestGrey};
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.lightGrey};

  @media ${query.greaterThanSmall} {
    flex-direction: row;
    padding: ${spacing.large}px;
    justify-content: flex-end;
    align-items: center;
  }
`

const FooterButton = styled(Button)`
  margin: 0;
  min-width: 100%;

  @media ${query.greaterThanSmall} {
    min-width: 120px;
  }
`

const ViewAllActivityButton = styled(Button)`
  min-width: 100%;
  padding: 0;
`

export const ModalFooter = ({ closeModal }: ModalFooterProps): ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleAccountActivity = () => {
    dispatch(updateModalStatus(''))
    setTimeout(() => {
      navigate(routes.ACTIVITY)
    }, 400)
  }

  return (
    <FooterWrapper vertical="base" horizontal="large">
      <div>
        <ViewAllActivityButton
          buttonType="text"
          onClick={() => handleAccountActivity()}
          data-type="view all activity"
          data-reason="view all activity"
          data-object="button"
          data-analytics="clicktrackingevent"
        >
          {i18n({ button: 'viewAllActivity' })}
        </ViewAllActivityButton>
        <Stack hideOnBreakpointUp="small" />
      </div>
      <Inline size="xlarge" hideOnBreakpointDown="small" />
      <FooterButton
        buttonType="primary"
        onClick={closeModal}
        data-test="close-button"
        data-type="close"
        data-reason="close"
        data-object="button"
        data-analytics="clicktrackingevent"
      >
        {i18n({ button: 'close' })}
      </FooterButton>
    </FooterWrapper>
  )
}
