import { redirectToAuth } from 'authorization-module'
import config from 'const/config'
import routes from 'const/routes'
import getApigeeClientId from 'helpers/getApigeeClientId'
import getLoginHintUrl from 'helpers/getLoginHintUrl'
import { Modals } from 'redux/modals/typings'
import getQueryParam from 'syf-js-utilities/formats/getQueryParam'

export interface AuthenticationRedirectProps {
  scope: string
  modal?: Modals
  navigateTo: string
}

const authenticationRedirect = (
  options?: AuthenticationRedirectProps
): void => {
  const client = getQueryParam('client') || localStorage.getItem('client')
  const authDomain = config.AUTH_DOMAIN
  const apigeeClientId = getApigeeClientId(client)
  const redirectUri = `${window.location.origin}${config.BASENAME}${routes.CALLBACK}`
  const loginHintUrl = getLoginHintUrl(client)

  if (options?.navigateTo && options?.scope) {
    const { scope, navigateTo } = options

    redirectToAuth(
      authDomain,
      apigeeClientId,
      redirectUri,
      loginHintUrl,
      scope,
      navigateTo
    )

    return
  }

  redirectToAuth(authDomain, apigeeClientId, redirectUri, loginHintUrl)
}

export const handleMFEAuthenticationRedirect = ({
  navigateTo,
  scope,
  modal
}: AuthenticationRedirectProps) => {
  const props = {
    navigateTo: JSON.stringify({
      openModal: modal,
      route: navigateTo
    }),
    scope
  }
  authenticationRedirect(props)
}

export default authenticationRedirect
