import { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'

import routes from 'const/routes'
import { useAccountSummary, useCreditAccounts } from 'const/syfApiQueries'
import findAccount from 'helpers/findAccount'
import shouldShowAccountServiceButton from 'pages/AccountServices/shouldShowAccountServiceButton'
import PaymentSectionHeader from 'pages/PageLayout/PaymentSectionHeader'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'

import MenuOptions from './MenuOptions'
import { NavigationContainer, PageWrapper } from './subcomponents'

const PageLayout = (): ReactElement => {
  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  const windowWidth = useWindowSize()
  const location = useLocation()

  const { data: { accounts = [] } = {}, isError: isCreditError } =
    useCreditAccounts()

  const {
    data: accountSummary,
    isLoading,
    isError: isAccountSummaryError
  } = useAccountSummary(selectedAccount)

  if (isAccountSummaryError || isCreditError) {
    return <Navigate to={routes.ERROR} />
  }

  const account = findAccount(accounts, selectedAccount)
  const showAccountServiceMenu = shouldShowAccountServiceButton(accountSummary)

  return (
    <>
      <PaymentSectionHeader
        isLoading={isLoading}
        account={account}
        accountSummary={accountSummary}
      />
      <NavigationContainer>
        {!windowWidth.isLessThanMobile && (
          <MenuOptions
            showAccountServiceMenu={showAccountServiceMenu}
            activeLink={location.pathname}
            accountId={selectedAccount}
          />
        )}

        <PageWrapper>
          <Outlet />
        </PageWrapper>
      </NavigationContainer>
    </>
  )
}

export default PageLayout
