import { ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  FAR_GEAR,
  FAS_BADGE_PERCENT,
  FAS_GIFT,
  FAS_LIST_ALT
} from 'const/icons'
import routes from 'const/routes'
import getAccountCardDetails from 'helpers/getAccountCardDetails'
import PaymentButton from 'pages/AccountsDashboard/Card/actions/PaymentButton'
import shouldShowAccountServiceButton from 'pages/AccountServices/shouldShowAccountServiceButton'
import i18n from 'strings/i18n'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Stack } from 'syf-component-library/ui/spacing'
import { Account, AccountSummaryGetResponse } from 'syf-typings'

import { useAccountFeatures } from 'const/syfApiQueries'
import featureFlags from 'const/featureFlags'
import PaymentFieldsDetail from './PaymentFieldsDetail'
import {
  ButtonContainer,
  IconContainer,
  MenuButton,
  MenuContainer,
  PaymentWrapper
} from './subcomponents'

interface PaymentDetailHeaderProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
}

const paymentButtonDataType = {
  'account-services': 'account services',
  activity: 'activity'
}

const PaymentDetailHeader = ({
  accountSummary,
  account
}: PaymentDetailHeaderProps): ReactElement => {
  const windowWidth = useWindowSize()
  const navigate = useNavigate()
  const location = useLocation()

  const cardDetails = accountSummary
    ? getAccountCardDetails(accountSummary)
    : []
  const showAccountServiceMenu = shouldShowAccountServiceButton(accountSummary)
  const onNavigateHandler = (routeUrl: string) => {
    navigate(routeUrl)
  }

  const { data: accountFeatures } = useAccountFeatures(
    account?.info?.['cipher.accountId']
  )

  const { accessToRewardsMfe, accessToRewards } =
    accountFeatures?.rewardDetails || {}

  const isRewardsButtonEnabled =
    featureFlags.isRewardsDeeplinkEnabled &&
    accessToRewardsMfe &&
    accessToRewards

  const isPromotionsButtonEnabled =
    featureFlags.isPromotionsMfeEnabled && accountFeatures?.accessToPromotions

  return (
    <PaymentWrapper>
      <PaymentFieldsDetail detail={cardDetails[0]} />
      <PaymentFieldsDetail detail={cardDetails[1]} />
      <PaymentFieldsDetail detail={cardDetails[2]} />
      <PaymentFieldsDetail detail={cardDetails[3]} />
      <Stack hideOnBreakpointUp="small" />
      {accountSummary && account && (
        <ButtonContainer>
          <PaymentButton
            className="make-payment-btn"
            account={account}
            accountSummary={accountSummary}
            dataType={paymentButtonDataType[location.pathname.replace('/', '')]}
          />
        </ButtonContainer>
      )}
      {windowWidth.isLessThanMobile && (
        <MenuContainer>
          <MenuButton
            buttonType="text"
            data-type="activity"
            data-reason="activity"
            data-object="button"
            data-analytics="clicktrackingevent"
            onClick={() => {
              onNavigateHandler(routes.ACTIVITY)
            }}
          >
            <IconContainer icon={FAS_LIST_ALT} />
            {i18n({
              labels: 'activityMenu'
            })}
          </MenuButton>
          {isPromotionsButtonEnabled && (
            <MenuButton
              buttonType="text"
              data-type="promotions"
              data-reason="header-nav"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="promotions-nav-link"
              onClick={() => {
                onNavigateHandler(routes.PROMOTIONS)
              }}
            >
              <IconContainer icon={FAS_BADGE_PERCENT} />
              {i18n({
                labels: 'promotionsMenu'
              })}
            </MenuButton>
          )}
          {showAccountServiceMenu && (
            <MenuButton
              buttonType="text"
              data-type="account services"
              data-reason="account services"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="account-services"
              onClick={() => {
                onNavigateHandler(routes.ACCOUNT_SERVICES)
              }}
            >
              <IconContainer icon={FAR_GEAR} />
              {i18n({
                labels: 'accountsServices'
              })}
            </MenuButton>
          )}
          {isRewardsButtonEnabled && (
            <MenuButton
              buttonType="text"
              data-type="rewards"
              data-reason="rewards"
              data-object="button"
              data-analytics="clicktrackingevent"
              data-test="rewards"
              onClick={() => {
                onNavigateHandler(routes.REWARDS)
              }}
            >
              <IconContainer icon={FAS_GIFT} />
              {i18n({
                button: 'rewards'
              })}
            </MenuButton>
          )}
        </MenuContainer>
      )}
    </PaymentWrapper>
  )
}

export default PaymentDetailHeader
