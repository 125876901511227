import { ModalAction, ModalInitialState } from './typings'

const initialState: ModalInitialState = {
  selectedAccount: '',
  modalId: ''
}

export default function reducer(
  state: ModalInitialState = initialState,
  action: ModalAction = undefined
): ModalInitialState {
  switch (action.type) {
    case 'UPDATE_MODAL_STATUS': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'UPDATE_SELECTED_ACCOUNT': {
      return {
        ...state,
        selectedAccount: action.payload
      }
    }
    default:
      return state
  }
}
