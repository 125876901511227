import { ConditionValues, EnrollmentValues } from 'syf-typings'

export const ACCOUNT_OPEN: ConditionValues = 'OPEN'
export const ACCOUNT_SHELL: ConditionValues = 'SHELL'
export const ACCOUNT_CLOSED: ConditionValues = 'CLOSED'
export const ACCOUNT_AUTH_PROHIBITED: ConditionValues = 'AUTH_PROHIBITED'
export const ACCOUNT_CHARGED_OFF: ConditionValues = 'CHARGED_OFF'
export const ACCOUNT_CLOSED_WITH_BALANCE: ConditionValues =
  'CLOSED_WITH_BALANCE'
export const ACCOUNT_CREDIT_LINE_INCREASE_ELIGIBLE: ConditionValues =
  'CREDIT_LINE_INCREASE_ELIGIBLE'
export const ACCOUNT_DELINQUENT: ConditionValues = 'DELINQUENT'
export const ACCOUNT_OVERLIMIT: ConditionValues = 'OVERLIMIT'
export const ACCOUNT_CREDIT_BALANCE: ConditionValues = 'CREDIT_BALANCE'
export const ACCOUNT_UPGRADING: ConditionValues = 'UPGRADING'
export const CREDIT_HEALTH: EnrollmentValues = 'CREDIT_HEALTH'
export const WAIVED_INTEREST: EnrollmentValues = 'WAIVED_INTEREST'
