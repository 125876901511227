import { maxVerticalCardWidth } from 'const/styles'
import styled, { css, keyframes } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import CLVerticalCardCollapsedLoader from 'syf-component-library/ui/atoms/Loader/Skeleton/VerticalCardCollapsedLoader'
import CLVerticalCardLoader from 'syf-component-library/ui/atoms/Loader/Skeleton/VerticalCardLoader'
import { Inset } from 'syf-component-library/ui/spacing'

import AccountHeader from '../AccountHeader'

interface CardNumberProps {
  $cardIndex: number
  $shouldCollapse?: boolean
}

export const AccountCard = styled(Card)`
  position: relative;

  @media ${query.greaterThanSmall} {
    width: ${maxVerticalCardWidth}px;
    height: 516px;
  }
`

export const AccountCardBody = styled(Inset)`
  height: 320px;
`

export const CardLoader = styled(CLVerticalCardLoader)`
  display: none;

  @media ${query.greaterThanSmall} {
    display: block;
  }
`

export const CardCollapsedLoader = styled(CLVerticalCardCollapsedLoader)`
  display: none;

  @media ${query.lessThanSmall} {
    display: block;
  }
`

const slideCards = ($cardIndex: number) => keyframes`
  0% {
     left: calc(100% + ${$cardIndex} * 100%);
  }
  100% {
     left: 0;
  }
`

const collapseStyles = css`
  width: 100%;

  ${AccountCard} {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    max-width: 476px;

    @media ${query.greaterThanSmall} {
      padding-left: ${spacing.xsmall};
    }
  }
`

export const RegisterAccountWrapper = styled(Inset)`
  background-color: ${({ theme }) => theme.mediumGrey};
  border-radius: 0 0 10px 10px;
`

export const CardColumn = styled.div<CardNumberProps>`
  @media ${query.lessThanSmall} {
    position: relative;
    animation: ${({ $cardIndex }) => slideCards($cardIndex)} 700ms;
    transition: width 0.3s;
    width: calc(100% - ${spacing.small}px);
    padding-left: ${spacing.small}px;
    ${({ $shouldCollapse }) => $shouldCollapse && collapseStyles}
  }
  @media ${query.greaterThanSmall} {
    flex-basis: unset;
  }
`

const ClickableHeaderBorder = `
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  cursor: pointer;
`

export const CollapseHeaderWrapper = styled.button<{
  isExpanded: boolean
}>`
  padding: 0;
  color: inherit;
  background: none;
  border: none;
  text-align: left;
  box-shadow: none;
  width: 100%;
  ${({ isExpanded }) => isExpanded && ClickableHeaderBorder}
`

export const AccountHeaderWrapper = styled(AccountHeader)`
  border-bottom: 0px;
  width: 100%;
`
