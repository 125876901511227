import { INTERNAL_SERVER_ERROR_CODE } from 'const/errorCodes'
import { BusinessErrorCode, ScreenType } from '../typings'

export const getErrorScreenType = (
  status: number,
  businessErrorCode?: BusinessErrorCode
): ScreenType => {
  let screenType: ScreenType = 'SomethingWrong'

  if (status === INTERNAL_SERVER_ERROR_CODE) {
    return 'SomethingWrong'
  }

  switch (businessErrorCode) {
    case '600001':
      screenType = 'NotSupported'
      break
    case '600002':
    case '600006':
      screenType = 'UnableToAdd'
      break
    case '600003':
      screenType = 'IncorrectInfo'
      break
    case '600004':
    case '600005':
    case '600009':
    case '600010':
      screenType = 'SomethingWrongForThisAccount'
      break
    case '600011':
      screenType = 'AlreadyAdded'
      break
    case '600012':
      screenType = 'SomethingWrong'
      break
    case '600013':
      screenType = 'MaxAttempt'
      break
    default:
      screenType = 'SomethingWrong'
      break
  }

  return screenType
}
