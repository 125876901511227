import { ReactElement, useEffect, useState } from 'react'

import i18n from 'strings/i18n'

import getTimeOfDay from './getTimeOfDay'
import {
  PersonalGreetingContainer,
  PersonalGreetingHeading
} from './subcomponents'

const PersonalGreeting = (): ReactElement => {
  const [timeOfDay, setTimeOfDay] = useState(getTimeOfDay(new Date()))

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeOfDay(getTimeOfDay(new Date()))
    }, 60000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <PersonalGreetingContainer>
      <PersonalGreetingHeading as="h1" weight="light">
        {i18n({ personalGreeting: timeOfDay })}
      </PersonalGreetingHeading>
    </PersonalGreetingContainer>
  )
}

export default PersonalGreeting
