interface DynamicYieldPayload {
  type: string
  location: string
  selectorGroups: string
}

const dynamicYieldPayload: DynamicYieldPayload = {
  type: 'OTHER',
  location: 'www.syf.com',
  selectorGroups: 'digital-servicing-vista'
}

export default dynamicYieldPayload
