import i18n from 'strings/i18n'
import { ScreenType } from '../typings'

const getButtonDetails = (
  screenType: ScreenType,
  handleAddAccount: () => Promise<void>,
  handleClose: () => void
) => {
  if (screenType === 'Default' || screenType === 'IncorrectInfo') {
    return {
      primaryButtonText: `${i18n({ button: 'addCard' })}`,
      secondaryButtonText: `${i18n({ button: 'cancel' })}`,
      handlePrimarybuttonClick: handleAddAccount,
      handleSecondarybuttonClick: handleClose
    }
  }
  return {
    primaryButtonText: `${i18n({ button: 'close' })}`,
    secondaryButtonText: undefined,
    handlePrimarybuttonClick: handleClose,
    handleSecondarybuttonClick: undefined
  }
}

export { getButtonDetails }
