import {
  UseMutationOptions,
  UseMutationResult,
  useMutation
} from '@tanstack/react-query'
import endpoints from 'const/apiEndpoints'
import queryClient from 'const/queryClient'
import { TError, creditAccountsCacheKey } from 'syf-api'
import getFetchWrapper from 'syf-api/api/getFetchWrapper'
import { MFEConfig } from 'syf-js-utilities'

interface AddAccountResponse {
  success: boolean
}

export interface AddAccountVariables {
  accountNumber: string
  ssn: string
  dateOfBirth: string
}

interface AddAccountMutationOptions<
  TData,
  TVariables = AddAccountVariables,
  TContext = unknown
> {
  config: MFEConfig
  mutationOptions?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >
}

export const useAddAccountMutation = ({
  config,
  mutationOptions = {}
}: AddAccountMutationOptions<
  AddAccountResponse,
  AddAccountVariables
>): UseMutationResult<AddAccountResponse, TError, AddAccountVariables> => {
  const fetchWrapper = getFetchWrapper(config)
  const addAccountURL = new URL(
    endpoints.ADD_MISSING_ACCOUNT,
    config.hosts.apigee
  )

  const mutationFunction = async (
    payloadData: AddAccountVariables
  ): Promise<AddAccountResponse> => {
    const { accountNumber, ssn, dateOfBirth } = payloadData

    await fetchWrapper(addAccountURL, {
      method: 'POST',
      body: JSON.stringify({
        'cipher.accountNumber': accountNumber,
        'cipher.ssn': ssn,
        'cipher.dateOfBirth': dateOfBirth
      })
    })

    return { success: true }
  }

  return useMutation<AddAccountResponse, TError, AddAccountVariables>({
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries(creditAccountsCacheKey())
    },
    ...mutationOptions
  })
}
