import { GivenNameAction } from './typings'

export const initialState: string =
  navigator.cookieEnabled && sessionStorage.getItem('givenName')

const reducer = (
  state: string = initialState,
  action: GivenNameAction = undefined
): string => {
  if (action.type === 'UPDATE_GIVEN_NAME') {
    return action.payload
  }
  return state
}

export default reducer
