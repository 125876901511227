import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import mediaQueries from 'syf-component-library/const/mediaQueries'
import { Button } from 'syf-component-library/ui/atoms'
import SimpleModalPortal from 'syf-component-library/ui/patterns/SimpleModalPortal'

export const Modal = styled(SimpleModalPortal)`
  width: 95%;
  padding-bottom: 43px;
  text-align: center;
  @media ${mediaQueries.greaterThanSmall} {
    width: 500px;
    max-height: 400px;
  }
`

export const FooterWrapper = styled.div`
  display: flex;

  @media ${mediaQueries.lessThanMedium} {
    flex-direction: column-reverse;
  }
`

export const Icon = styled(FontAwesomeIcon)`
  height: 32px;
  width: 100%;
  margin: auto;
`

export const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.body.background};
`
