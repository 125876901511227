import { PSCC, RC } from 'const/account/products'
import config from 'const/config'
import { AccountInfoProduct } from 'syf-typings/creditAccounts'

import clearSessionStorage from './clearSessionStorage'
import formPost from './formPost'
import handleRevokeAccessToken from './handleRevokeAccessToken'
import isAllowedHost from './isAllowedHost'
import redirectToUrl from './redirectToUrl'

export const handoverToRCPayAsGuest = (clientId: string): void => {
  const langId = localStorage.getItem('language')
  try {
    const formData = {
      clientId,
      langId
    }
    return formPost(
      `${config.TARGET_ORIGIN(clientId)}/quickpay/dashboardstart`,
      formData
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

const handoverToPayAsGuest = async (
  product: AccountInfoProduct,
  clientId: string
): Promise<void> => {
  const langId = localStorage.getItem('language')

  if (
    product.lineOfBusiness === PSCC &&
    isAllowedHost(sessionStorage.getItem('psccRedirectUrl'))
  ) {
    try {
      const psccUrl = new URL(sessionStorage.getItem('psccRedirectUrl'))
      redirectToUrl(`${psccUrl.origin}/mobilewebpay?langId=${langId}`)
    } catch (error) {
      redirectToUrl(
        `${config.PSCC_PAY_AS_GUEST_URL}/mobilewebpay?langId=${langId}`
      )
    }
  } else if (product.lineOfBusiness === PSCC) {
    redirectToUrl(
      `${config.PSCC_PAY_AS_GUEST_URL}/mobilewebpay?langId=${langId}`
    )
  } else if (product.lineOfBusiness === RC) {
    handoverToRCPayAsGuest(clientId)
  }
  await handleRevokeAccessToken()
  clearSessionStorage()
  return null
}

export default handoverToPayAsGuest
