import { ReactElement, useEffect, useRef, useState } from 'react'

import AddAccountButton from 'App/AddAccountModal/AddAccountButton'
import Loader from 'authorization-module/ui/atoms/Loader'
import featureFlags from 'const/featureFlags'
import queryClient from 'const/queryClient'
import {
  useAllAccountSummary,
  useAllProfile,
  useAutoRegisterUpdate,
  useCreditAccounts
} from 'const/syfApiQueries'
import getAutoRegisterPayload from 'helpers/getAutoRegisterPayload'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import WelcomeInterstitial from 'pages/AccountsDashboard/WelcomeInterstitial'
import i18n from 'strings/i18n'
import { creditAccountsCacheKey } from 'syf-api'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import PersonalGreeting from 'ui/patterns/PersonalGreeting'

import NotificationCenter from 'ui/atoms/NotificationCenter'
import { formatStringAsDate } from 'helpers/dateFormatters'
import { differenceInCalendarDays } from 'date-fns'
import Card from './Card'
import DynamicYieldGrid from './DynamicYieldGrid'
import {
  AccountManagerContainer,
  AccountManagerGrid,
  AccountManagerText,
  AccountsContainer,
  DashboardAccountGrid,
  DashboardContainer,
  MainContainer,
  NotificationContainer,
  PageWrapper
} from './subcomponents'
import TechnicalError from './TechnicalError'

const CIPHER_ANNUAL_INCOME_LAST_UPDATED = 'cipher.annualIncomeLastUpdated'

const AccountsDashboard = (): ReactElement => {
  // To restrict from running in loop wrt creditaccount in case of any API issues
  const autoregisterCounter = useRef(0)
  const [notificationList, setNotificationList] = useState<
    { id: string; name: string }[] | null
  >()
  const { isLoading: isAutoRegisterLoading, mutate: mutateAutoRegister } =
    useAutoRegisterUpdate()

  const {
    data: { accounts = [], electronicConsent } = {},
    isLoading: isAccountsLoading,
    error: accountError,
    isError: isCreditAccountsAPIError
  } = useCreditAccounts({
    onSuccess: (data) => {
      const accountsLength = data?.accounts?.length
      if (!accountsLength) {
        triggerAnalyticsEvent({
          pageName: 'error',
          MessageType: 'technical error',
          ErrorMessage: `${i18n({ errors: 'unableToFindAccount' })}`
        })
      }
      if (data.electronicConsent) {
        const accountsToAutoRegister = getAutoRegisterPayload(data.accounts)

        if (accountsToAutoRegister.length && autoregisterCounter.current < 1) {
          mutateAutoRegister(accountsToAutoRegister, {
            onSuccess: () => {
              queryClient.invalidateQueries(creditAccountsCacheKey())
              autoregisterCounter.current += 1
            }
          })
        }
      }
    }
  })

  const profileResponse = useAllProfile(accounts)
  const isAllProfileFetched = useAllProfile(accounts).every(
    (query) => query.isFetched
  )
  const isAllProfileError = useAllProfile(accounts).some(
    (queryData) => queryData.isError
  )

  useEffect(() => {
    if (profileResponse.length > 0 && isAllProfileFetched) {
      const notificationAccounts = profileResponse
        .map((acc) => {
          const aniLastUpdatedDate =
            acc.data?.profile[CIPHER_ANNUAL_INCOME_LAST_UPDATED] &&
            formatStringAsDate(
              acc.data?.profile[CIPHER_ANNUAL_INCOME_LAST_UPDATED],
              '/'
            )
          const days = aniLastUpdatedDate
            ? differenceInCalendarDays(new Date(), aniLastUpdatedDate)
            : 241

          if (days > 240) {
            return {
              id: acc?.data?.accountId,
              name: acc?.data?.accountName
            }
          }
          return { id: null, name: null }
        })
        .filter((acc) => acc.id !== null && acc.name)
      setNotificationList(notificationAccounts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileResponse.length, isAllProfileFetched])

  const numberOfAccounts = accounts?.length

  useEffect(() => {
    if (numberOfAccounts) {
      triggerAnalyticsEvent({
        numberOfAccounts: `${numberOfAccounts}`
      })
    }
  }, [numberOfAccounts])

  useEffect(() => {
    if (isCreditAccountsAPIError) {
      triggerAnalyticsEvent({
        pageName: 'error',
        MessageType: 'technical error',
        ErrorMessage: `${i18n({ errors: 'technicalDifficulties' })}`
      })
    }
  }, [isCreditAccountsAPIError])

  const isAllAccountSummaryLoading = useAllAccountSummary(accounts).some(
    (queryData) => queryData.isLoading
  )

  const isAllAccountSummaryError = useAllAccountSummary(accounts).some(
    (queryData) => queryData.isError
  )

  const { isDYEnabled } = featureFlags
  const isAccountEmpty = !numberOfAccounts
  const previouslyVisited = localStorage.getItem('previouslyVisited')
  const isAccountsOrAutoRegisterLoading =
    isAccountsLoading || isAutoRegisterLoading
  const showDYCampaigns = !(
    isAllAccountSummaryLoading || isAccountsOrAutoRegisterLoading
  )
  const isCreditOrSummaryError =
    isCreditAccountsAPIError || isAllAccountSummaryError
  const renderWelcomeInterstitial =
    !isAccountsLoading &&
    (!previouslyVisited || !electronicConsent) &&
    !isAutoRegisterLoading &&
    !accountError &&
    !isAccountEmpty

  const showAddAccount =
    !isCreditAccountsAPIError &&
    !isAccountsLoading &&
    featureFlags.isAddAccountEnabled

  const shouldShowNotificationCenter =
    featureFlags.isNotificationCenterEnabled &&
    notificationList &&
    notificationList?.length > 0 &&
    !isCreditAccountsAPIError &&
    !isAllAccountSummaryError &&
    !isAllProfileError

  useEffect(() => {
    analyticsConfig.defaults.PageKind = 'multiaccount'
    triggerAnalyticsEvent({
      PageFunction: 'dashboard',
      PageKind: 'multiaccount',
      previouslyVisited: `${Boolean(previouslyVisited)}`
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper data-test="account-dashboard">
      {renderWelcomeInterstitial && <WelcomeInterstitial />}
      <DashboardContainer vertical="base" hideOnBreakpointDown="small">
        <PersonalGreeting />
        {isAccountsOrAutoRegisterLoading ? (
          <Loader />
        ) : (
          <MainContainer
            shouldShowNotificationCenter={shouldShowNotificationCenter}
          >
            <AccountsContainer>
              {showAddAccount && (
                <AccountManagerContainer>
                  <AccountManagerGrid id="add-account-container-id">
                    <AccountManagerText as="h2" weight="bold">
                      {i18n({ labels: 'yourAccounts' })}
                    </AccountManagerText>
                    <AddAccountButton />
                  </AccountManagerGrid>
                </AccountManagerContainer>
              )}
              <DashboardAccountGrid
                shouldShowNotificationCenter={shouldShowNotificationCenter}
              >
                {accountError || isAccountEmpty ? (
                  <TechnicalError
                    errorType={
                      accountError ? 'technicalError' : 'noAccountsFound'
                    }
                  />
                ) : (
                  accounts.map((account, index) => {
                    const { info } = account
                    const accountId = info['cipher.accountId']
                    return (
                      <Card
                        key={accountId}
                        accountId={accountId}
                        cardIndex={index}
                        data-test="account-card"
                      />
                    )
                  })
                )}
              </DashboardAccountGrid>
            </AccountsContainer>
            {shouldShowNotificationCenter && (
              <NotificationContainer data-test="notification-center">
                <NotificationCenter accounts={notificationList} />
              </NotificationContainer>
            )}
          </MainContainer>
        )}
      </DashboardContainer>
      {showDYCampaigns &&
        isDYEnabled &&
        !isCreditOrSummaryError &&
        !isAccountEmpty && <DynamicYieldGrid />}
    </PageWrapper>
  )
}

export default AccountsDashboard
