import { AutoRegisterPutRequest } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

const getAutoRegisterPayload = (
  accounts: Account[]
): AutoRegisterPutRequest[] => {
  const autoRegistrationPayload: AutoRegisterPutRequest[] = []

  accounts.forEach((account) => {
    const {
      registration: {
        registered,
        eligibleForAutoRegistration,
        transferable,
        registeredByVirtueOf
      },
      product: { lineOfBusiness }
    } = account
    if (!registered && (eligibleForAutoRegistration || transferable)) {
      autoRegistrationPayload.push({
        targetAccountId: account.info['cipher.accountId'],
        eligibleForAutoRegistration,
        registered,
        registeredAccountId: registeredByVirtueOf,
        transferable,
        lineOfBusiness
      })
    }
  })
  return autoRegistrationPayload
}

export default getAutoRegisterPayload
