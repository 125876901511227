import { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import { mmaConfig } from 'const/mfeConfig'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import useHandleNavigate from 'hooks/useHandleNavigate'
import ActivityTable from 'mma-mfe'

import ActivityTableWrapper from './subcomponents'

const Activity = (): ReactElement => {
  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  const { handleNavigate } = useHandleNavigate()

  useNavigateOnRedirect()

  return (
    <ActivityTableWrapper>
      <ActivityTable
        config={mmaConfig}
        guidAccountId={selectedAccount}
        navigationHandler={handleNavigate}
      />
    </ActivityTableWrapper>
  )
}

export default Activity
