import { DisputesMFEConfig } from 'digital-disputes'
import getLanguageFromStorage from 'helpers/getLanguageFromStorage'
import { MmaMFEConfig } from 'mma-mfe/lib/src/services/mma-config'
import { MFEConfig } from 'syf-js-utilities/typings/mfeConfig'

import config from './config'

const mfeConfig: MFEConfig = {
  hosts: {
    apigee: new URL(config.API_HOST),
    assets: new URL(config.ASSETS_HOST)
  },
  apiErrorCallback: (error) => error,
  lang: getLanguageFromStorage()
}

const serviceFilesMfeUrl =
  config.APP_ENV === 'production'
    ? 'https://images.synchronycredit.com/serviceFiles/json/prod-mma-config.json'
    : `https://images.synchronycredit.com/serviceFiles/json/nonprod-mma-config.json`

// TODO: Combine mmaConfig and mfeConfig into a single object
const mmaConfig: MmaMFEConfig = {
  hosts: {
    apigee: new URL(config.API_HOST),
    assets: new URL(config.ASSETS_HOST),
    serviceFile: new URL(serviceFilesMfeUrl)
  },
  apiErrorCallback: (error) => error,
  lang: getLanguageFromStorage()
}

const serviceFilesDisputesUrl =
  config.APP_ENV === 'production'
    ? 'https://images.synchronycredit.com/serviceFiles/json/prod-disputes-config.json'
    : 'https://images.synchronycredit.com/serviceFiles/json/nonprod-disputes-config.json'

const disputesConfig: DisputesMFEConfig = {
  hosts: {
    apigee: new URL(config.API_HOST),
    assets: new URL(config.ASSETS_HOST),
    serviceFile: new URL(serviceFilesDisputesUrl)
  },
  lang: getLanguageFromStorage(),
  apiErrorCallback: (error) => error
}

export { disputesConfig, mfeConfig, mmaConfig }
