import config from 'const/config'
import featureFlags from 'const/featureFlags'

const loadDynamicYieldScript = () => {
  if (featureFlags.isDYScriptEnabled) {
    const dynamicScript = document.createElement('script')
    dynamicScript.type = 'text/javascript'
    dynamicScript.src = `//cdn.dynamicyield.com/api/${config.DY_SECTION_ID}/api_dynamic.js`
    document.head.append(dynamicScript)

    const staticScript = document.createElement('script')
    staticScript.type = 'text/javascript'
    staticScript.src = `//cdn.dynamicyield.com/api/${config.DY_SECTION_ID}/api_static.js`
    document.head.append(staticScript)
  }
}

export default loadDynamicYieldScript
