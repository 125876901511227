import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

type IconProp = [IconPrefix, IconName]

// Icon weights
const FAL = 'fal'
const FAR = 'far'
const FAS = 'fas'

// Light
export const FAL_ANGLE_DOWN: IconProp = [FAL, 'angle-down']
export const FAL_ANGLE_LEFT: IconProp = [FAL, 'angle-left']
export const FAL_ANGLE_RIGHT: IconProp = [FAL, 'angle-right']
export const FAL_ANGLE_UP: IconProp = [FAL, 'angle-up']
export const FAL_BELL: IconProp = [FAL, 'bell']
export const FAL_CALCULATOR_ALT: IconProp = [FAL, 'calculator-alt']
export const FAL_CALENDAR_ALT: IconProp = [FAL, 'calendar-alt']
export const FAL_CHECK_CIRCLE: IconProp = [FAL, 'check-circle']
export const FAL_CLOCK: IconProp = [FAL, 'clock']
export const FAL_CLOUD_RAIN: IconProp = [FAL, 'cloud-rain']
export const FAL_CREDIT_CARD_BLANK: IconProp = [FAL, 'credit-card-blank']
export const FAL_CREDIT_CARD: IconProp = [FAL, 'credit-card']
export const FAL_CALENDAR_EXCLAMATION: IconProp = [FAL, 'calendar-exclamation']
export const FAL_DONATE: IconProp = [FAL, 'donate']
export const FAL_ELLIPSIS_H: IconProp = [FAL, 'ellipsis-h']
export const FAL_FILE_INVOICE_DOLLAR: IconProp = [FAL, 'file-invoice-dollar']
export const FAL_FILE_INVOICE: IconProp = [FAL, 'file-invoice']
export const FAL_GIFT: IconProp = [FAL, 'gift']
export const FAL_GLOBE: IconProp = [FAL, 'globe']
export const FAL_HAND_HOLDING_USD: IconProp = [FAL, 'hand-holding-usd']
export const FAL_HANDS_USD: IconProp = [FAL, 'hands-usd']
export const FAL_LIST_ALT: IconProp = [FAL, 'list-alt']
export const FAL_MEDAL: IconProp = [FAL, 'medal']
export const FAL_MONEY_CHECK: IconProp = [FAL, 'money-check-alt']
export const FAL_PERCENTAGE: IconProp = [FAL, 'percentage']
export const FAL_PHONE_LAPTOP: IconProp = [FAL, 'phone-laptop']
export const FAL_PHONE: IconProp = [FAL, 'phone']
export const FAL_PIE_CHART: IconProp = [FAL, 'chart-pie-alt']
export const FAL_RECEIPT: IconProp = [FAL, 'receipt']
export const FAL_STAR_CHRISTMAS: IconProp = [FAL, 'star-christmas']
export const FAL_STORE_ALT_SLASH: IconProp = [FAL, 'store-alt-slash']
export const FAL_USER_COG: IconProp = [FAL, 'user-cog']
export const FAL_WALLET: IconProp = [FAL, 'wallet']

// Regular
export const FAR_ANGLE_DOWN: IconProp = [FAR, 'angle-down']
export const FAR_ANGLE_LEFT: IconProp = [FAR, 'angle-left']
export const FAR_BAR: IconProp = [FAR, 'bars']
export const FAR_CALENDAR_DAY: IconProp = [FAR, 'calendar-day']
export const FAR_CALENDAR_DAYS: IconProp = [FAR, 'calendar-days']
export const FAR_CHART_LINE: IconProp = [FAR, 'chart-line']
export const FAR_CHECK: IconProp = [FAR, 'check']
export const FAR_EXCLAMATION_TRIANGLE: IconProp = [FAR, 'exclamation-triangle']
export const FAR_GEAR: IconProp = [FAR, 'gear']
export const FAR_INFO_CIRCLE: IconProp = [FAR, 'info-circle']
export const FAR_LINK: IconProp = [FAR, 'link']
export const FAR_MESSAGE_PEN: IconProp = [FAR, 'message-pen']
export const FAR_SIGN_OUT_ALT: IconProp = [FAR, 'sign-out-alt']
export const FAR_SPINNER: IconProp = [FAR, 'spinner']
export const FAR_SQUARE_SLIDERS: IconProp = [FAR, 'square-sliders-vertical']
export const FAR_TIMES: IconProp = [FAR, 'times']
export const FAR_TRIANGLE_EXCLAMATION: IconProp = [FAR, 'triangle-exclamation']
export const FAR_UNDO_ALT: IconProp = [FAR, 'undo-alt']
export const FAR_USD_CIRCLE: IconProp = [FAR, 'usd-circle']
export const FAR_USER: IconProp = [FAR, 'user']
export const FAR_XMARK: IconProp = [FAR, 'xmark']
export const FAR_PLUS_CIRCLE: IconProp = [FAR, 'plus-circle']

// Solid
export const FAS_BADGE_PERCENT: IconProp = [FAS, 'badge-percent']
export const FAS_CALENDAR_ALT: IconProp = [FAS, 'calendar-alt']
export const FAS_CHEVRON_DOWN: IconProp = [FAS, 'chevron-down']
export const FAS_CHEVRON_RIGHT: IconProp = [FAS, 'chevron-right']
export const FAS_CHEVRON_UP: IconProp = [FAS, 'chevron-up']
export const FAS_CREDIT_CARD: IconProp = [FAS, 'credit-card']
export const FAS_ELLIPSIS_H: IconProp = [FAS, 'ellipsis-h']
export const FAS_FILE_INVOICE: IconProp = [FAS, 'file-invoice']
export const FAS_GIFT: IconProp = [FAS, 'gift']
export const FAS_LINE_CHART: IconProp = [FAS, 'chart-line']
export const FAS_LIST_ALT: IconProp = [FAS, 'list-alt']
export const FAS_PIE_CHART_ALT: IconProp = [FAS, 'chart-pie-alt']
export const FAS_USER_CIRCLE: IconProp = [FAS, 'user-circle']
export const FAS_USER_COG: IconProp = [FAS, 'user-cog']
