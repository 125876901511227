import { ACCOUNT_OPEN } from 'const/account/conditions'
import { SETPAY } from 'const/account/products'
import { ConditionValues } from 'syf-typings'
import {
  Installment,
  ProductType
} from 'syf-typings/accountSummary/accountSummary'

const getSetpayLoanProcessingStatus = (
  installment: Installment,
  productType: ProductType,
  conditions: ConditionValues[]
): boolean => {
  const isStatusOpen = conditions?.includes(ACCOUNT_OPEN)
  const isSetpayAccount = productType === SETPAY
  const { loanTerm, interestRate, startDate, endDate } = installment || {}
  const isLoanProcessing =
    loanTerm === '0' && !interestRate && !startDate && !endDate
  return isSetpayAccount && isLoanProcessing && isStatusOpen
}

export default getSetpayLoanProcessingStatus
