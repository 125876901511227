import styled from 'styled-components'
import { Button } from 'syf-component-library/ui/atoms'

export const AccountLinkButton = styled(Button)`
  color: ${({ theme }) => theme.cyanBlue};
  margin: 0;
  padding: 0;
  height: 100%;
`

export const CardImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`
