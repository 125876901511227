import endpoints from 'const/apiEndpoints'
import api from 'services'

const getRewardsResponse = async (accountId: string) => {
  try {
    const response = await api.get(endpoints.ACCOUNT_REWARDS, {
      params: { 'cipher.accountId': accountId, accountIdType: 'guid' }
    })

    return response.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}

export default getRewardsResponse
