import PageContainer from 'pages/subcomponents'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { Body, H5 } from 'syf-component-library/ui/typography'

export const Container = styled(PageContainer)`
  margin: 48px auto;
  color: ${({ theme }) => theme.white};

  @media ${query.greaterThanMedium} {
    width: 482px;
  }
`

export const Title = styled(H5)`
  margin-top: 16px;
  text-align: center;
`

export const Content = styled(Body)`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`
