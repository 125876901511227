import { useRef, useState } from 'react'
import i18n from 'strings/i18n'
import { isAfter, isBefore, isValid, parse } from 'date-fns'
import { FormState, InputName, ManageFormHelpers } from '../typings'

export const initialValue: FormState = {
  accountNumber: '',
  ssn: '',
  dob: ''
}

const validateDate = (dateInput: string) => {
  const regexDate = /^\d{2}\/\d{2}\/\d{4}$/
  const parsedDate = parse(dateInput, 'MM/dd/yyyy', new Date())

  return !regexDate.test(dateInput) || !isValid(parsedDate)
}

const validateAccountNumber = (accountNumber: string): string => {
  if (!accountNumber) {
    return `${i18n({ errors: 'emptyAccountNumber' })}`
  }
  if (accountNumber.length < 8) {
    return `${i18n({ errors: 'wrongAccountNumber' })}`
  }
  return ''
}

const validateSSN = (ssn: string): string => {
  if (!ssn) {
    return `${i18n({ errors: 'emptySSN' })}`
  }
  if (ssn.length < 4) {
    return `${i18n({ errors: 'wrongSSN' })}`
  }
  return ''
}

const validateDateInput = (dob: string): string => {
  if (!dob) {
    return `${i18n({ errors: 'emptyDOB' })}`
  }
  if (validateDate(dob)) {
    return `${i18n({ errors: 'wrongDOB' })}`
  }

  const parsedDate = parse(dob, 'MM/dd/yyyy', new Date())
  const oldestDate = new Date(1900, 0, 1)

  if (isBefore(parsedDate, oldestDate)) {
    return `${i18n({ errors: 'pastDOB' })}`
  }
  if (isAfter(parsedDate, new Date())) {
    return `${i18n({ errors: 'futureDOB' })}`
  }
  return ''
}

const useForm = (): ManageFormHelpers => {
  const [formData, setFormData] = useState<FormState>(initialValue)
  const [formError, setFormError] = useState<FormState>(initialValue)
  const isFormValid = useRef(false)

  const handleInputChange = (name: InputName, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const validateInput = (name?: InputName, value?: string) => {
    const { accountNumber, ssn, dob } = formData

    const accountNumberError = validateAccountNumber(value || accountNumber)
    const ssnError = validateSSN(value || ssn)
    const dobError = validateDateInput(value || dob)

    switch (name) {
      case 'accountNumber':
        setFormError((prevData) => ({
          ...prevData,
          [name]: accountNumberError
        }))
        break
      case 'ssn':
        setFormError((prevData) => ({
          ...prevData,
          [name]: ssnError
        }))
        break
      case 'dob':
        setFormError((prevData) => ({
          ...prevData,
          [name]: dobError
        }))
        break
      default:
        setFormError({
          accountNumber: accountNumberError,
          ssn: ssnError,
          dob: dobError
        })
    }
    isFormValid.current = !accountNumberError && !ssnError && !dobError
  }

  return {
    formData,
    formError,
    setFormData,
    setFormError,
    handleInputChange,
    validateInput,
    isFormValid
  }
}

export default useForm
