import axios from 'axios'
import config from 'const/config'
import dynamicYieldPayload from 'const/dynamicYieldPayload'
import { generateUUID, getLanguageFromStorage } from 'syf-js-utilities'

import { DynamicYieldData } from './typings'

const getDynamicYieldResponse = async (): Promise<DynamicYieldData> => {
  const { type, location, selectorGroups } = dynamicYieldPayload

  const trackingID = generateUUID()

  const locale = `${getLanguageFromStorage()}_US`

  const response = await axios.get<DynamicYieldData>(config.DY_CAMPAIGNS_URL, {
    params: { selectorGroups, type, location, locale },
    headers: {
      'X-SYF-Request-TrackingId': trackingID
    }
  })

  return response.data
}

export default getDynamicYieldResponse
