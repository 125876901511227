declare global {
  interface Window {
    QSI: {
      API: {
        load: () => void
        run: () => void
        unload: () => void
      }
    }
  }
}

const triggerQualtrics = (): void => {
  window.QSI?.API?.unload()
  window.QSI?.API?.load()
  window.QSI?.API?.run()
}

export default triggerQualtrics
