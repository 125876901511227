import { maxModalWidth } from 'const/styles'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import CLSimpleModal from 'syf-component-library/ui/patterns/SimpleModal'

export const SimpleModal = styled(CLSimpleModal)`
  width: 95%;
  text-align: center;

  @media ${query.greaterThanMedium} {
    width: ${maxModalWidth}px;
    max-width: 75%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
