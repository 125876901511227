import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import { Body } from 'syf-component-library/ui/typography'

export const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: 152px 132px ${spacing.xxlarge}px;
  @media ${query.lessThanMedium} {
    padding: ${spacing.xxlarge}px ${spacing.base}px;
  }
`

export const ImageWrapper = styled.div`
  height: 112px;
  width: 168px;
  margin: 0 auto;
  padding: 0 ${spacing.base}px;
  @media ${query.lessThanMedium} {
    height: 96px;
    width: 144px;
    padding: 0 14px;
  }
`

export const BodyContent = styled(Body)`
  text-align: center;
`
