import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateModalStatus, updateSelectedAccount } from 'redux/modals/actions'

import featureFlags from 'const/featureFlags'
import { useAutopayStatus } from 'const/syfApiQueries'
import handleAccountLink from 'helpers/handleAccountLink'
import i18n from 'strings/i18n'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import Small from 'syf-component-library/ui/typography/Small'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import { AutopayButtonWrapper, AutopayStatus } from './subcomponents'

interface AutopayButtonProps {
  account: Account
  clientId: string
}

const AutopayButton = ({
  account,
  clientId
}: AutopayButtonProps): ReactElement => {
  const dispatch = useDispatch()
  const accountId = account.info['cipher.accountId']
  const windowWidth = useWindowSize()
  const { data: autopayStatus } = useAutopayStatus(accountId)
  const autopayEnrollment = autopayStatus?.enrollment
  const [isViewAccountDisabled, setIsViewAccountDisabled] = useState(false)
  const autopayButtonId = `autopay-${accountId}`

  const handleAutoPayClick = () => {
    if (featureFlags.isAutopayEnabled) {
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('MAP', autopayButtonId))
    } else {
      setIsViewAccountDisabled(true)
      handleAccountLink(account, clientId, windowWidth)
    }
  }
  return (
    <AutopayButtonWrapper
      id={autopayButtonId}
      buttonType="text"
      data-test="autopay-text"
      data-type="autopay"
      data-reason="autopay"
      data-object="button"
      data-analytics="clicktrackingevent"
      disabled={isViewAccountDisabled}
      onClick={handleAutoPayClick}
    >
      <Small isInline>{`${i18n({ autopay: 'text' })}`}</Small>&nbsp;
      <AutopayStatus isInline weight="bold" $enrolled={autopayEnrollment}>
        {i18n({ autopay: autopayEnrollment ? 'on' : 'off' })}
      </AutopayStatus>
    </AutopayButtonWrapper>
  )
}

export default AutopayButton
