import { ReactElement } from 'react'

import { useAccountFeatures } from 'const/syfApiQueries'
import getPaymentButtonType from 'helpers/getPaymentButtonType'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import CollectionsPaymentButton from './CollectionsPaymentButton'
import CreditBalanceRefundButton from './CreditBalanceRefundButton'
import MakeAPaymentButton from './MakeAPaymentButton'

interface PaymentButtonProps {
  className?: string
  account: Account
  accountSummary: AccountSummaryGetResponse
  dataType?: string
}

const PaymentButton = ({
  className,
  account,
  accountSummary,
  dataType
}: PaymentButtonProps): ReactElement => {
  const { data: accountFeatures } = useAccountFeatures(
    account?.info?.['cipher.accountId']
  )

  const {
    shouldShowCreditBalanceRefund,
    shouldShowMakeAPayment,
    shouldShowCollectionPayment,
    shouldShowViewPaymentOptions
  } = getPaymentButtonType({
    account,
    accountSummary,
    accessToCreditBalanceRefund: accountFeatures?.accessToCreditBalanceRefund
  })

  if (shouldShowCreditBalanceRefund) {
    return <CreditBalanceRefundButton account={account} dataType={dataType} />
  }

  if (shouldShowMakeAPayment) {
    return (
      <MakeAPaymentButton
        className={className}
        account={account}
        accountSummary={accountSummary}
        dataType={dataType}
      />
    )
  }

  const viewPaymentText = shouldShowViewPaymentOptions
    ? 'paymentOptions'
    : 'paymentPlan'

  if (shouldShowCollectionPayment) {
    return (
      <CollectionsPaymentButton
        account={account}
        className={className}
        buttonText={viewPaymentText}
        dataType={dataType}
      />
    )
  }

  return null
}

export default PaymentButton
