import { ReactElement } from 'react'

import i18n from 'strings/i18n'
import { SpacingSizeTypes } from 'syf-component-library/typings/spacing'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import { Body } from 'syf-component-library/ui/typography'
import ActivateCardOverlayMessage from 'ui/patterns/OverlayMessage/ActivateCard'

import {
  OverlayMessageTitle,
  OverlayMessageType,
  OverlayMessageWrapper,
  SetpayLoanProcessingError,
  overlayMessages
} from './subcomponents'

interface OverlayMessageProps {
  overlayMessageType: OverlayMessageType
  overlayDynamicText?: string
  overlayIconUrl?: string
}

const AccountOverlayMessage = ({
  overlayMessageType,
  overlayDynamicText,

  overlayIconUrl
}: OverlayMessageProps): ReactElement => {
  const {
    overlayMessageIcon,
    overlayMessageTitle,
    overlayMessageDescription,
    overlayMessageHelp
  } = overlayMessages[overlayMessageType]
  const isAuthProhibitedUnregisteredStatus =
    overlayMessageType === 'authProhibitedUnregisteredStatus'
  const isSetpayLoanProcessing =
    overlayMessageType === 'setpayLoanProcessingError'
  const isCardNotActivated =
    overlayMessageType === 'activateCard' && Boolean(overlayDynamicText)

  const handleActivateButtonClick = () => {}

  if (isCardNotActivated) {
    return (
      <ActivateCardOverlayMessage
        handleOnClick={handleActivateButtonClick}
        overlayDynamicText={overlayDynamicText}
        overlayIconUrl={overlayIconUrl}
      />
    )
  }

  if (isSetpayLoanProcessing) {
    return <SetpayLoanProcessingError data-test="overlay-message" />
  }

  return (
    <OverlayMessageWrapper data-test="overlay-message">
      <>
        {overlayMessageIcon}
        <Stack />
        <OverlayMessageTitle data-test="overlay-title" weight="light">
          {i18n({ overlayMessages: overlayMessageTitle })}
        </OverlayMessageTitle>
        <Inset bottom="xlarge" top="medium">
          <Body data-test="overlay-description">
            {i18n({ overlayMessages: overlayMessageDescription })}
          </Body>
        </Inset>
        <Inset
          vertical={
            (isAuthProhibitedUnregisteredStatus
              ? 'xxlarge'
              : 'xsmall') as SpacingSizeTypes
          }
          bottom="large"
        >
          {overlayMessageHelp && (
            <Body data-test="overlay-help">
              {i18n({ overlayMessages: overlayMessageHelp })}
            </Body>
          )}
          <Stack size="xxlarge" />
        </Inset>
        <Stack size="xxlarge" />
      </>
    </OverlayMessageWrapper>
  )
}

export default AccountOverlayMessage
