import apiEndpoints from 'const/apiEndpoints'
import config from 'const/config'
import routes from 'const/routes'
import clearSessionStorage from 'helpers/clearSessionStorage'

import handleRevokeAccessToken from './handleRevokeAccessToken'
import redirectToUrl from './redirectToUrl'

const handleLogout = async (): Promise<void> => {
  await handleRevokeAccessToken()
  clearSessionStorage()
  redirectToUrl(
    `${config.AUTH_DOMAIN}${apiEndpoints.LOGOUT}?post_logout_redirect_uri=${window.location.origin}${config.BASENAME}${routes.LOGOUT}`
  )
}

export default handleLogout
