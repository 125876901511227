import config from 'const/config'
import getQueryParam from 'syf-js-utilities/formats/getQueryParam'

import isAllowedHost from './isAllowedHost'

const getPSCCRedirectUrlFromQueryParams = (): string => {
  const psccEncodedUrl = getQueryParam('returnTo')
  if (!psccEncodedUrl) return config.PSCC_URL

  const psccRedirectUrl = psccEncodedUrl
    ? atob(psccEncodedUrl)
    : sessionStorage.getItem('psccRedirectUrl')
  const isSyfHost = isAllowedHost(psccRedirectUrl)
  if (!isSyfHost) {
    return config.PSCC_URL
  }

  sessionStorage.setItem('psccRedirectUrl', psccRedirectUrl)
  return psccRedirectUrl
}

export default getPSCCRedirectUrlFromQueryParams
