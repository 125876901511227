import { SETPAY } from 'const/account/products'
import { SYF_NUMBER, helpNumbers } from 'const/helpNumbers'
import { ProductType } from 'syf-typings/accountSummary'

const getHelpNumber = (client: string, product: ProductType): string => {
  const { amazon, lowes, sams } = helpNumbers
  if (product === SETPAY) {
    switch (client) {
      case 'amazon':
        return amazon
      case 'lowes':
        return lowes
      case 'sams':
        return sams
      default:
        return SYF_NUMBER
    }
  }
  return SYF_NUMBER
}

export default getHelpNumber
