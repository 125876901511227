import {
  PSCC,
  PSCC_ACCOUNT_URL_PATH,
  RC,
  RC_ACCOUNT_URL_PATH,
  SETPAY
} from 'const/account/products'
import config from 'const/config'
import { WindowSize } from 'syf-component-library/ui/customHooks/useWindowWidth'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import getUrlExtension from './getUrlExtension'
import isAllowedHost from './isAllowedHost'

const getViewAccountUrl = (
  account: Account,
  clientId: string,
  windowWidth: WindowSize
): string => {
  const PSCC_URL = isAllowedHost(sessionStorage.getItem('psccRedirectUrl'))
    ? sessionStorage.getItem('psccRedirectUrl')
    : config.PSCC_URL
  const langId = localStorage.getItem('language')
  const guid = account.info['cipher.accountId']
  const { product } = account
  const { type: productType, lineOfBusiness } = product
  const isMobileTablet = windowWidth.isLessThanDesktop

  if (productType === SETPAY) {
    const pathname =
      config.TARGET_ORIGIN(clientId) +
      getUrlExtension('account/setpay', clientId)
    const queryParams = `?account=${guid}&lang=${langId}`

    return pathname + queryParams
  }

  if (lineOfBusiness === RC) {
    const service =
      !isMobileTablet || clientId === 'gemoney' ? 'eService' : 'mService'
    return `${config.TARGET_ORIGIN(clientId)}/${service}${RC_ACCOUNT_URL_PATH}`
  }

  if (lineOfBusiness === PSCC) {
    return `${PSCC_URL}/${PSCC_ACCOUNT_URL_PATH}`
  }

  return null
}

export default getViewAccountUrl
