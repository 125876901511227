import { ReactElement } from 'react'

import DisabledCookies from 'pages/DisabledCookies'

const CookieEnabled = ({
  children
}: {
  children?: ReactElement
}): ReactElement => {
  if (navigator.cookieEnabled) {
    return children
  }
  return <DisabledCookies />
}

export default CookieEnabled
