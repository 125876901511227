import { ReactElement } from 'react'

import { defaultCardArt } from 'const/assets'
import getAccountCardDetails from 'helpers/getAccountCardDetails'
import handleImageError from 'helpers/handleImageError'
import AutopayButton from 'pages/AccountsDashboard/Card/actions/AutopayButton'
import {
  AutopayWrapper,
  CardBorderColumn,
  CardDetailsGrid,
  FlexWrapper,
  Image,
  ImageWrapper
} from 'pages/AccountsDashboard/Card/layouts/subcomponents'
import Grid from 'syf-component-library/ui/patterns/Grid'
import { Inline, Inset, Stack } from 'syf-component-library/ui/spacing'
import { Account, AccountSummaryGetResponse } from 'syf-typings'
import AccountDetail from 'ui/atoms/AccountDetail'

export interface CardDetailsProps {
  accountSummary: AccountSummaryGetResponse
  account?: Account
  clientId?: string
  shouldShowAutopayButton?: boolean
}

const CardDetails = ({
  accountSummary,
  account,
  clientId,
  shouldShowAutopayButton
}: CardDetailsProps): ReactElement => {
  const { card } = accountSummary
  const cardDetails = getAccountCardDetails(accountSummary)

  return (
    <CardDetailsGrid item container data-test="card-details">
      <CardBorderColumn item xs={6} $borderSize={103}>
        <Inset left="small">
          <ImageWrapper>
            <Image
              src={card.cardArtURL || defaultCardArt}
              alt=""
              onError={(e) => handleImageError(e, defaultCardArt)}
            />
          </ImageWrapper>
        </Inset>
      </CardBorderColumn>
      <Grid item xs={6}>
        <FlexWrapper>
          <Inline size="large" respondToBreakpoints={['small', 'medium']} />
          <AccountDetail detail={cardDetails[0]} />
        </FlexWrapper>
        <Stack size="small" />
        <FlexWrapper>
          <Inline size="large" respondToBreakpoints={['small', 'medium']} />
          <AccountDetail detail={cardDetails[1]} />
        </FlexWrapper>
        <Stack size="small" />
      </Grid>
      <Grid item xs={6}>
        <AccountDetail detail={cardDetails[2]} />
      </Grid>
      <Grid item xs={6}>
        <FlexWrapper>
          <Inline size="large" respondToBreakpoints={['small', 'medium']} />
          <AccountDetail detail={cardDetails[3]} />
        </FlexWrapper>
      </Grid>

      <AutopayWrapper item xs={12}>
        <Inset bottom="large">
          {shouldShowAutopayButton && (
            <AutopayButton
              data-test="autopay-button"
              account={account}
              clientId={clientId}
            />
          )}
        </Inset>
      </AutopayWrapper>
    </CardDetailsGrid>
  )
}

export default CardDetails
