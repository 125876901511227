import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import triggerQualtrics from 'helpers/triggerQualtrics'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'

import { ModalAction, Modals } from './typings'

const handleAnalyticsOnClose = () => {
  analyticsConfig.defaults.PageKind = 'multiaccount'
  triggerAnalyticsEvent({
    PageFunction: 'dashboard',
    PageKind: 'multiaccount'
  })
}

export const updateModalStatus = (
  modalId: Modals,
  modalTriggerId?: string,
  resetAnalytics?: boolean
): ModalAction => {
  if (!modalId && resetAnalytics) {
    handleAnalyticsOnClose()
  }
  triggerQualtrics()

  if (modalTriggerId) {
    return {
      type: 'UPDATE_MODAL_STATUS',
      payload: {
        modalId,
        modalTriggerId
      }
    }
  }
  return {
    type: 'UPDATE_MODAL_STATUS',
    payload: {
      modalId
    }
  }
}

export const updateSelectedAccount = (payload: string): ModalAction => {
  sessionStorage.setItem('selectedAccountId', payload)
  return {
    type: 'UPDATE_SELECTED_ACCOUNT',
    payload
  }
}
