import formPost from 'helpers/formPost'

const handleRewardsPlatform = (
  samlToken: string,
  rewardsPlatformDestination: string
) => {
  if (!samlToken) return
  const formData = {
    SAMLResponse: samlToken
  }
  formPost(rewardsPlatformDestination, formData, '_blank')
}

export default handleRewardsPlatform
