const formatNumToCurrency = (value: string): string => {
  const absoluteValue = value ? value.replace(/-/g, '') : ''
  const formattedCurrency = Number(absoluteValue).toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })

  return value && value.includes('-')
    ? `-$${formattedCurrency}`
    : `$${formattedCurrency}`
}

export default formatNumToCurrency
