import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import config from 'const/config'
import parseError from 'helpers/parseError'
import generateUUID from 'syf-js-utilities/helpers/generateUUID'
import ServerError from 'typings/ServerError'

const api = axios.create({
  baseURL: config.API_HOST,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use((reqConfig: AxiosRequestConfig) => {
  const updateReqConfig = reqConfig
  updateReqConfig.headers['X-SYF-Request-TrackingId'] = generateUUID()
  updateReqConfig.headers['X-SYF-Request-CorrelationId'] =
    sessionStorage.getItem('correlationId')
  const accessToken = sessionStorage.getItem('accessToken')
  updateReqConfig.headers.Authorization = `Bearer ${accessToken}`
  return updateReqConfig
})

api.interceptors.response.use(
  (res: AxiosResponse) => res,
  (err: AxiosError) => {
    const error: ServerError = parseError(err)
    // eslint-disable-next-line
    throw error
  }
)

export default api
