import { FC, ReactElement } from 'react'

import { mfeConfig } from 'const/mfeConfig'
import { handleMFEAuthenticationRedirect } from 'helpers/authenticationRedirect'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import ProfileMFE from 'profile-mfe'
import { MFEConfig, SupportedLanguage } from 'syf-js-utilities'

interface ProfileProps {
  currentLanguage: SupportedLanguage
}

const Profile: FC<ProfileProps> = ({ currentLanguage }): ReactElement => {
  const profileConfig: MFEConfig = {
    ...mfeConfig,
    lang: currentLanguage
  }

  useNavigateOnRedirect()

  return (
    <ProfileMFE
      config={profileConfig}
      authenticationRedirect={handleMFEAuthenticationRedirect}
    />
  )
}

export default Profile
