import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { updateModalStatus, updateSelectedAccount } from 'redux/modals/actions'

import { makeAPaymentModalContainerId } from 'const/modalContainerId'
import handoverToPayAsGuest from 'helpers/handoverToPayAsGuest'
import i18n from 'strings/i18n'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import { Button } from './subcomponents'

export interface MakeAPaymentButtonProps {
  className?: string
  accountSummary: AccountSummaryGetResponse
  account: Account
  dataType?: string
}

const MakeAPaymentButton = ({
  className,
  accountSummary,
  account,
  dataType
}: MakeAPaymentButtonProps): ReactElement => {
  const {
    product,
    registration: { registered: isRegistered },
    info: { 'cipher.accountId': accountId }
  } = account
  const {
    product: { clientId }
  } = accountSummary
  const { type: productType } = product
  const dispatch = useDispatch()
  const paymentButtonId = `map-${accountId}`

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    analyticsConfig.defaults.PageKind = 'payments'

    if (isRegistered) {
      dispatch(updateSelectedAccount(accountId))
      dispatch(updateModalStatus('MAP', paymentButtonId))
    } else {
      handoverToPayAsGuest(product, clientId)
    }
  }

  return (
    <div id={makeAPaymentModalContainerId(accountId)}>
      <Button
        id={paymentButtonId}
        buttonType="secondary"
        className={className}
        onClick={handleOnClick}
        data-test="make-a-payment-button"
        data-type={dataType || 'payment'}
        data-reason="make a payment"
        data-object="button"
        data-analytics="clicktrackingevent"
      >
        {i18n({ button: 'makeAPayment' })}
      </Button>
    </div>
  )
}

export default MakeAPaymentButton
