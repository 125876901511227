import { ReactElement, RefObject, forwardRef } from 'react'

import { ACCOUNT_UPGRADING } from 'const/account/conditions'
import { synchronyLogo } from 'const/assets'
import featureFlags from 'const/featureFlags'
import getAutopayStatus from 'helpers/getAutopayStatus'
import getSetpayLoanProcessingStatus from 'helpers/getSetpayLoanProcessingStatus'
import hasAuthProhibitedUnregisteredNotification from 'helpers/hasAuthProhibitedUnregisteredNotification'
import { PaymentStatus } from 'notifications/typings/custom'
import { NotificationFront } from 'notifications/ui'
import PaymentButton from 'pages/AccountsDashboard/Card/actions/PaymentButton'
import RegisterAccount from 'pages/AccountsDashboard/Card/actions/RegisterAccount'
import { CardFooterGrid } from 'pages/AccountsDashboard/Card/layouts/subcomponents'
import Grid from 'syf-component-library/ui/patterns/Grid'
import { Stack } from 'syf-component-library/ui/spacing'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'
import AccountOverlayMessage from 'ui/atoms/AccountOverlayMessage'
import { OverlayMessageType } from 'ui/atoms/AccountOverlayMessage/subcomponents'

import CardDetails from './CardDetails'
import { AccountCardBody, RegisterAccountWrapper } from './subcomponents'

interface CardBodyProps {
  isError: boolean
  notificationList: PaymentStatus[]
  handleOnClick: () => void
  account: Account
  accountSummary: AccountSummaryGetResponse
}

const CardBody = (
  {
    isError,
    notificationList,
    handleOnClick,
    account,
    accountSummary
  }: CardBodyProps,
  ref: RefObject<HTMLDivElement>
): ReactElement => {
  const isRegistered = account?.registration?.registered
  const {
    billing,
    conditions,
    installment,
    product: {
      clientId = null,
      displayName = 'Your Synchrony Account',
      portfolioLogo = synchronyLogo
    } = {}
  } = accountSummary || {}
  const hasAuthProhibitedUnregisteredNotify =
    hasAuthProhibitedUnregisteredNotification(conditions, isRegistered)
  const productType = account?.product?.type
  const isSetpayLoanProcessing = getSetpayLoanProcessingStatus(
    installment,
    productType,
    conditions
  )
  const isAccountUpgrading =
    conditions?.includes(ACCOUNT_UPGRADING) &&
    featureFlags.isAccountUpgradingMessageEnabled

  // Need to add the logic for not activated Account.
  const isAccountActivated = true
  const showCardActivationOverlay =
    featureFlags.isCardActivationEnabled && !isAccountActivated

  let overlayMessageType: OverlayMessageType

  if (hasAuthProhibitedUnregisteredNotify) {
    overlayMessageType = 'authProhibitedUnregisteredStatus'
  } else if (isSetpayLoanProcessing) {
    overlayMessageType = 'setpayLoanProcessingError'
  } else if (isAccountUpgrading) {
    overlayMessageType = 'accountUpgrading'
  } else if (isError) {
    overlayMessageType = 'accountUnavailableError'
  } else if (showCardActivationOverlay) {
    overlayMessageType = 'activateCard'
  }

  const isAutopayButtonVisible = getAutopayStatus({
    billing,
    conditions
  })

  const shouldShowAutopayButton = isAutopayButtonVisible && isRegistered

  return overlayMessageType ? (
    <AccountOverlayMessage
      data-test="account-error-message"
      overlayMessageType={overlayMessageType}
      overlayDynamicText={displayName}
      overlayIconUrl={portfolioLogo}
    />
  ) : (
    <>
      <NotificationFront
        data-test="notifications-front"
        notificationList={notificationList}
        handleOnClick={handleOnClick}
        ref={ref}
      />
      <AccountCardBody vertical="base" data-test="account-body">
        <Stack size="small" />
        <CardDetails
          data-test="cardLayout-details"
          accountSummary={accountSummary}
          account={account}
          clientId={clientId}
          shouldShowAutopayButton={shouldShowAutopayButton}
        />
        <Grid item xs={12}>
          <PaymentButton account={account} accountSummary={accountSummary} />
        </Grid>
      </AccountCardBody>
      {isRegistered ? (
        <CardFooterGrid
          accountSummary={accountSummary}
          account={account}
          data-test="account-body-footer"
        />
      ) : (
        <RegisterAccountWrapper vertical="base" top="medium" bottom="large">
          <RegisterAccount accountSummary={accountSummary} />
        </RegisterAccountWrapper>
      )}
    </>
  )
}

export default forwardRef(CardBody)
