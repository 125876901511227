import strings from 'strings/lang/en'
import { ScreenType } from '../typings'

export const ADD_ACCOUNT = 'add account'
export const SNAPSHOT = 'snapshot'

const {
  successTitle,
  successDescription,
  maxAttemptsTitle,
  maxAttemptsDescription,
  alreadyAddedTitle,
  alreadyAddedDescription,
  unableToAddTitle,
  callCustomerService,
  syfIDErrorTitle,
  notSupportedTitle,
  notSupportedDescription,
  generalErrorTitle,
  generalErrorDescription
} = strings.addAccountModal

export interface ButtonAnalytics {
  dataTypePrimary: string
  dataReasonPrimary: string
  dataTypeSecondary?: string
  dataReasonSecondary?: string
}

export interface PageViewAnalytics {
  Message: string
  MessageType: string
  pageName: string
}

export const getAnalyticsData = (
  screenType: ScreenType
): { buttonDetails: ButtonAnalytics; pageView: PageViewAnalytics } => {
  switch (screenType) {
    case 'Default':
      return {
        buttonDetails: {
          dataTypePrimary: ADD_ACCOUNT,
          dataReasonPrimary: 'add card',
          dataTypeSecondary: ADD_ACCOUNT,
          dataReasonSecondary: 'cancel'
        },
        pageView: {
          Message: '',
          MessageType: '',
          pageName: 'view'
        }
      }

    case 'IncorrectInfo':
      return {
        buttonDetails: {
          dataTypePrimary: 'add account not found',
          dataReasonPrimary: ADD_ACCOUNT,
          dataTypeSecondary: 'add account not found',
          dataReasonSecondary: 'cancel'
        },
        pageView: {
          Message: 'something went wrong',
          MessageType: 'trouble finding account',
          pageName: 'account not found'
        }
      }

    case 'Success':
      return {
        buttonDetails: {
          dataTypePrimary: 'account added successfully',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: successDescription,
          MessageType: successTitle,
          pageName: 'account added successfully'
        }
      }

    case 'MaxAttempt':
      return {
        buttonDetails: {
          dataTypePrimary: 'max attempts reached',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: maxAttemptsDescription,
          MessageType: maxAttemptsTitle,
          pageName: 'max attempts reached'
        }
      }

    case 'AlreadyAdded':
      return {
        buttonDetails: {
          dataTypePrimary: 'account already added',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: alreadyAddedDescription,
          MessageType: alreadyAddedTitle,
          pageName: 'account already added'
        }
      }

    case 'UnableToAdd':
      return {
        buttonDetails: {
          dataTypePrimary: 'unable to add account now',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: callCustomerService,
          MessageType: unableToAddTitle,
          pageName: 'unable to add account now'
        }
      }

    case 'SomethingWrongForThisAccount':
      return {
        buttonDetails: {
          dataTypePrimary: 'unable to add account',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: callCustomerService,
          MessageType: syfIDErrorTitle,
          pageName: 'unable to add account'
        }
      }

    case 'NotSupported':
      return {
        buttonDetails: {
          dataTypePrimary: 'account not supported',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: notSupportedDescription,
          MessageType: notSupportedTitle,
          pageName: 'account not supported'
        }
      }

    case 'SomethingWrong':
      return {
        buttonDetails: {
          dataTypePrimary: 'something went wrong',
          dataReasonPrimary: 'close'
        },
        pageView: {
          Message: generalErrorDescription,
          MessageType: generalErrorTitle,
          pageName: generalErrorTitle
        }
      }
    default:
      return { buttonDetails: {}, pageView: {} } as never
  }
}
