import { CARECREDIT, PAYSOL } from 'const/account/clients'
import config from 'const/config'

import getPSCCRedirectUrlFromQueryParams from './getPSCCRedirectUrlFromQueryParams'
import getUrlExtension from './getUrlExtension'

const getLoginHintUrl = (client: string): string => {
  if ([PAYSOL, CARECREDIT].includes(client)) {
    return `${getPSCCRedirectUrlFromQueryParams()}/login/?client=${client}`
  }

  const extension = getUrlExtension('accounts/login', client)
  return `${config.TARGET_ORIGIN(client)}${extension}?client=${client}`
}

export default getLoginHintUrl
