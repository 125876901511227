import { ReactElement } from 'react'

import i18n from 'strings/i18n'

import { DetailValueWrapper, DetailWrapper } from './subcomponents'
import { AccountDetailProps } from './typings'
import AccountDetailSubtitle from '../AccountDetailSubtitle'

const AccountDetail = ({
  children,
  className,
  detail
}: AccountDetailProps): ReactElement => {
  const { value, type } = detail

  const detailValue =
    value === 'unavailable' ? i18n({ labels: 'unavailable' }) : value

  return (
    <DetailWrapper className={className}>
      <DetailValueWrapper data-test="detail-value">
        {detailValue}
      </DetailValueWrapper>
      <AccountDetailSubtitle type={type} />
      {children}
    </DetailWrapper>
  )
}
export default AccountDetail
