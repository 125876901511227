import {
  ACCOUNT_CLOSED_WITH_BALANCE,
  ACCOUNT_DELINQUENT,
  ACCOUNT_OPEN
} from 'const/account/conditions'
import { ConditionValues } from 'syf-typings'
import { BillingAccountSummary } from 'syf-typings/accountSummary/accountSummary'

interface AutopayStatusProps {
  billing: BillingAccountSummary
  conditions: ConditionValues[]
}

const getAutopayStatus = ({
  billing,
  conditions
}: AutopayStatusProps): boolean => {
  const { customerAssistancePlan, settlementEnrolled } = billing || {}
  const isDelinquent = conditions?.includes(ACCOUNT_DELINQUENT)
  const isStatusOpen = conditions?.includes(ACCOUNT_OPEN)
  const isStatusClosedWithBalance = conditions?.includes(
    ACCOUNT_CLOSED_WITH_BALANCE
  )
  const isEnrolled = customerAssistancePlan || settlementEnrolled

  return !(
    isDelinquent ||
    ((isStatusOpen || isStatusClosedWithBalance) && isEnrolled)
  )
}

export default getAutopayStatus
