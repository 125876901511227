/* eslint-disable */
import config from 'const/config'
import { WindowWithSurfly } from 'syf-component-library/ui/patterns/Footer/typings'

const initializeCobrowse = (isSurflyScriptTagEnabled: boolean) => {
  if (isSurflyScriptTagEnabled) {
    // Source: https://docs.surfly.com/javascript-api/quickstart/#example
    ;(function (s, u, r, f, l, y) {
      s[f] = s[f] || {
        init: function () {
          s[f].q = arguments
        }
      }
      l = u?.createElement(r)
      y = u?.getElementsByTagName(r)[0]
      l.async = 1
      l.src = config.SURFLY_URL
      y?.parentNode?.insertBefore(l, y)
    })(window, document, 'script', 'Surfly')

    const settings = {
      widget_key: config.SURFLY_WIDGET_KEY
    }

    const surfly = (window as WindowWithSurfly)?.Surfly
    surfly.init(settings, function (initResult) { 
      if (!initResult?.success) {
        console.log('Your browser lacks features required by Surfly') 
      } 
    })

  } else {
    // Glance implementation
    const element = document?.createElement('script')
    element.type = 'text/javascript'
    element.src = `https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=19909&site=${config.COBROWSE}`
    element.id = 'glance-cobrowse'
    element.dataset.site = config.COBROWSE
    element.dataset.groupid = '19909'
    element.async = true
    document?.body?.appendChild(element)
  }
}

export default initializeCobrowse
