import { ReactElement, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAL_CLOCK } from 'const/icons'
import handleLogout from 'helpers/handleLogout'
import i18n from 'strings/i18n'
import InactivityTimer from 'syf-component-library/ui/templates/InactivityTimer'

const InactivityModal = (): ReactElement => {
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const headerRef = useRef()

  const logoutHandler = () => {
    setIsLoggedIn(false)
    handleLogout()
  }

  const modalTexts = {
    modalTitle: `${i18n({ inactivityModal: 'modalTitle' })}`,
    promptTitle: `${i18n({ inactivityModal: 'promptTitle' })}`,
    countdownWarning: `${i18n({ inactivityModal: 'countdownWarning' })}`,
    continueButton: `${i18n({ inactivityModal: 'continueButton' })}`,
    signOffButton: `${i18n({ inactivityModal: 'logOffButton' })}`,
    seconds: `${i18n({ inactivityModal: 'seconds' })}`
  }

  return (
    <InactivityTimer
      headerRef={headerRef}
      isLoggedIn={isLoggedIn}
      text={modalTexts}
      messageType="session timeout"
      logoutHandler={logoutHandler}
      icon={<FontAwesomeIcon icon={FAL_CLOCK} size="4x" />}
    />
  )
}

export default InactivityModal
