import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { updateModalStatus, updateSelectedAccount } from 'redux/modals/actions'

import AccountCardMFE from 'account-card-mfe'
import {
  AccountCardHandlers,
  LayoutVariant
} from 'account-card-mfe/dist/mfe/AccountCardMFE/AccountCardMFE.types'
import { mfeConfig } from 'const/mfeConfig'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { ProductType } from 'syf-typings/accountSummary'

interface AccountCardProps {
  accountId: string
  clientId: string
  productType: ProductType
}

const AccountCard = ({
  accountId,
  clientId,
  productType
}: AccountCardProps): ReactElement => {
  const dispatch = useDispatch()
  const windowWidth = useWindowSize()

  const variant: LayoutVariant = windowWidth.isLessThanMobile
    ? 'mobile'
    : 'vertical'

  const handleActivityButtonClick = () => {
    const activityButtonId = `activity-${accountId}`

    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    dispatch(updateModalStatus('Activity', activityButtonId))
    dispatch(updateSelectedAccount(accountId))
  }

  const handleDetailsButtonClick = () => {
    const detailsButtonId = `details-${accountId}`

    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    dispatch(updateModalStatus('Details', detailsButtonId))
    dispatch(updateSelectedAccount(accountId))
  }

  const handleMakePaymentButtonClick = () => {
    const paymentButtonId = `map-${accountId}`

    analyticsConfig.defaults.Product = productType
    analyticsConfig.defaults.ClientName = clientId
    analyticsConfig.defaults.PageKind = 'payments'

    dispatch(updateSelectedAccount(accountId))
    dispatch(updateModalStatus('MAP', paymentButtonId))
  }

  const eventHandlersAccountCardMFE: AccountCardHandlers = {
    handleActivityClick: handleActivityButtonClick,
    handleDetailsClick: handleDetailsButtonClick,
    handleMAPClick: handleMakePaymentButtonClick
  }

  return (
    <AccountCardMFE
      isQueryClientPresent
      mfeConfig={mfeConfig}
      accountId={accountId}
      variant={variant}
      eventHandlers={eventHandlersAccountCardMFE}
    />
  )
}

export default AccountCard
