import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  maxDynamicYieldCardWidth,
  maxDynamicYieldWrapperWidth,
  minDynamicYieldCardHeight
} from 'const/styles'
import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import CardRow from 'syf-component-library/ui/atoms/Card/CardRow'
import Link from 'syf-component-library/ui/atoms/Link/Link'
import { H5, Small } from 'syf-component-library/ui/typography'

export const UpperRowText = styled(CardRow)`
  justify-content: left;
  width: 100%;
`

export const LowerRowText = styled(CardRow)`
  justify-content: right;
  width: 100%;
  position: absolute;
  right: ${spacing.large}px;
  bottom: ${spacing.medium}px;
`

export const Image = styled.img`
  width: 58px;
  height: 58px;
  object-position: center;
  object-fit: contain;
  margin-right: ${spacing.base}px;

  @media ${query.greaterThanSmall} {
    margin-right: ${spacing.large}px;
  }
`

export const CampaignTitle = styled(H5)`
  color: ${({ theme }) => theme.lightGrey};
`

export const Subtitle = styled(Small)`
  color: ${({ theme }) => theme.lightGrey};
`

export const YieldCard = styled(Card)`
  position: relative;
  height: ${minDynamicYieldCardHeight}px;
  width: 100%;
  max-width: ${maxDynamicYieldCardWidth}px;
  background-color: ${({ theme }) => theme.navyBlue};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  padding: ${spacing.base}px;
  @media ${query.greaterThanSmall} {
    height: 150px;
  }
`

export const CampaignLink = styled(Link)`
  color: ${({ theme }) => theme.white};
  align-items: right;
  &:visited {
    color: ${({ theme }) => theme.white};
  }
`

export const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: ${spacing.small}px;
`

export const DynamicYieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-bottom: ${spacing.xlarge}px;
  gap: ${spacing.medium}px;
  padding: 0px ${spacing.xsmall}px;
  max-width: ${maxDynamicYieldWrapperWidth}px;
  @media ${query.greaterThanSmall} {
    gap: ${spacing.base}px;
    align-items: center;
  }

  @media ${query.greaterThanHuge} {
    flex-direction: row;
    gap: ${spacing.large}px;
    align-items: stretch;
  }
`
