import {
  DetailTypeOptions,
  TooltipOptions
} from 'ui/atoms/AccountDetail/typings'

const getToolTipConfig = (type: DetailTypeOptions): TooltipOptions | null => {
  let tooltip: TooltipOptions | null = null

  switch (type) {
    case 'currentBalance':
      tooltip = {
        tooltipType: type,
        tooltipAlt: 'currentBalanceAlt',
        tooltipContent: 'currentBalanceTooltip'
      }
      break
    case 'totalLoanBalance':
      tooltip = {
        tooltipType: type,
        tooltipAlt: 'totalLoanBalanceTooltip',
        tooltipContent: 'totalLoanBalanceTooltip'
      }
      break
    case 'nextAmountDue':
      tooltip = {
        tooltipType: type,
        tooltipAlt: 'nextAmountDueTooltip',
        tooltipContent: 'nextAmountDueTooltip'
      }
      break
    default:
  }

  return tooltip
}

export default getToolTipConfig
