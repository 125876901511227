import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import { FC, ReactElement } from 'react'
import VantageMFE from 'vantage-mfe'
import { mfeConfig } from 'const/mfeConfig'
import { useNavigate } from 'react-router-dom'
import routes from 'const/routes'
import { getLanguageFromStorage } from 'helpers'

const VantageScore: FC = (): ReactElement => {
  const navigate = useNavigate()

  useNavigateOnRedirect()

  const navigateToDashboard = () => navigate(routes.HOME)

  return (
    <VantageMFE
      config={{ ...mfeConfig, lang: getLanguageFromStorage() }}
      navigateToDashboard={navigateToDashboard}
    />
  )
}

export default VantageScore
