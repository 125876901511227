import { ProductType } from 'syf-typings/accountSummary'

const PRODUCT_TYPE: Record<ProductType, ProductType> = {
  BUSINESS_RETAIL_CARD: 'BUSINESS_RETAIL_CARD',
  DUAL_CARD: 'DUAL_CARD',
  LIMITED_USE_DUAL_CARD: 'LIMITED_USE_DUAL_CARD',
  PRIVATE_LABEL: 'PRIVATE_LABEL',
  INSTALLMENT_LOAN: 'INSTALLMENT_LOAN',
  INSTALLMENT_LOAN_PAY_IN_FOUR: 'INSTALLMENT_LOAN_PAY_IN_FOUR',
  INSTALLMENT_LOAN_MONTHLY: 'INSTALLMENT_LOAN_MONTHLY',
  INSTALLMENT_LOAN_SECURED: 'INSTALLMENT_LOAN_SECURED'
}

export default PRODUCT_TYPE
