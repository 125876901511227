/* eslint-disable react/no-deprecated */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from 'App'
import config from 'const/config'
import routes from 'const/routes'

const BASENAME = `${config.BASENAME}${routes.HOME}`

if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react').then(({ default: axe }) => {
    axe(React, ReactDOM, 1000)
  })
}

if (
  !window.location.pathname.includes(BASENAME) &&
  process.env.NODE_ENV !== 'production'
) {
  window.history.replaceState('', '', BASENAME)
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={BASENAME}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('vista-root')
)
