import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import CLTooltip from 'syf-component-library/ui/atoms/Tooltip/Tooltip'
import { Small } from 'syf-component-library/ui/typography'

export const Tooltip = styled(CLTooltip)`
  width: auto;
  white-space: normal;
  z-index: 1;
  flex-shrink: 0;
`

export const TooltipWrapper = styled.div`
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-end;
`

export const InfoCircleIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.cyanBlue};
`

export const DetailLabel = styled(Small)`
  color: ${({ theme }) => theme.cyanBlue};
  white-space: pre-line;
  text-align: 'left';
  margin: auto 0;
`
