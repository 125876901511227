import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import H5 from 'syf-component-library/ui/typography/H5'
import Body from 'syf-component-library/ui/typography/Body'
import query from 'syf-component-library/const/mediaQueries'
import { colors } from 'theme'
import { fontWeights, spacing } from 'syf-component-library/defaultTheme'

export const NotificationWrapper = styled.div`
  width: 350px;
  color: ${colors.white};

  @media (${query.lessThanMedium}) {
    width: 350px;
    padding-bottom: ${spacing.base}px;
  }

  @media (${query.lessThanSmall}) {
    width: 100%;
    padding-bottom: ${spacing.base}px;
  }
`

export const NotificationHeader = styled(H5)`
  width: 193px;
  height: 28px;
  text-align: left;
  margin-bottom: 18px;
`
export const NotificationCard = styled.div`
  background: ${colors.cyanBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid ${colors.pewterBlue};
  border-radius: ${spacing.small}px;

  @media (${query.lessThanSmall}) {
    align-content: flex-end;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export const NotificationCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing.base}px;
`

export const NotificationCardBody = styled.div`
  font-weight: ${fontWeights.regular};
  line-height: ${spacing.large}px;
`

export const IssueTitle = styled.div`
  margin-left: ${spacing.base}px;
  padding: 0;
`

export const AccountList = styled.ul`
  margin-left: ${spacing.base}px;
  margin-bottom: ${spacing.base}px;
  padding: 0;
`

export const AccountName = styled.li`
  margin-left: ${spacing.large}px;
`

export const RoundCircle = styled.div`
  width: ${spacing.xxlarge}px;
  height: ${spacing.xxlarge}px;
  background: ${colors.yellow};
  border-radius: 50%;
  text-align: center;
  margin-top: ${spacing.xsmall}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NotificationCount = styled(Body)`
  color: ${colors.cyanBlue};
`
export const AttentionTitle = styled(Body)`
  width: 206px;
  height: 48px;
  margin-left: ${spacing.base}px;
`
export const CollapseButton = styled.button`
  background: none;
  border: 0;
`

export const ReviewProfileLink = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  border: 0;
  background: ${colors.darkSlateGray};
  color: ${colors.yellow};
  justify-content: flex-end;
  border-bottom-left-radius: ${spacing.small}px;
  border-bottom-right-radius: ${spacing.small}px;

  & :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const ReviewProfileText = styled.span`
  margin-top: ${spacing.base}px;
`

export const CollapseIcon = styled(FontAwesomeIcon)`
  color: ${colors.white};
  margin: 15px;
  cursor: pointer;

  @media (${query.lessThanMedium}) {
    position: relative;
    left: 20px;
  }
`

export const RightAngleIcon = styled(FontAwesomeIcon)`
  color: ${colors.yellow};
  margin: 20px ${spacing.base}px 0 0;
  padding-left: 5px;
`
