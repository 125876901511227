export const CARECREDIT = 'carecredit'
export const PAYSOL = 'paysol'
export const UNIVERSAL = 'universal'

export interface IDomains {
  [key: string]: string
}

// Production Client URLS
export const clientUrls: IDomains = {
  aeoutfitters: 'https://aeoutfitters.syf.com',
  amazon: 'https://amazon.syf.com',
  athome: 'https://athome.syf.com',
  belk: 'https://belk.syf.com',
  bp: 'https://bp.syf.com',
  cathay: 'https://cathay.syf.com',
  cb: 'https://crateandbarrel.syf.com',
  cheapoair: 'https://cheapoair.syf.com',
  dicks: 'https://dsg.syf.com',
  ebates: 'https://rakutencard.syf.com',
  ebaycb: 'https://ebaymastercard.syf.com',
  gemoney: 'https://www.synchronymastercard.com',
  google: 'https://google.syf.com',
  hft: 'https://harborfreight.syf.com',
  hsn: 'https://hsn.syf.com',
  infiniti: 'https://infiniti.syf.com',
  jcpenney: 'https://jcpenney.syf.com',
  jcrew: 'https://jcrew.syf.com',
  lowes: 'https://lowes.syf.com',
  lowescan: 'https://lowes.syf.ca',
  marvel: 'https://marvel.syf.com',
  mwh: 'https://mwh.syf.com',
  nissan: 'https://nissan.syf.com',
  norair: 'https://norwegianair.syf.com',
  onetravel: 'https://onetravel.syf.com',
  paysol: 'https://synchrony.com',
  qvc: 'https://qvc.syf.com',
  sams: 'https://samsclub.syf.com',
  shopnbc: 'https://shophq.syf.com',
  stash: 'https://stash.syf.com',
  steinmart: 'https://steinmart.syf.com',
  tjx: 'https://tjx.syf.com',
  tru: 'https://mastercard.syf.com',
  verizon: 'https://verizonvisacard.syf.com',
  virgin: 'https://virginredrewardscard.syf.com',
  walgreens: 'https://walgreens.syf.com',
  walmart: 'https://walmart.syf.com',
  zulily: 'https://zulily.syf.com'
}

export const FIND_ACCOUNT = 'https://www.synchrony.com/find-account.html'

export const nonSupportingPAGClients = ['bp', 'gemoney', 'menswear', 'stash']

export const liquidatedClients = [
  'bp',
  'gemoney',
  'infiniti',
  'marvel',
  'mwh',
  'norair',
  'stash',
  'steinmart',
  'walmart'
]

export const nonSupportedClients = [
  'gap',
  'br',
  'oldnavy',
  'athleta',
  'lowescan',
  'Winwholesale',
  'Orchard',
  'Stock'
]
