import axios from 'axios'
import config from 'const/config'
import featureFlags, { FeatureFlagsType } from 'const/featureFlags'
import initializeCobrowse from 'helpers/initializeCobrowse'
import loadDynamicYieldScript from 'helpers/loadDynamicYieldScript'

const updateFeatureFlags = async (): Promise<void> => {
  // localFeatureFlags are used in development and test modes.
  if (process.env.NODE_ENV === 'production') {
    try {
      const { data } = await axios.get<FeatureFlagsType>(
        `${config.SERVICE_FILES}/vista-config.json`
      )
      // Check to ensure we don't set the value to undefined if API doesn't return a response
      Object.keys(data).forEach((flag: string) => {
        if (typeof data[flag] === 'boolean') featureFlags[flag] = data[flag]
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }
  }
  loadDynamicYieldScript()
  initializeCobrowse(featureFlags.isSurflyScriptTagEnabled)
}

export default updateFeatureFlags
