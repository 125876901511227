import apiEndpoints from 'const/apiEndpoints'
import api from 'services'

const postElectronicConsent = async (): Promise<boolean> => {
  const { data } = await api.post(apiEndpoints.ELECTRONIC_CONSENT)
  const { econsentStatus } = data || {}
  return econsentStatus
}

export default postElectronicConsent
