import { ReactElement } from 'react'

import { FAR_INFO_CIRCLE } from 'const/icons'
import getToolTipConfig from 'helpers/getTooltipConfig'
import i18n from 'strings/i18n'
import Inline from 'syf-component-library/ui/spacing/Inline/Inline'
import { Body } from 'syf-component-library/ui/typography'

import {
  DetailLabel,
  InfoCircleIcon,
  Tooltip,
  TooltipWrapper
} from './subcomponents'
import { DetailTypeOptions } from '../AccountDetail/typings'

interface AccountDetailSubtitleProps {
  type: DetailTypeOptions
}

const AccountDetailSubtitle = ({
  type
}: AccountDetailSubtitleProps): ReactElement => {
  const tooltipConfig = getToolTipConfig(type)

  if (tooltipConfig == null) {
    return (
      <DetailLabel data-test="detail-label">
        {i18n({ labels: type })}
      </DetailLabel>
    )
  }

  const { tooltipType, tooltipAlt, tooltipContent } = tooltipConfig

  return (
    <TooltipWrapper data-test={`tooltip-wrapper-${tooltipType}`}>
      <DetailLabel data-test="detail-label">
        {i18n({ labels: tooltipType })}
      </DetailLabel>
      <Tooltip
        ariaLabel={i18n({ labels: tooltipAlt }) as string}
        content={<Body>{i18n({ labels: tooltipContent })}</Body>}
        isInteractive={false}
        placement="bottom-start"
      >
        <Inline size="small" />
        <InfoCircleIcon icon={FAR_INFO_CIRCLE} size="sm" />
      </Tooltip>
    </TooltipWrapper>
  )
}

export default AccountDetailSubtitle
