import { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import { disputesConfig } from 'const/mfeConfig'
import SyfDisputesCenter, { DisputeFeature } from 'digital-disputes'
import useHandleNavigate from 'hooks/useHandleNavigate'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import { DisputesTableWrapper } from './subcomponents'

const DisputesCenter = (): ReactElement => {
  const { handleNavigate } = useHandleNavigate()

  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  useNavigateOnRedirect()

  return (
    <DisputesTableWrapper>
      <SyfDisputesCenter
        disputesMFEConfig={disputesConfig}
        accountId={selectedAccount}
        feature={DisputeFeature.DisputeCenter}
        navigationHandler={handleNavigate}
        returnToParent={() => null}
      />
    </DisputesTableWrapper>
  )
}

export default DisputesCenter
