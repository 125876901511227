import NotificationBack from 'notifications/ui/NotificationBack'
import styled from 'styled-components'

export const BackFooter = styled.div`
  background-color: ${({ theme }) => theme.mediumGrey};
  height: 84px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
`

export const BackNotification = styled(NotificationBack)`
  height: 360px;
  overflow-y: auto;
`
