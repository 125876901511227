import { ReactElement } from 'react'

import styled, { keyframes } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'

const minHeight = 520

export const AccountManagerGraphic = styled.div`
  display: none;
  @media (min-height: ${minHeight}px) {
    display: initial;
  }
`

const showStroke = keyframes`
  from { left: 0; top: 0; }
  to { left: 100% ; top: 100%; background: transparent; }
`

export const StrokeAnimater = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.white} no-repeat;
  animation: ${showStroke} 1.5s linear forwards;
`

export const LineWrapper = styled.div`
  position: relative;
`

export const dashedLine = (
  color: string,
  height: string,
  width: string,
  isMobile: boolean
): ReactElement => {
  const calculatedHeight = isMobile ? 0.8 * Number(height) : height
  const calculatedWidth = isMobile ? 0.8 * Number(width) : width

  return (
    <svg height={calculatedHeight} width={calculatedWidth}>
      <line
        stroke={color}
        x2={calculatedWidth}
        y2={calculatedHeight}
        strokeDasharray="3, 3"
      />
    </svg>
  )
}

export const FlexWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.white};
  justify-content: center;
  margin: auto;
  height: 140px;

  @media ${query.lessThanSmall} {
    height: 120px;
  }
`

const commonStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PhoneLaptopIconContainer = styled.div`
  ${commonStyles}
  height: 61px;
  width: 61px;
  background-color: ${({ theme }) => theme.grey};
  border-radius: 35px;

  @media ${query.lessThanSmall} {
    width: 45px;
    height: 45px;
  }
`

export const FileInvoiceIconContainer = styled.div`
  ${commonStyles}
  height: 43px;
  width: 43px;
  background-color: ${({ theme }) => theme.cyanBlue};
  border-radius: 25px;

  @media ${query.lessThanSmall} {
    width: 35px;
    height: 35px;
  }
`

export const CreditCardIconContainer = styled.div`
  ${commonStyles}
  height: 36px;
  width: 36px;
  background-color: ${({ theme }) => theme.yellow};
  border-radius: 20px;

  @media ${query.lessThanSmall} {
    width: 30px;
    height: 30px;
  }
`

export const MoneyCheckIconContainer = styled.div`
  ${commonStyles}
  height: 84px;
  width: 84px;
  background-color: ${({ theme }) => theme.blue};
  border-radius: 48px;

  @media ${query.lessThanSmall} {
    width: 60px;
    height: 60px;
  }
`

export const ReceiptIconContainer = styled.div`
  ${commonStyles}
  height: 28px;
  width: 28px;
  background-color: ${({ theme }) => theme.green};
  border-radius: 16px;

  @media ${query.lessThanSmall} {
    width: 22px;
    height: 22px;
  }
`

export const CreditCardIconWrapper = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 3%;
  transform: translateX(-50%);

  @media ${query.lessThanSmall} {
    margin-top: 7%;
  }
`

export const PhoneLaptopIconWrapper = styled.div`
  position: relative;
  transform: translate(30%, 5%);

  @media ${query.lessThanSmall} {
    transform: translate(20%, 10%);
  }
`

export const MoneyCheckIconWrapper = styled.div`
  position: relative;
  transform: translate(65%, -10%);

  @media ${query.lessThanSmall} {
    transform: translate(75%, -5%);
  }
`

export const FileInvoiceIconWrapper = styled.div`
  position: relative;
  transform: translate(230%, 60%);
`

export const ReceiptIconWrapper = styled.div`
  position: relative;
  transform: translate(-60%, 60%);
`

export const FileInvoiceIconLineWrapper = styled.div`
  transform: translate(170%, 82%);
`

export const PhoneLaptopIconLineWrapper = styled.div`
  transform: translate(10%, 45%);

  @media ${query.lessThanSmall} {
    transform: translate(5%, 45%);
  }
`

export const MoneyCheckIconLineWrapper = styled.div`
  transform: scaleX(-1) translate(125%, 45%);

  @media ${query.lessThanSmall} {
    transform: scaleX(-1) translate(105%, 43%);
  }
`

export const ReceiptIconLineWrapper = styled.div`
  transform: scaleX(-1) translate(155%, 75%);
`

export const IconWrapper = styled.div`
  ${commonStyles};
  padding-bottom: ${spacing.large}px;
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`

export const StarIconWrapper = styled.div`
  animation: ${fadeIn} 3s;
  animation-delay: ${fadeIn} 1s;
`
