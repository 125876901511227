type GetTimeOfDay = 'morning' | 'afternoon' | 'evening'

const getTimeOfDay = (date: Date): GetTimeOfDay => {
  const hour = date.getHours()

  // 4am - 11:59pm
  if (hour >= 4 && hour < 12) {
    return 'morning'
  }

  // 12:00pm - 4:59pm
  if (hour >= 12 && hour < 17) {
    return 'afternoon'
  }

  /*
   * 5:00pm - 3:59am
   * No need for conditional since all else would meet evening
   */
  return 'evening'
}

export default getTimeOfDay
