import { ReactElement, RefObject, forwardRef, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAR_ANGLE_LEFT } from 'const/icons'
import { PaymentStatus } from 'notifications/typings/custom'
import AccountHeader from 'pages/AccountsDashboard/Card/layouts/AccountHeader'
import i18n from 'strings/i18n'
import Button from 'syf-component-library/ui/atoms/Button'
import Inline from 'syf-component-library/ui/spacing/Inline'
import { AccountSummaryGetResponse } from 'syf-typings'
import { Account } from 'syf-typings/creditAccounts/creditAccounts'

import { BackFooter, BackNotification } from './subcomponents'

interface CardBackProps {
  accountSummary: AccountSummaryGetResponse
  account: Account
  notificationList: PaymentStatus[]
  setIsFlipped: (flag: boolean) => void
}

const CardBack = (
  { accountSummary, account, notificationList, setIsFlipped }: CardBackProps,
  focusRef: RefObject<HTMLDivElement>
): ReactElement => {
  const logoFocusRef: RefObject<HTMLButtonElement> = useRef(null)
  const { current } = focusRef || {}
  useEffect(() => {
    setTimeout(() => {
      if (logoFocusRef) {
        logoFocusRef?.current?.focus()
      }
    }, 500)
  })

  const backClickHandler = () => {
    setIsFlipped(false)
    setTimeout(() => {
      if (current) {
        current?.focus()
      }
    }, 600)
  }

  return (
    <>
      <AccountHeader
        accountSummary={accountSummary}
        account={account}
        isExpanded
        ref={logoFocusRef}
      />
      <BackNotification
        data-test="back-notfications"
        notificationList={notificationList}
      />
      <BackFooter>
        <Button
          data-test="card-back-button"
          buttonType="ghost"
          onClick={backClickHandler}
          data-type="Flip card"
          data-reason="Back"
          data-object="button"
          data-analytics="clicktrackingevent"
        >
          <FontAwesomeIcon icon={FAR_ANGLE_LEFT} size="lg" />
          <Inline size="xsmall" />
          {i18n({ button: 'back' })}
        </Button>
      </BackFooter>
    </>
  )
}
export default forwardRef(CardBack)
