import PRODUCT_TYPE from 'const/productTypes'
import { ProductType } from 'syf-typings'

// The Account Card MFE - setpay 2.0 only implemented the `Monthly` and `Pay in four`. `Installment Loan` should be added here
const isLoanProductCard = (type: ProductType) => {
  return [
    PRODUCT_TYPE.INSTALLMENT_LOAN_MONTHLY,
    PRODUCT_TYPE.INSTALLMENT_LOAN_PAY_IN_FOUR
  ].includes(type)
}

export default isLoanProductCard
