import { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAR_TRIANGLE_EXCLAMATION } from 'const/icons'
import i18n from 'strings/i18n'
import { Stack } from 'syf-component-library/ui/spacing'
import { Body } from 'syf-component-library/ui/typography'
import { ErrorCard, ErrorDescription, IconContainer } from './subcomponents'

const IncorrectInfoError = (): ReactElement => {
  return (
    <>
      <ErrorCard data-test="add-account-incorrect-info">
        <IconContainer>
          <FontAwesomeIcon icon={FAR_TRIANGLE_EXCLAMATION} size="lg" />
        </IconContainer>
        <div data-test="incorrect-info-error-title">
          <Body weight="bold">
            {i18n({ addAccountModal: 'generalErrorTitle' })}
          </Body>
          <ErrorDescription data-test="incorrect-info-error-description">
            {i18n({ errors: 'incorrectInfoDescripton' })}
          </ErrorDescription>
        </div>
      </ErrorCard>
      <Stack size="large" />
    </>
  )
}

export default IncorrectInfoError
