import { ACCOUNT_AUTH_PROHIBITED } from 'const/account/conditions'
import { ConditionValues } from 'syf-typings'

const hasAuthProhibitedUnregisteredNotification = (
  conditions: ConditionValues[],
  isRegistered: boolean
): boolean => {
  return conditions?.includes(ACCOUNT_AUTH_PROHIBITED) && isRegistered === false
}

export default hasAuthProhibitedUnregisteredNotification
