import { useNavigate } from 'react-router'

import routes, { RouteKeys } from 'const/routes'

const FeatureRouteMap: Record<string, RouteKeys> = {
  disputes: 'DISPUTES',
  activity: 'ACTIVITY'
}

const useHandleNavigate = () => {
  const navigate = useNavigate()

  const handleNavigate = (feature: string) => {
    const routeKey = FeatureRouteMap[feature] ?? 'HOME'
    const path = routes[routeKey]
    navigate(path)
  }

  return {
    handleNavigate
  }
}

export default useHandleNavigate
