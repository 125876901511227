import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import routes from 'const/routes'

/**
 *
 *This function will allow users to be redirected to the dashboard when they click
 *the back button on the browser, rather than being redirected to the previous routes
 *that they accessed where accessToken is null.
 */

const useNavigateOnRedirect = (): void => {
  const navigate = useNavigate()

  const isUsingMockData = Boolean(sessionStorage.getItem('mockData'))

  useEffect(() => {
    if (!sessionStorage.getItem('accessToken') && !isUsingMockData) {
      navigate(routes.HOME)
    }
  }, [navigate, isUsingMockData])
}

export default useNavigateOnRedirect
