import config from 'const/config'

import isAllowedHost from './isAllowedHost'

const getUrlExtension = (extension: string, CLIENT_ID: string): string => {
  const currentUrl = window.location.href

  if (isAllowedHost(currentUrl) && config.APP_ENV !== 'production')
    return `/${extension}/${CLIENT_ID}/`

  return isAllowedHost(currentUrl)
    ? `/${extension}/`
    : `/${extension}/${CLIENT_ID}/`
}

export default getUrlExtension
